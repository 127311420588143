import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs/internal/Subscription';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { CalendarService} from '../services/calendar.service';
import { Observable } from "rxjs/internal/Observable";
import { PauseAreaInfo, PauseService } from "../pause/pause.service";
import { ReasonDialogComponent } from 'src/app/main/reason-dialog/reason-dialog.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-pause',
  templateUrl: './pause.component.html',
  styleUrls: ['./pause.component.scss']
})

export class PauseComponent implements OnInit {
  dataSource:PauseAreaInfo[];
  areaShiftList = [];
  
  site: string;
  subPauseList: Subscription;

  areaMap:any = {}; // Will store map with key as area and value as proficy environment
  shiftCount:number = 1; // Number of shifts to load
  
  loadingStatus:boolean; // Status of loading 

  showAllPause:boolean = false; // True if there are any areas available to pause
  showAllUnpause:boolean = false; // True if there are any areas available to unpause

  displayedColumns: string[] = ["ShiftDate", "Shift", "StartTime", "EndTime", "Status", "Action"];

  constructor(private route: ActivatedRoute,private router: Router,
    public dialog: MatDialog, 
    private pauseService: PauseService,
    private calendarService:CalendarService) {        
  }

  ngOnInit(): void {
    this.site = this.route.snapshot.paramMap.get('site')

    // Populate areaMap as with AREA as key and Proficy Environment as value
    let areas:string[] = this.route.snapshot.paramMap.get('lines').split('&'); 
    areas.forEach(
      (area) => {
          let parts:string[] = area.split('-');

          this.areaMap[parts[0]] = parts[1];
      }
    );

    this.calendarService.isLoading.subscribe(
      (status:boolean) => { this.loadingStatus = status }
    );

    this.getData();
  } 

  /**
   * Load current pause status for all selected areas
   */
  getData() {
    let arealist:string = Object.keys(this.areaMap).join(","); // Get comma delimited list of Areas
    let envlist:string = Array.from(new Set(Object.values(this.areaMap))).join(","); // Get comma delimited list of unique Proficy Environments
    
    this.calendarService.isLoading.next(true);
    
    if (this.subPauseList) this.subPauseList.unsubscribe();
    this.subPauseList = this.pauseService.getPauselist(arealist,envlist, this.shiftCount).subscribe(
      (pauses) => {
        this.dataSource = pauses['Body'];

        this.showAllPause = (this.dataSource.filter( area => area.ShiftStart != null && area.PauseStart == '').length > 0) ? true : false ;
        this.showAllUnpause = (this.dataSource.filter( area => area.ShiftStart != null && area.PauseStart != '').length > 0) ? true : false;

        let currentArea:any;
        
        this.areaShiftList = [];
        this.dataSource.forEach(
          (areaShift) => {
            if (!currentArea) {
              currentArea = {
                "Area": areaShift.Area,
                "AreaDesc": areaShift.AreaDesc,
                "Shifts": []
              };
            } else if (currentArea.Area != areaShift.Area) {
              this.areaShiftList.push(currentArea);

              currentArea = {
                "Area": areaShift.Area,
                "AreaDesc": areaShift.AreaDesc,
                "Shifts": []
              };
            }

            currentArea.Shifts.push(areaShift);
          }
        );

        if (currentArea) {
          this.areaShiftList.push(currentArea);
        }

        console.log("areaShiftList", this.areaShiftList);

        this.calendarService.isLoading.next(false);
      }
    );

  }

  toggleShiftCount():void {
    this.shiftCount = this.shiftCount > 1 ? 1 : 2;
    this.getData();
  }

  /**
   * Helper method to open reason dialog
   * 
   * @returns Dialog Close Observable
   */
  getReason():Observable<any> {
    const dialogRef = this.dialog.open(ReasonDialogComponent,{
      width:'250px',
      data: {reason: ''}
    });

    return dialogRef.afterClosed();
  }

  /**
   * Pause the given area
   * 
   * @param area Area to pause
   */
  pauseArea(area:string,shiftdate:string,shift:string) {
    this.getReason().subscribe( 
      result => {
        if (result) {
          let reason:string = result.reason;
          let obsPause:Observable<any>[] = [];

          this.calendarService.isLoading.next(true);

          this.pauseService.setPause(area,this.areaMap[area],reason,shiftdate,shift).subscribe( 
            result => {
              console.log("result", result);
              this.getData();
            }
          );
        }
      }
    );
  }

  /**
   * Pause all displayed areas that are eligible to pause
   */
  pauseAllAreas() {
    let eligibleAreas:PauseAreaInfo[]  = this.dataSource.filter( area => area.ShiftStart != null);

    this.getReason().subscribe( 
      result => {
        if (result) {
          let reason:string = result.reason;
          let obsPause:Observable<any>[] = [];

          this.calendarService.isLoading.next(true);

          eligibleAreas.forEach(
            (area) => {
              obsPause.push(this.pauseService.setPause(area.Area,this.areaMap[area.Area],reason));
            }
          );

          forkJoin(obsPause).subscribe( 
            result => {
              console.log("result", result);
              this.getData();
            }
          );
        }
      }
    );
  }

  /**
   * Unpause the given area
   * 
   * @param area Area to unpause
   */
  unpauseArea(area:string, shiftdate:string, shift:string, starttime:string, idx:number) {
    console.log("starttime", starttime, idx);
    
    let futureShiftDate:string;
    let futureShift:string;
    let futureShiftStart:string;

    if (idx > 0) {
      // Future shift, need to pass shiftdate, shift, and starttime
      futureShiftDate = shiftdate;
      futureShift = shift;
      futureShiftStart = starttime.replace("T"," ");
    }
    
    this.getReason().subscribe( 
      result => {
        if (result) {
          let reason:string = result.reason;
          let obsPause:Observable<any>[] = [];

          this.calendarService.isLoading.next(true);

          this.pauseService.deletePause(area,this.areaMap[area],reason, futureShiftDate, futureShift, futureShiftStart).subscribe( 
            result => {
              console.log("result", result);
              this.getData();
            }
          );
        }
      }
    );
  }

  /**
   * Unpause all displayed areas that are currently paused
   */
  unpauseAllAreas() {
    let eligibleAreas:PauseAreaInfo[]  = this.dataSource.filter( area => area.ShiftStart != null && area.PauseStart != '');

    this.getReason().subscribe( 
      result => {
        if (result) {
          let reason:string = result.reason;
          let obsUnpause:Observable<any>[] = [];

          this.calendarService.isLoading.next(true);
          eligibleAreas.forEach(
            (area) => {
              obsUnpause.push(this.pauseService.deletePause(area.Area,this.areaMap[area.Area],reason));
            }
          );

          forkJoin(obsUnpause).subscribe( 
            result => {
              console.log("result", result);
              this.getData();
            }
          );
        }
      }
    );
  }

  onBack() {
    this.router.navigate(['/area', 'select', this.site, 'pause', 'ALL']);
  }
}
