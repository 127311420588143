import { Component, OnInit } from '@angular/core';
import { Router} from "@angular/router";
import {Observable} from "rxjs/internal/Observable";
import {CalendarService} from "../../services/calendar.service";
import { LocationSelectService } from 'src/app/services/location-select.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-location-select',
  templateUrl: './location-select.component.html',
  styleUrls: ['./location-select.component.scss']
})

export class LocationSelectComponent implements OnInit {
  siteData: Observable<any>;
  location:string;
  constructor(private calendarService:CalendarService,
              private router: Router,
              private locationSelectServ: LocationSelectService              
  ) { 
  }

  ngOnInit() {
    this.calendarService.isLoading.next(true);
    this.siteData = this.locationSelectServ.getSites().pipe(
      map((data) => {
        this.calendarService.isLoading.next(false)
        return data['Body']
      })
    )
    console.log(this.siteData)
  }
 
    handleClick(name){
      this.router.navigate(['/activity/select', name]);
    }

  ngOnDestroy(){
 
  }

}
