import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-area-edit-dialog',
  templateUrl: './area-edit-dialog.component.html',
  styleUrls: ['./area-edit-dialog.component.scss']
})
export class AreaEditDialogComponent implements OnInit {

  areas: any[];
  templateName: string;
  templateAreas: any[];
  templateAreaKeys: string[];
  areasWithEvents: string[];

  selection = new SelectionModel<string>(true, []); // Stores the list of areas checked  
  displayedColumns: string[] = ["Selection", "Area"];

  addAreas:any[]=[]; // List of areas which users select
  removeAreas:any[]=[]; // List of areas which users deselect

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AreaEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.debug("AreaEditDialogComponent Input Data", this.data)
   }

  ngOnInit() {
    this.templateName = this.data.template
    this.templateAreas = this.data.areas
    this.templateAreaKeys = this.templateAreas.map(item => item.area);
    this.areasWithEvents = this.templateAreas.filter(item => item.events).map(item => item.area);
    this.areas = this.data.allAreas;

    this.templateAreaKeys.forEach(templateArea => this.selection.select(templateArea)); // Add all preselected areas
  }

  onNoClick(): void {
    this.dialogRef.close({'status': 'cancel'});
  }

  /**
   * After user clicks Save, determine if any change were made. If changes made, show the confirmation otherwise close the dialog.
   * 
   */
   onUpdateAreas() {
    let selectedAreas = this.areas.filter(el => {
      return this.selection.selected.find(element => {
        return element === el.Area;
         });
    })
    
    let output={'status': 'save', 'selectedAreas': selectedAreas}
    this.dialogRef.close(output) 
  }
}
