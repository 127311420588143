import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import {GenericEventLambdaReturn} from "../models/genericLambda";
import {Observable} from "rxjs/internal/Observable";
import {CalendarServiceUtil} from "./calendar.util";
import {BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { of } from "rxjs";
import { CalendarEvent, CalendarEventType } from '../common/models/event-dialog.model';
import * as dayjs from 'dayjs';
import { HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CalendarService extends AbstractRestService {
  private breakCategories: string[] = []; // Cache break categories for us in getBreakCategories()

  postInit(): void {
  }

  public isLoading = new BehaviorSubject<boolean>(false);


  getAreasAPI(site: string, repeat: boolean, lines?: string[]):Observable<GenericLambdaReturn<AreaType[]>>{
    

    let params: any = {};
    params.site = site.toString();
    if (lines) {
      params.areas = lines.join(",");
      new HttpParams({fromObject: {params}}); 
    }
    else {
      new HttpParams({fromObject: {params}}); 
    }
    return this.get({
      ApiResource: "/getareas",
      data: params,
      repeatCall: repeat
    }); 
  }

  getEventsAPI(environments:string[], startTime:string, endTime:string, repeat: boolean, lines?:string[]):Observable<GenericEventLambdaReturn<[]>>{
    let params: any = {};

    if (startTime) params.startTime = startTime;
    if (endTime) params.endTime = endTime;
    if (lines) params.areas = lines.join(",");
    if (environments) params.environments = environments.join(",");
    return this.get({
      ApiResource: "/events",
      data: params,
      repeatCall: repeat
    });
  }

  buildEventAPI(events: CalendarEventType[], templateEvent: boolean):Observable<GenericLambdaReturn<[]>>{
    console.log('Inside build event api ',events, templateEvent)
    if (templateEvent){
      let paramsArr: any[] = []
      events.forEach(event => {
        let params: any = {}
        params.Action = event.Action;
        params.AuroraEnv = event.AuroraEnv ? event.AuroraEnv : event.ProjectID;
        params.Template = event.Template;
        params.Area = event.Area;
        params.Shift = event.Shift;
        params.Event = event.Event;
        params.BreakCatagory = event.BreakCatagory;
        params.StartTime = dayjs(event.StartTime).format('HH:mm:ss.sssssss');
        params.EndTime = dayjs(event.EndTime).format('HH:mm:ss.sssssss');

        console.debug("buildEventAPI: " + params.StartTime + " - " + params.EndTime);

        params.ShiftDayOffset = event.ShiftDayOffset;
        let myTarget:number = +event.Target;
        params.Target = myTarget / 100;
        if (params.Target > 0) {
          params.Target = myTarget / 100;
        } else {
          params.Target = myTarget;
        }

        params.Modify_by = event.Modify_by;
        params.Reason = event.Reason;
        params.HornEndDuration = event.HornEndDuration;
        params.HornEndDelta = event.HornEndDelta;
        params.HornStartDuration = event.HornStartDuration;
        params.HornStartDelta = event.HornStartDelta;
        params.HornAdvDelta = event.HornAdvDelta;
        params.HornAdvDuration = event.HornAdvDuration;
        params.DayList = Array.isArray(event.Default) ? event.Default.join(',') : event.Default;
        paramsArr.push(params)
      });
      let params = {'data': paramsArr}
      return this.post({
        ApiResource: "/templatedetails",
        data: params,
        repeatCall: false
      });
    } else {

      let params =  CalendarServiceUtil.buildEventParams(events);
      console.log(params)
      return this.post({
        ApiResource: "/buildevent",
        data: params,
        repeatCall: false
      });
    }   
  }

  deleteCalendarEvent(events: CalendarEventType[], templateEvent: boolean):Observable<GenericLambdaReturn<[]>>{
    if (templateEvent){
      let paramsArr: any[] = []
      events.forEach(event => {
        let params: any = {}
        params.Action = event.Action;
        params.AuroraEnv = event.AuroraEnv ? event.AuroraEnv : event.ProjectID;
        params.Template = event.Template;
        params.Area = event.Area;
        params.Shift = event.Shift;
        params.Event = event.Event;
        params.BreakCatagory = event.BreakCatagory;
        params.StartTime = dayjs(event.StartTime).format('HH:mm:ss.sssssss');
        params.EndTime = dayjs(event.EndTime).format('HH:mm:ss.sssssss');

        console.debug("deleteCalendarEvent: " + params.StartTime + " - " + params.EndTime);

        params.ShiftDayOffset = event.ShiftDayOffset;
        params.Target = event.Target;
        params.Modify_by = event.Modify_by;
        params.Reason = event.Reason;
        params.HornEndDuration = event.HornEndDuration;
        params.HornEndDelta = event.HornEndDelta;
        params.HornStartDuration = event.HornStartDuration;
        params.HornStartDelta = event.HornStartDelta;
        params.HornAdvDelta = event.HornAdvDelta;
        params.HornAdvDuration = event.HornAdvDuration;
        params.DayList = Array.isArray(event.Default) ? event.Default.join(',') : event.Default;
        paramsArr.push(params)
      });
        let params = {'data': paramsArr}
      return this.post({
        ApiResource: "/templatedetails",
        data: params,
        repeatCall: false
      });
    } else {


      let params =  CalendarServiceUtil.buildEventParams(events);
      console.log(params)
      return this.post({
        ApiResource: "/buildevent",
        data: params,
        repeatCall: false
      });
     
    }  
    
  }

  loadBreakCategories():Observable<GenericEventLambdaReturn<any>> {
    let params = new HttpParams({fromObject: {}});
    console.log(' Inside Load break category ')
    return this.get({
      ApiResource: "/breakcategory",
      data: params,
      repeatCall: false
    });
    
  }
  
  /**
   * Return an Obserable with an array of break categories from the Proficy Enumerators
   */
  getBreakCategories():Observable<any> {
    if (this.breakCategories.length == 0) {
      console.debug("Break categories not loaded, retrieve them first");
      return new Observable<any>((observer) => {
        this.loadBreakCategories().subscribe( data => {
          console.debug("Categories received", data.Body);
          for (var val of data.Body) {
            this.breakCategories.push(val.BreakCategory);
          };
          observer.next(this.breakCategories);
          observer.complete();
        });
      });
    } else {
      console.debug("Break categories loaded, send them on.");

      return of(this.breakCategories);
    }
  }

  /**
   * Return list of templates
   * 
   * @param environments
   * @returns 
   */
  getTemplates(site:string):Observable<GenericLambdaReturn<any>>{
  /*  let params = new HttpParams({fromObject: {site}}) */
    let params: any = {}; 
    console.log( ' Inside getTemplates ',site)

    params.site = site; 
    return this.get({
      ApiResource: "/templates",
      data: params,
      repeatCall: false
    });
    
  }

  /**
   * Apply a given Template to a particular ShiftDate
   * 
   * @param Template Valid template to apply
   * @param ShiftDate Shift Date to apply on
   * @param Projects List of projects the template should be applied to
   * @param Reason Optional: Reason
   * @param AreaList Optional: List of Areas (NOTE: Currently Proficy bug means this should only be used with NON WORKING)
   * @returns 
   */
  applyTemplate(Template:string, ShiftDate:Date, Projects:string[], Reason:string="Mobile Template Apply",AreaList:string[]=[]):Observable<GenericLambdaReturn<[]>>{
    let params: any = {};
    params.Template = Template;
    params.ShiftDate = ShiftDate;
    params.Projects = Projects;
    params.Reason = Reason;
    params.Areas = AreaList;
    return this.post({
      ApiResource: "/templateapply",
      data: params,
      repeatCall: false
    });
  }

   getMetricStatus(Area:string, ShiftDate:string, Shift:string):Observable<GenericLambdaReturn<[]>>{
    let params: any = {};
    params.Area = Area;
    params.ShiftDate = ShiftDate;
    params.Shift = Shift;
    return this.get({
      ApiResource: "/metricstatus",
      data: params,
      repeatCall: false
    });
  }

  updateMetrics(Shifts: any[]):Observable<GenericLambdaReturn<[]>>{
    let params: any = [];
    params = Shifts
    return this.post({
      ApiResource: "/metricstatus",
      data: params,
      repeatCall: false
    });
  }
 }

  export type EventType = {
    Area: string,
    ShiftDate: string,
    StartTime: string,
    EndTime: string,
    Event: string,
    EventType: string,
    ObjectDescription: string,
    Shift: string,
    ProjectID: string,
    BreakCatagory: string,
    Target: string,
    HornAdvDelta: string,
    HornAdvDuration: string,
    HornStartDelta: string,
    HornEndDelta: string,
    HornStartDuration: string,
    HornEndDuration: string,
    WarningDelta: string,
    WarningDuration: string,
    Template: string,
    Modify_by: string,
    Reason: string,
    Action: string
}



export type AreaType = {
  Area: string,
  ObjectDescription: string,
  Environment: string,
  ProjectID: string,
  Checked: boolean
}

export type EventCreateType = {
  event: EventType,
  areas: AreaType[];
  date: string;
}

export type MarkNonWorkType = {
  events: CalendarEvent[]
  areas: AreaType[];
  date: Date;
}

export type EventConfirmType = {
  events?: CalendarEvent[];
  oldEvent?: CalendarEvent;
  newEvent?: CalendarEvent;
  action?: string;
  isTemplate?: boolean;
}

export type ShiftInfoType = {
  Area: string,
  Shift: string,
  ShiftDate: Date
}
