import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UpdateGroupsService {
  private updateGroups = new Subject<any>();

  constructor() { }

  updateGroupAfterSubmit(){
    this.updateGroups.next();
  }

  getUpdateGroupAfterSubmit(): Observable<any>{
    return this.updateGroups.asObservable();
  }


}
