import * as dayjs from 'dayjs';

export class Event {
    Area: string;
    ShiftDayOffset: number;
    DisplayShiftDayOffset: number;
    ShiftDate: string;
    DisplayStartTime: string;
    StartTime: string;
    DisplayEndTime: string;
    EndTime: string;
    Event: string;
    ObjectDescription: string;
    Shift: string;
    ProjectID: string;
    BreakCatagory: string;
    Target: string;
    HornAdvDelta: string;
    HornAdvDuration: string;
    HornStartDelta: string;
    HornEndDelta: string;
    HornStartDuration: string;
    HornEndDuration: string;
    Template: string;
    Default: string;
    Modify_by: string;
    Reason: string;
    Action: string;
    AuroraEnv: string;

    constructor() {
        this.Area = null,
        this.StartTime = '',
        this.EndTime = '',
        this.Event = null,
        this.ObjectDescription = null,
        this.Shift = null,
        this.ProjectID = null,
        this.BreakCatagory = '<None>',
        this.Target = '95',
        this.HornEndDuration = '5',
        this.HornEndDelta = '0',
        this.HornStartDuration = '5',
        this.HornStartDelta = '0',
        this.HornAdvDelta = '0',
        this.HornAdvDuration = '0',
        this.Modify_by = null,
        this.Reason = '',
        this.Action = 'Create',
        this.AuroraEnv = ''
    }
}

export class CalendarEvent extends Event {
    ShiftDate: string;

    constructor(date: string){
        super();
        let timeNow = dayjs().hour() + ':' + dayjs().minute();
        this.ShiftDate = dayjs(date).format('YYYY-MM-DD');
        this.StartTime = dayjs(date + ' ' + timeNow).format('MM/DD/YYYY hh:mm A');
        this.EndTime = dayjs(date + ' ' + timeNow).format('MM/DD/YYYY hh:mm A');
    }
}

export class TemplateEvent extends Event {
    ShiftDayOffset: number;
    DisplayShiftDayOffset: number;
    DisplayStartTime: string;
    DisplayEndTime: string;
    Template: string;
    Default: string;

    constructor(templateName: string, defaultDays: string){
        super();
        this.ShiftDayOffset = 0;
        this.DisplayShiftDayOffset = null;
        this.DisplayStartTime = null;
        this.DisplayEndTime = null;
        this.Template = templateName;
        this.Default = defaultDays;
        this.ShiftDate = dayjs().format('YYYY-MM-DD')
        this.StartTime = dayjs().format('YYYY-MM-DD HH:mm:ss.sssssss')
        this.EndTime = dayjs().format('YYYY-MM-DD HH:mm:ss.sssssss')
    }
}

export type CalendarEventType = {
    Area: string,
    ShiftDayOffset: number,
    DisplayShiftDayOffset: number,
    ShiftDate: string,
    DisplayStartTime: string,
    StartTime: string,
    DisplayEndTime: string,
    EndTime: string,
    Event: string,
    ObjectDescription: string,
    Shift: string,
    ProjectID: string,
    BreakCatagory: string,
    Target: string,
    HornAdvDelta: string,
    HornAdvDuration: string,
    HornStartDelta: string,
    HornEndDelta: string,
    HornStartDuration: string,
    HornEndDuration: string,
    Template: string,
    Default: string,
    Modify_by: string,
    Reason: string,
    Action: string,
    AuroraEnv: string
}

export type CalendarEventMetaData = {
    Site: string,
    Event: CalendarEventType,
    Shift: CalendarEventType,
    HaveAllLocks: boolean,
    Areas: AreaSelection,
    TemplateEvent: boolean,
    SelectedDate?: string
}

export type ShiftLookup = {
    [AreaKey: string]: ShiftInformation[]
}

export type ShiftInformation = {
    StartTime: string,
    EndTime: string,
    ShiftOffset: number,
    Shift: string
}

export type ShiftTimes = {
    start: string,
    end: string
}

export type AreaSelection = {
    SelectedAreas: AreaInformation[],
    AvailableAreas: AreaInformation[]
}

export type AreaInformation = {
    Area: string,
    Environment: string,
    ObjectDescription: string,
    ProjectID: string,
    Site: string
}

export type EventErrors = {
    BreakCategoryErrors: string[],
    EventTypeErrors: string[],
    ShiftTitleErrors: string[],
    ShiftTimeErrors: string[], 
    EventTimeErrors: string[],
    DemandErrors: string[],
    ReasonErrors: string[]
}

export interface Options {
    value: string;
    viewValue: string;
}


export const Areas = {
    'AvailableAreas': [],
    'SelectedAreas': []
}
  
export const eventOptions = [
    {value: 'PAID EVENT', viewValue: 'Paid Event'},
    {value: 'UNPAID EVENT', viewValue: 'Unpaid Event'},
    {value: 'Pause', viewValue: 'Pause'},
    {value: 'SHIFT', viewValue: 'Shift'}
]

export const eventOptionsNoShift = [
    {value: 'PAID', viewValue: 'Paid Event'},
    {value: 'UNPAID', viewValue: 'Unpaid Event'},
    {value: 'Pause', viewValue: 'Pause'}
]

export let breakCategoryOptions = [
    'Break',
    'Lunch', 
    'Idle', 
    'Team Meeting', 
    'Clean Up', 
    'Swing', 
    'All Employee Meeting',
    'OTHER', 
    '<NONE>',
    'Pause'
];

export const shiftOptions = ['1ST SHIFT', '2ND SHIFT', '3RD SHIFT']; 


