import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-log-out-page',
  templateUrl: './log-out-page.component.html',
  styleUrls: ['./log-out-page.component.scss']
})
export class LogOutPageComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  logIn(){
    this.router.navigate(['/login'])
  }

}