<h3 mat-dialog-title>End of Shift Metrics for XXX</h3>
<!--
<div mat-dialog-content>
    <mat-table [dataSource]="shift.Metrics"  style="overflow:auto; max-height:400px;"> 
        <ng-container matColumnDef="ObjectID">
            <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Object ID</mat-header-cell>
            <mat-cell *matCellDef="let item">{{item.ObjectID}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Target">
            <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Target</mat-header-cell>
            <mat-cell *matCellDef="let item">{{item.Target}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Actual">
            <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Actual</mat-header-cell>
            <mat-cell *matCellDef="let item">{{item.Actual}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
-->
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">Close</button>
</div>