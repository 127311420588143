import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { PriorShift, PriorShiftServiceService } from '../services/prior-shift-service.service';

@Component({
  selector: 'app-remove-shift-dialog',
  templateUrl: './remove-shift-dialog.component.html',
  styleUrls: ['./remove-shift-dialog.component.scss']
})
export class RemoveShiftDialogComponent implements OnInit {
  shift:PriorShift;
  reasonFormGroup:UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<RemoveShiftDialogComponent>,
    private fb: UntypedFormBuilder,
    private svcShiftService: PriorShiftServiceService,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { 
    this.shift = this.inData.Shift;

    this.reasonFormGroup = this.fb.group({
      Reason: new UntypedFormControl({value: "", disabled: false}, Validators.required)
    });

  }

  ngOnInit(): void {
    
  }

  removeShift():void {
    let reason:string = this.reasonFormGroup.get("Reason").value;
    this.svcShiftService.deleteShift(this.shift.ProjectID, this.shift.Area, this.shift.ShiftDate, this.shift.Shift, reason).subscribe( 
      out => {
        console.debug("Shift Deleted", out);
      }

    )
  }

  onNoClick():void {
    console.log("Close");
    this.dialogRef.close();
  }


}
