import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TemplateEvent } from 'src/app/common/models/event-dialog.model';
import { EventValidationService } from 'src/app/common/services/event-validation.service';
import { TemplatesService } from 'src/app/template/services/templates.service';

@Component({
  selector: 'app-template-create-dialog',
  templateUrl: './template-create-dialog.component.html',
  styleUrls: ['./template-create-dialog.component.scss']
})
export class TemplateCreateDialogComponent implements OnInit {

  siteName: string;
  createFromExistingFlag: boolean;
  createFlag:boolean;
  oldTemplateName: any;
  newTemplateName: string;
  templateList: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private router: Router,
  public dialogRef: MatDialogRef<TemplateCreateDialogComponent>,
  private templateService: TemplatesService,
  private eventValidationService: EventValidationService) { }

  ngOnInit(): void {
    this.siteName = this.data.site;
    this.templateList = this.data.templateList
    this.createFromExistingFlag = false;
    this.createFlag = false;
    this.oldTemplateName = {};
    this.newTemplateName = '';
    console.log(this.templateList)
  }

  createFromBlank(){    
    this.createFlag=true;
  }

  createFromExisting(){
    this.createFromExistingFlag = true;
    this.createFlag=true;
  }

  createTemplateFromExisting(){    
    if (this.createFromExistingFlag){
      this.templateService.templateCopy(this.siteName, this.oldTemplateName.Environment, this.oldTemplateName.Name, this.newTemplateName).subscribe((result) =>{
        this.dialogRef.close({'createType': 'Existing', 'template': this.newTemplateName, 'site': this.siteName, 'envs': this.oldTemplateName.Environment});
      })
    }
    else{      
      this.dialogRef.close({'createType': 'Blank', 'template': this.newTemplateName, 'site': this.siteName, 'envs': this.oldTemplateName.Environment});      
    }
  }

  close(){    
    this.dialogRef.close({'createType': 'Close', 'template': 'None'});
  }

}
