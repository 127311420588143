import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PriorShiftServiceService extends AbstractRestService {

  postInit(){}

  getPriorShiftList(envs:string[], areas:string[], startDate?:Date, endDate?:Date):Observable<GenericLambdaReturn<PriorShift[]>> {
    let parms: any = {};

    parms.environments = envs.join(",");
    parms.arealist = areas.join(",");

    if (startDate) parms.StartDate = startDate;
    if (endDate) parms.EndDate = endDate;
    
    return this.get({
      ApiResource: "/priorshift",
      data: parms,
      repeatCall: true,
      repeatRate: 60000
    })
  }

  deleteShift(environment:string, areas:string, shiftDate:string, shift:string, reason:string):Observable<GenericLambdaReturn<any>> {
    let parms: any = {};

    parms.environments = environment;
    parms.Area = areas;
    parms.Shift = shift;
    parms.ShiftDate = shiftDate;
    parms.Reason = reason;
        
    return this.delete({
      ApiResource: "/priorshift",
      data: parms,
      repeatCall: false
    })
  }

}

export type PriorShift = {
  Status: string,
  ProjectID: string,
  Area: string,
  AreaDesc: string,
  ShiftDate: string,
  Shift: string,
  ShiftStart: Date,
  ShiftEnd: Date,
  UnitCount: number  
}