import {Component, OnInit} from '@angular/core';
import {CalendarService} from "src/app/services/calendar.service";

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progressSpinner.component.html',
  styleUrls: ['./progressSpinner.component.scss']
})
export class ProgressSpinnerComponent implements OnInit {

  isLoading: boolean;

  constructor(private calendarService:CalendarService) { }
//no unsubscribe here because its a unique case wtih only one subscription that is never needed again, not a pattern to reuse
  ngOnInit() {
    this.calendarService.isLoading.subscribe(value => {
      this.isLoading = value;
    })

  }
}
