import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AbstractRestService } from '../services/abstract-rest.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { GenericLambdaReturn } from '@gea-mes/cognito';


@Injectable({
  providedIn: 'root'
})

export class PauseService extends AbstractRestService {
  postInit(){}

  private _envList: BehaviorSubject<any[]> = new BehaviorSubject([])
  public envList: Observable<any[]> = this._envList.asObservable()


  setEnvironmentList(envs: any[]){
    console.log(envs)
    this._envList.next(envs)
  }

  getPauselist(arealist:string,environments:string, shiftcount?:number):Observable<GenericLambdaReturn<PauseAreaInfo[]>> {
    let params: any = {};
    let env:string;
   
    /* if (environments) params.environments = 'AP1_HWK'; */
    if (arealist) params.arealist = arealist; 
    if (environments) params.environments = environments; 
    if (shiftcount) params.ShiftCount = shiftcount;

    return this.get('/pause', new HttpParams({fromObject: params}), false)
  }


  setPause(area:string,environments:string,reason:string,shiftdate?:string,shift?:string):Observable<GenericLambdaReturn<any>>{
    let params: any = {};
   
     if (area) params.area = area; 
    if (reason) params.reason = reason;
    if (environments) params.environments = environments;
    if (shiftdate) params.ShiftDate = shiftdate;
    if (shift) params.Shift = shift;
    
    return this.put("/pause", {'data': params}, false)
  }

  deletePause(area:string,environments:string,reason:string,shiftdate?:string,shift?:string,shiftstarttime?:string):Observable<GenericLambdaReturn<any>>{
    let params: any = {};

    if (area) params.area = area; 
    if (reason) params.reason = reason;
    if (environments) params.environments = environments;
    
    // If the following three are paused, then attempting to unpause future shift
    if (shiftdate) params.ShiftDate = shiftdate;
    if (shift) params.Shift = shift;
    if (shiftstarttime) params.ShiftStartTime = shiftstarttime;

    return this.delete('/pause', new HttpParams({fromObject: params}))
  }
}

export type PauseAreaInfo = {
  Area: string,
  AreaDesc: string,
  PauseEnd: string,
  PauseStart: string,
  ProjectID: string,
  Shift: string,
  ShiftDate: string,
  ShiftEnd: string,
  ShiftStart: string
}