import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventDialogComponent } from './components/event-dialog/event-dialog.component';
import { ConfirmationBoxComponent } from './components/confirmationBox/confirmationBox.component';
import { ProgressSpinnerComponent } from './components/progressSpinner/progressSpinner.component';

import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { FullCalendarModule } from '@fullcalendar/angular';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [
    ConfirmationBoxComponent,
    EventDialogComponent,
    ProgressSpinnerComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatDividerModule,
    MatListModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    FullCalendarModule,
    FormsModule,
    PipesModule
  ],
  exports: [
    EventDialogComponent,
    ConfirmationBoxComponent,
    ProgressSpinnerComponent  
  ]
})
export class SharedCommonModule { }
