import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as dayjs from 'dayjs';
import { EasyShiftServiceService, EasyEditArea } from '../../services/easy-shift-service.service';

@Component({
  selector: 'app-change-endtime-dialog',
  templateUrl: './change-endtime-dialog.component.html',
  styleUrls: ['./change-endtime-dialog.component.scss']
})
export class ChangeEndtimeDialogComponent implements OnInit {

  endtimeFormGroup:UntypedFormGroup;
  areaShift:EasyEditArea;
  shiftEnd:string; 
  minTime:string; 
  maxTime:string;
  currentTime:Date;

  constructor(
    public dialogRef: MatDialogRef<ChangeEndtimeDialogComponent>,
    private fb: UntypedFormBuilder,
    private svcEasyShift: EasyShiftServiceService,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { 
    this.areaShift = inData.Area;
    this.shiftEnd = dayjs(this.areaShift.CurrentShiftEnd).format("HH:mm A");

    console.log("this.shiftEnd", this.shiftEnd);
    console.log("this.areaShift", this.areaShift, inData);

    this.endtimeFormGroup = this.fb.group({
      ShiftEnd: new UntypedFormControl({value: this.shiftEnd, disabled: false}, [Validators.required, this.validatorEndTime()]),
      EndImmediately: new UntypedFormControl({value: false, disabled: false}),
      Reason: new UntypedFormControl({value: "", disabled: false}, Validators.required)
    });
  }

  ngOnInit(): void {
    if (dayjs(this.areaShift.CurrentShiftStart).format('DD') != dayjs(this.areaShift.CurrentShiftEnd).format('DD')) {
      console.debug("Shift spans multiple days, do not restrict timepicker.")
      // Shift crosses day, do not restrict time picker
      this.minTime = null;
      this.maxTime = null;
    } else {
      // Shift in a single day, we can restrict the time picker
      this.minTime = dayjs().format("HH:mm A");
      this.maxTime = dayjs(this.areaShift.CurrentShiftEnd).format("HH:mm A"); // TODO: When allowing changing shift end to later this will need to change
      console.debug("Restrict timepicker input ", this.minTime, this.maxTime);
    }

  }

  toggleImmediate() {
    if (this.endtimeFormGroup.get("ShiftEnd").disabled) {
      // Enable ShiftEndTime field and set to current shift end
      this.endtimeFormGroup.get("ShiftEnd").enable();
      this.endtimeFormGroup.patchValue({ShiftEnd: dayjs(this.areaShift.CurrentShiftEnd).format("HH:mm A")});
    } else {
      // Disable ShiftEndTime field and set to current time
      this.endtimeFormGroup.get("ShiftEnd").disable();
      this.endtimeFormGroup.patchValue({ShiftEnd: dayjs().format("HH:mm A")});
    }
  }

  updateEndTime() {
    let endTime:string = this.endtimeFormGroup.get("ShiftEnd").value;

    let EndDateTime:string = dayjs(this.calculateEndDateTime(endTime)).format('YYYY-MM-DD HH:mm')

    this.svcEasyShift.updateShiftEndTime(this.inData.Envs, this.areaShift.Area, EndDateTime, this.endtimeFormGroup.get("Reason").value).subscribe(
      out => {
        this.dialogRef.close();
      }
    );
  }

  calculateEndDateTime(inEndTime:string):Date {
    let startDate:string = dayjs(this.areaShift.CurrentShiftStart).format('YYYY-MM-DD');
    let endDate:string = dayjs(this.areaShift.CurrentShiftEnd).format('YYYY-MM-DD');

    let endTime:string = inEndTime;
    let EndDateTime:string = `${startDate} ${endTime}`

    if (dayjs(EndDateTime, "YYYY-MM-DD HH:mm A").isBefore(dayjs(this.areaShift.CurrentShiftStart))) {
      EndDateTime = `${endDate} ${endTime}`
    }

    return dayjs(EndDateTime, "YYYY-MM-DD HH:mm A").toDate();
  }

  onNoClick():void {
    console.log("Close");
    this.dialogRef.close();
  }

  validatorEndTime(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let inTime: string = control.value;

      let EndDateTime:Date = this.calculateEndDateTime(inTime);

      this.currentTime = dayjs().toDate();

      if (dayjs(EndDateTime).isAfter(dayjs(this.areaShift.CurrentShiftEnd))) {
        return { afterCurrentShiftEnd: true } // TODO: When this is update to allow making end date later then this validation will need to be adjusted.
      }
      else if (dayjs(EndDateTime).isBefore(dayjs(this.areaShift.CurrentShiftStart))) {
        return { beforeCurrentShiftStart: true }
      }
      else if (dayjs(EndDateTime).isBefore(this.currentTime, 'minute')) { // Check only down to minute not second or millisecond
        return { beforeCurrentTime: true }
      }

      return null;
    } 
  }

}