import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {  Lock } from '@gea-mes/utility';

@Component({
    selector: 'bottom-sheet-overview-example-sheet',
    templateUrl: 'lock-info-bottom-sheet.html',
  })
  export class LockInfoBottomSheet {
    constructor(private _bottomSheetRef: MatBottomSheetRef<LockInfoBottomSheet>,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: Lock, ) {}
  
    openLink(event: MouseEvent): void {
      this._bottomSheetRef.dismiss();
      event.preventDefault();
    }
  }