import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';


@Injectable({
  providedIn: 'root'
})
export class ManageGroupService extends AbstractRestService {
  postInit(): void {
  }

  addAreas(groupName: string, area: string, site: string, environment: string){
    console.log('Inside manage-group.addAreas New',environment )

    let params: any = {};
    if (groupName) params.groupName = groupName;
    if (area) params.area = area;
    if (site) params.site = site;
    if (environment) params.environment = environment;

    let paramsObj = {'recordsToInsert': [params]}
    return this.post({
      ApiResource: "/managegroups",
      data: paramsObj,
      repeatCall: false
    });

  }


}
