<div class="fx-column-center-center">
    <h4 mat-dialog-title>Create a New Template</h4>
    <div class="fx-column-space-between-center" *ngIf="!createFlag">
        <button mat-raised-button class="createSelectButtons" color="primary" (click)="createFromBlank()">Create from blank template</button>
        <button mat-raised-button class="createSelectButtons" color="primary" (click)="createFromExisting()">Create from existing template</button>
    </div>
    <div *ngIf="createFlag">
        <form (ngSubmit)="createTemplateFromExisting()" #templateFromExisting="ngForm">
            <div class="fx-column-center-center">
                <!-- Existing Template Name Select -->
                <mat-form-field *ngIf="createFromExistingFlag" class="copyTemplateFormFields">
                    <mat-label>Select Template to Copy</mat-label>
                    <mat-select panelClass="my-panel" disableOptionCentering [(ngModel)]="oldTemplateName" name="oldTemplateName">
                        <mat-option *ngFor="let template of templateList" [value] = "template">{{template.Name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- New Template Name Input -->
                <mat-form-field>
                    <mat-label>New Template Name</mat-label>
                    <input matInput style="cursor:pointer" [(ngModel)]="newTemplateName" name="newTemplateName">
                </mat-form-field>
            
                <div mat-dialog-actions>
                    <button mat-raised-button color="primary" type="submit" class="submit mat-dialog-buttons">Save</button>
                    <span class="spacer"></span>
                    <button color="primary" type="button" mat-raised-button (click)="close()" class="warn mat-dialog-buttons">Close</button>
                </div>
            </div>
        </form>        
    </div>    
</div>