import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'
import { AmDateFormatPipe } from '../pipes/am-date-format.pipe';
import { AmLocalPipe } from '../pipes/am-local.pipe';

@NgModule({
    declarations: [
        AmDateFormatPipe,
        AmLocalPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AmDateFormatPipe,
        AmLocalPipe
    ]
  })
  export class PipesModule { }