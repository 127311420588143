import { Component, OnInit, ViewChild, HostListener, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatDrawer } from '@angular/material/sidenav';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridDay from '@fullcalendar/resource-timegrid';
import { EventInput } from '@fullcalendar/core';
import * as dayjs from 'dayjs';
import { CalendarOptions } from '@fullcalendar/core'
import { Subscription, Observable } from 'rxjs';
import { TemplatesService, TemplateEventType } from '../../services/templates.service';
import { AreaEditDialogComponent } from './area-edit-dialog/area-edit-dialog.component';
import { DeleteConfirmComponent } from './delete-confirm/delete-confirm.component';
import { TemplateNameChangeDialogComponent } from './template-name-change-dialog/template-name-change-dialog.component';
import { BreakpointState, BreakpointObserver, Breakpoints  } from '@angular/cdk/layout';
import { pluck } from 'rxjs/operators';
import { CalendarService } from 'src/app/services/calendar.service';
import {GeaMesCognitoAuthService} from "@gea-mes/cognito";
import { GeaLockService, Lock } from '@gea-mes/utility';
import { LockInfoBottomSheet } from 'src/app/main/lock-info-bottom-sheet/lock-info-bottom-sheet';
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { TemplateEvent } from 'src/app/common/models/event-dialog.model';
import { ShiftLookupService } from 'src/app/common/services/shift-lookup.service';
import { EventValidationService } from 'src/app/common/services/event-validation.service';
import { EventDialogComponent } from '../../../common/components/event-dialog/event-dialog.component'
import { CalendarEvent } from '../../../common/models/event-dialog.model'


export interface wcTemplate {
  Site: string;
  Name: string;
  Areas: string [];
  Days: string [];
}

export interface eventData{
  id: any;
  resourceID: any;
  start: any;
  end: any;
  title: any;
  backgroundColor: any;
  borderColor: any;
  rendering: any;
}

export interface DiffDefaultDaysObj {
  [key: string]: string[];
}

@Component({
  selector: 'app-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['./template-detail.component.scss']
})
export class TemplateDetailComponent implements OnInit {
  templateName: string;
  templateOptionsForm: UntypedFormGroup;
  environments: any[]=[];
  defaultDays: any;
  lastModified: string;
  date: number  = Date.now();
  today: string
  events: TemplateEventType[];
  getTemplateDetialsSubscription: Subscription;
  allEvents: TemplateEventType[];
  calendarDatasource: EventInput[];
  allAreas: any[]=[];
  allUniqueAreas: any[];
  allAreasSelection: any[]=[];
  reason: string;
  daysForm = new UntypedFormControl(["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]);
  daysOfWeek: string[] = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  selectedDays: string[];
  differentDefaultDaysFlag: boolean;
  differentDefaultDaysObj: DiffDefaultDaysObj; 
  site: string;
  dataSource: EventInput[];
  slotWidth: number;
  allResources: any[] = [];

  lockUpdatesSubscription: Subscription;
  getLockStatusSubscription: Subscription;
  changeLockStatusSubscription: Subscription;
  lock: Lock;
  lockName: string
  lockTimeLimit: number = 2;
  
  isAdmin = false;
  sso: string;
  canLock: boolean=true;
  haveAllLocks: boolean=false;
  otherUserHasLocks: boolean = false;
  isExpanded: boolean = false;  
  isScreen:boolean=false; 

  templateAreas:any[] =[]; // List of areas from the template
  localAreas:any[]=[]; // List of local areas (those from the database and added from Add Areas dialog)

  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
  @ViewChild('calendar', { static: true }) calendarComponent: FullCalendarComponent; // the #calendar in the template
  @ViewChild( 'mobileCalendar', { static: false }) mobileCalendarComponent: FullCalendarComponent; // the #calendar in the template
  @ViewChild('drawer', {static: true}) public drawer: MatDrawer;


  calendarOptions: CalendarOptions;

  mobileCalendarOptions: CalendarOptions;


  constructor(private route: ActivatedRoute, 
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private calendarService: CalendarService,
    private templateService: TemplatesService,
    private shiftLookupService: ShiftLookupService,
    private eventValidationService: EventValidationService,
    private geaMesCognitoAuthService: GeaMesCognitoAuthService,
    private lockAppSync: GeaLockService,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    public fb: UntypedFormBuilder) { 
      this.events = [];
      this.allAreas = [];
      this.allEvents = [];
      this.dataSource = [];
      this.allResources = [];
      this.allUniqueAreas = [];

      this.calendarOptions = {
        scrollTime : '08:00',
        eventOrder: ['title'],
        resourceAreaWidth: '12%',
        aspectRatio : 1,
        duration : '2:00',
        resourceAreaHeaderContent : 'Areas',
        schedulerLicenseKey : 'GPL-My-Project-Is-Open-Source',
        editable : false,
        views : { resourceTimelineDay: {
              buttonText: 'day',
              slotDuration: '01:00'
            },
            resourceTimelineTenDay: {
              type: 'resourceTimeline',
              duration: { days: 10 },
              buttonText: '10 days'
            }},
        slotMinWidth : this.slotWidth,
        height : 'auto',
        contentHeight : 'auto',
        nowIndicator : true,
        initialView:"resourceTimelineDay",
        plugins: [ resourceTimelinePlugin  ],
        headerToolbar:{
          left: '',
          center: '',
          right: ''
        },
        events: this.dataSource,
        resources : this.allResources,
        eventClick: this.handleEventClick.bind(this)
      };      

      this.mobileCalendarOptions = {
        initialView:"resourceTimeGridDay",
        plugins: [ resourceTimeGridDay ],
        scrollTime : '08:00',
        eventOrder: ['title'],
        aspectRatio : 1,
        duration : '2:00',
        resourceAreaHeaderContent : 'Areas',
        schedulerLicenseKey : 'GPL-My-Project-Is-Open-Source',
        editable : false,
        events: this.dataSource,
        slotMinWidth : this.slotWidth,
        height : 'auto',
        contentHeight : 'auto',
        nowIndicator : true,
        headerToolbar:{
          left: '',
          center: '',
          right: ''
        },
        resources : this.allResources,
        eventClick: this.handleEventClick.bind(this)
      };      
    }


  ngOnInit() {
    this.sso = this.geaMesCognitoAuthService.getUserAttribute("sso");
    this.templateName = this.route.snapshot.paramMap.get('template');
    this.site = this.route.snapshot.paramMap.get('site')
    this.lockName = `WCTEMP${this.site}${this.templateName}`;
    this.isScreen$.subscribe(event => this.isScreen = event.matches);
    this.geaMesCognitoAuthService.isUserInRole("Admin").subscribe( adminCheckResult =>{
      this.isAdmin = adminCheckResult
    });

    this.templateOptionsForm = new UntypedFormGroup({
      daysForm: new UntypedFormControl(),
      title: new UntypedFormControl(this.templateName)
    });

    this.calendarService.getAreasAPI(this.site, false).pipe(
      pluck('Body'),
      pluck('areas')
      ).subscribe((areas:any) =>{
        this.allUniqueAreas = areas;
        this.environments = [...new Set(areas.map(item => item.Environment))]
        this.getTemplateDetails();
    });
    
    this.checkLockStatus();
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.haveAllLocks){
      this.changeLockStatus('unlocked')
    }
  }

  onBack(){
    this.router.navigate(['template', 'Home', this.site]);
  }

  getTemplateDetails() {    
    if (this.getTemplateDetialsSubscription) this.getTemplateDetialsSubscription.unsubscribe()

    this.getTemplateDetialsSubscription = this.templateService.getTemplateDetails(this.environments, this.templateName).subscribe((templateData) => {
      this.calendarDatasource = []
      this.events = []
      this.allAreas = []
      this.allEvents = []
      let templateDetails = templateData['Body']

      this.templateAreas = templateDetails['Areas'];
      this.createAreaList();
      if (templateDetails['Status'] == 'EXISTING') {
        this.templateName = templateDetails['Template']
        this.defaultDays = templateDetails['DefaultDays'] ? templateDetails['DefaultDays'].split(",") : []
        this.selectedDays = this.defaultDays
        this.lastModified = templateDetails['Modify_by']
        this.reason = templateDetails['Reason']
        this.differentDefaultDaysFlag = templateDetails['DifferentDefaultDays']['DiffFlag']
        this.differentDefaultDaysObj = templateDetails['DifferentDefaultDays']['AreaDaysList']
        this.calendarDatasource = this.convertEvents(templateDetails['Areas'])
        this.calendarOptions.events = this.calendarDatasource
        this.calendarOptions.resources = this.allAreas
        this.mobileCalendarOptions.events = this.calendarDatasource
        this.mobileCalendarOptions.resources = this.allAreas
        this.eventValidationService.updateShiftList(templateDetails['Areas'], true)
        this.shiftLookupService.setShiftLookUp(this.allEvents, this.site, true)
        console.log("this.allAreas", this.allAreas);

      } else {
        this.changeLockStatus("locked")
      }
    })
  }

  createAreaList() {
    // This section sets the labels on the full calendar control
    let newAreas:any[] = [];
    let areaCheck:string[] = [];
    let loadLocalAreas:boolean = this.localAreas.length == 0 ? true : false; // If true, load localAreas array 

    for (let area in this.templateAreas){
      let events:any[] = this.templateAreas[area];
    
      events.forEach( event => {
        let id:string = event.Area + "_" + event.AuroraEnv;
        if (areaCheck.indexOf(id) == -1) {
          areaCheck.push(id);
          newAreas.push({
            "id": id,
            "title": event.ObjectDescription, 
            "area": event.Area, 
            "env": event.ProjectID, 
            "AuroraEnv": event.AuroraEnv,
            "events": true
          });
          
          // On initial load, prepopulate AddAreas array
          if (loadLocalAreas) {
            this.localAreas.push({
              "ObjectDescription": event.ObjectDescription,
              "Area": event.Area,
              "ProjectID": event.ProjectID,
              "Environment": event.AuroraEnv
            })
          }
        }
      })
    }

    this.localAreas.forEach(area => {
      let id:string = area.Area + "_" + area.Environment;
      if (areaCheck.indexOf(id) == -1) {
        areaCheck.push(id);      
        newAreas.push({
          "id": id,
          "title": area.ObjectDescription,
          "area": area.Area,
          "env": area.ProjectID,
          "AuroraEnv": area.Environment,
          "events": false
        })
      }
    });

    this.allAreas = newAreas;
    this.calendarOptions.resources = this.allAreas;
    this.mobileCalendarOptions.resources = this.allAreas;  

    // This section sets the event list for Create Events
    let areas = Object.keys(this.templateAreas);
    this.allAreasSelection = [...this.allUniqueAreas.filter(item => areas.indexOf(item.Area) > -1 ? true : false)]
    this.allAreasSelection = this.allAreasSelection.concat.apply(this.allAreasSelection, this.localAreas.filter(item => areas.indexOf(item.Area) > -1 ? false : true));

    // Sort areas by ObjectDescription
    this.allAreasSelection = this.allAreasSelection.sort( (a,b) => a.ObjectDescription > b.ObjectDescription ? 1 : a.ObjectDescription < b.ObjectDescription ? -1 : 0);

  }


  //convert array of eventTypes into FullCalendar's preferred event Type (EventInput)
  //also gather unique areas to be used for FullCalendar's resource
  convertEvents(arr: any[]) : any[] {
    for (let areaEvents in arr){
      for (let el in arr[areaEvents]) {
        this.events.push(arr[areaEvents][el]);
      }
    };

    this.allEvents = [].concat.apply([], this.events);

    //convert events into correct format
      return this.allEvents.map(function(el, i){
        let today = dayjs().format('YYYY-MM-DD');
        let eventStart = el.DisplayStartTime ? el.DisplayStartTime : el.StartTime
        let eventEnd = el.DisplayEndTime ? el.DisplayEndTime : el.EndTime
        if (el.BreakCatagory == "Pause") {
          return {id: i, resourceId: el.Area + "_" + el.AuroraEnv, start: dayjs(today + ' ' + eventStart).format('YYYY-MM-DDTHH:mm:ss'), end: dayjs(today + ' ' + eventEnd).format('YYYY-MM-DDTHH:mm:ss'), title: el.BreakCatagory, display: 'block', backgroundColor: '#fa8f2a', borderColor: '#fa8f2a'};
        }
    
        // event color depends on event type
        if (el.Event.substring(0,5) == "SHIFT") {
          return {id: i, resourceId: el.Area + "_" + el.AuroraEnv, start: dayjs(today + ' ' + eventStart).format('YYYY-MM-DDTHH:mm:ss'), end: dayjs(today + ' ' + eventEnd).format('YYYY-MM-DDTHH:mm:ss'), title: el.Shift, display: 'background', overlap:true};
        }
        else if (el.Event.substring(0,4) == "PAID") {
          return {id: i, resourceId: el.Area + "_" + el.AuroraEnv, start: dayjs(today + ' ' + eventStart).format('YYYY-MM-DDTHH:mm:ss'), end: dayjs(today + ' ' + eventEnd).format('YYYY-MM-DDTHH:mm:ss'), backgroundColor: '#cf3d36', borderColor: '#b23430', display: 'block', overlap:true};
        }
        else if (el.Event.substring(0,6) == "UNPAID") {
          return {id: i, resourceId: el.Area + "_" + el.AuroraEnv, start: dayjs(today + ' ' + eventStart).format('YYYY-MM-DDTHH:mm:ss'), end: dayjs(today + ' ' + eventEnd).format('YYYY-MM-DDTHH:mm:ss'), backgroundColor: '#faf12e', borderColor: 'rgba(198, 189, 44, 0.5)', display: 'block', overlap:true};
        }
        else {
          //set resourceId to empty so non work event will not show
          return {id: i, resourceId: el.Area + "_" + el.AuroraEnv, start: dayjs(el.ShiftDate).format('YYYY-MM-DDTHH:mm:ss'), end: dayjs(el.ShiftDate).add(1,'day').format('YYYY-MM-DDTHH:mm:ss'), backgroundColor: 'rgba(0, 0, 0, 0.25)', borderColor: '#ffffff', display: 'block', overlap:true};
        }
      });
    /*******************DATA COLLECTION*****************************/
  }

    //shift or event was selected; open event details
  handleEventClick(el) {
    let event = this.allEvents[el.event.id]
    event.Action = 'Modify'
    
    if (event.DisplayStartTime) {
      // A Shift record, need to add in the date
      let today = dayjs().format('YYYY-MM-DD')
      event.StartTime = dayjs(today + " " + event.StartTime).format('YYYY-MM-DDTHH:mm:ss')
      event.EndTime = dayjs(today + " " + event.EndTime).format('YYYY-MM-DDTHH:mm:ss')
    } else {
      // An event record, date already included
      event.StartTime = dayjs(event.StartTime).format('YYYY-MM-DDTHH:mm:ss')
      event.EndTime = dayjs(event.EndTime).format('YYYY-MM-DDTHH:mm:ss')
    }
    this.openEventDialog(event);
  }


  confirmDelete(){
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      width: '250px', //consider changing based on window size...
      data: {'site': this.site, 'template': this.templateName, 'environments': this.environments}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("closed")
    });
    
  }

  editAreas(){
    let width:string;
    if (this.isScreen) {
      console.log("is screen true");
      width = '400px';
    } else {
      console.log("is screen false");
      width = '250px';
    }
    const dialogRef = this.dialog.open(AreaEditDialogComponent, {
      minWidth: width, //consider changing based on window size...
      data: {'site': this.site, 'template': this.templateName, 'areas': this.allAreas,  'allAreas': this.allUniqueAreas}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("editAreas result", result);

      if (result != undefined && result.status == 'save') {
        this.localAreas = result.selectedAreas;

        this.createAreaList();
      }   
    });
  }

  updateDays($event){
    if(!$event){
      this.templateService.updateDefaultDays(this.templateName, this.environments, this.selectedDays).subscribe((status) => {
        this.getTemplateDetails()
      })
    }
  }

  renameTemplate(){
    const dialogRef = this.dialog.open(TemplateNameChangeDialogComponent, {
      width: '250px', //consider changing based on window size...
      data: {'template': this.templateName, 'areas': this.allAreas, 'environments': this.environments}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.templateName = result.template
      this.getTemplateDetails()
    });
  }

  createNewEvent(){
    let newEvent = this.setNewEvent()
    this.openEventDialog(newEvent)
  }

  setNewEvent(){
    let newEvent = new TemplateEvent(this.templateName, this.defaultDays)
    return newEvent
  }

  openEventDialog(event: any): void{
    if (typeof this.allAreasSelection !== 'undefined'){
      let selectedAreas = this.allAreasSelection.find(eventArea => eventArea.Area == event.Area)
      console.log(event)
      const dialogRef = this.dialog.open(EventDialogComponent, {
        width: '250px',
        data: {
          'Event': event,
          'Shift': this.getEventShift(event), 
          'HaveAllLocks': this.haveAllLocks,
          'Site': this.site,
          'Areas': {
            'AvailableAreas': this.allAreasSelection, 
            'SelectedAreas': selectedAreas ? [selectedAreas] : []
          },
          'TemplateEvent': true,
          'SelectedDate': dayjs(event.StartTime).format("YYYY-MM-DD")
        }
      });

      dialogRef.afterClosed().subscribe( result => {
        this.getTemplateDetails();
      });
    }
  }

  checkLockStatus(){
    this.getLockStatusSubscription = this.lockAppSync.getLockStatus(this.lockName).subscribe((lockinfo) =>{
      this.lock = lockinfo ? lockinfo : {'lockobject': '', 'lockstatus': 'unlocked', 'locktime': ''};
      this.lock.displayname = this.lock.lockobject;
      this.setCanLock();
    });
    this.subscribeToLockUpdate();
  }

  setCanLock() {
    console.log("Call setCanLock()");
    let otherUserLock:boolean = false;
    let lockCount:number = 0;

    if (this.lock) {
      if (this.lock.lockstatus == "locked" && dayjs().diff(this.lock.locktime, 'hours') <= this.lockTimeLimit) {
        lockCount += 1;
        let lockSSO = this.lock.sso;        
        // Lock is under Cognito userid which may have extra data, strip out to only get SSO
        if (lockSSO.indexOf("_")) {
          lockSSO = lockSSO.substring(lockSSO.lastIndexOf("_") + 1)
        }

        if (lockSSO != this.sso) {
          otherUserLock = true;
        } 
      }
    }

    if (lockCount != 0) {
      this.canLock = false;

      if (otherUserLock) {
        this.haveAllLocks = false;
        this.otherUserHasLocks = true
      } else {
        this.haveAllLocks = true;
        this.otherUserHasLocks = false
      } 
    } else {
      this.haveAllLocks = false;
      this.canLock = true;
      this.otherUserHasLocks = false;
    }
  }

  openBottomSheet(): void {

    this._bottomSheet.open(LockInfoBottomSheet, 
    {
      data: this.lock
    });
  }

  subscribeToLockUpdate(){
    this.lockUpdatesSubscription = this.lockAppSync.subscribeToLockUpdates().subscribe({
      next: result => {
        if (result.data.updateLock.lockobject == this.lockName) {
          if (dayjs(this.lock.locktime).isSameOrAfter(dayjs(result.data.updateLock.locktime))) {
            console.debug("This is an old lock");
          } else {
            console.debug("This is a lock for this page");
            this.lock = result.data.updateLock;
            this.lock.displayname = this.lock.lockobject;
          }
          this.setCanLock();
        }
      },
      error: error => {
        console.log("Error", error)
      }
    })
  }

  changeLockStatus(lockstatus: string){
    if(lockstatus == "unlocked")
      this.isExpanded = false

    this.changeLockStatusSubscription = this.lockAppSync.changeLockStatus(this.lockName, lockstatus).subscribe(({data}) =>{
        console.log(data)
    })
  }
  
  getEventShift(event: any): CalendarEvent {
    return this.allEvents.filter(e => e.Area === event.Area)
        .filter(e => e.Event.includes("SHIFT"))
        .filter(e => e.Shift === event.Shift)[0];
  }
}