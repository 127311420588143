import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { pluck, map } from 'rxjs/operators';
import { CalendarService } from 'src/app/services/calendar.service';
import { Observable, Subscription } from 'rxjs';
import { LocationSelectService } from 'src/app/services/location-select.service';
import { DeleteAreaGroupService } from '../services/delete-area-group.service';
import { ManageGroupService } from '../services/manage-group.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UpdateGroupsService } from '../services/update-groups.service';

export interface selectedOptions {
  groups: string
  areas: string[]
}

@Component({
  selector: 'app-area-group-admin',
  templateUrl: './area-group-admin.component.html',
  styleUrls: ['./area-group-admin.component.scss']
})
export class AreaGroupAdminComponent implements OnInit {
  panelOpenState = false;
  subscription: Subscription;
  allGroups: any[];
  selectedSite: string;
  siteData: Observable<any>;
  areas: string[];
  selectedOptions: any[];
  selectedGroup: any[];
  uniqueEnvironments: any[];
  isMobile: boolean;

  constructor(private calendarService: CalendarService,
    private locationSelectServ: LocationSelectService,
    private dialog: MatDialog,
    private deleteAreaGroupServ: DeleteAreaGroupService,
    public breakpointObserver: BreakpointObserver,
    public updateGroupServ: UpdateGroupsService ) {
      this.selectedOptions = [];
    }

  ngOnInit() {
    this.getSites()

    this.breakpointObserver
      .observe(['(min-width: 500px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true
        } else {
          this.isMobile = false
        }
      });

    this.updateGroupServ.getUpdateGroupAfterSubmit().subscribe((data) =>{
      this.handleSiteClick(this.selectedSite)
    })
  }

  getSites(){
    this.siteData = this.locationSelectServ.getSites().pipe(
      map((data) => {
        return data['Body']
      })
    )
  }

  getAreas(site: string){
    let d: Observable<any[]> =
        this.calendarService.getAreasAPI(site, false)
        .pipe(
        pluck('Body')
      );
      
    if (this.subscription) this.subscription.unsubscribe();

    this.subscription = d.subscribe((next) => {
      this.areas = this.convertAreas(next['areas']);
      this.allGroups = next['groups'];
    });
  }

  convertGroups(groupArr: any[]){
    let arr: any[] = []
    groupArr.forEach((el) =>{
      arr.push(Object.keys(el)[0])
    })
    return arr
  }


  convertAreas(arr: any[]){
    let newArr: any[] = [];
    this.uniqueEnvironments = [];
    arr.forEach((el) => {
      if (!this.uniqueEnvironments.includes(el.Environment)){
        this.uniqueEnvironments.push(el.Environment);
      }
      newArr.push(el)
    });

    return newArr;
  }

  handleSiteClick(name){
    this.selectedSite = name
    this.getAreas(name)
    
  }

  areasSelected(){
  }

  onSelection(site){
    this.selectedOptions = site.selectedOptions.selected
  }

  deleteGroup(selectedGroup){
    let group = selectedGroup
    let site = this.selectedSite
    
    let areaToDel = {
        groupName: group,
        site: site
      }

    this.deleteAreaGroupServ.deleteGroups(areaToDel).subscribe((data) =>{
      this.handleSiteClick(site)
    })
  }

  editAreas(areas, group){
    const dialogRef = this.dialog.open(EditGroupDialog, {
      width: '250px',
      height: '700px',
      data: {areas: areas, group: group, allareas: this.areas, site: this.selectedSite}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.handleSiteClick(this.selectedSite)
      
    });
  }

  addGroup(){
    const dialogRef = this.dialog.open(AddGroupDialog, {
      width: '250px',
      data: {site: this.selectedSite, areas: this.areas}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.handleSiteClick(this.selectedSite)
    });
  }

}


@Component({
  selector: 'edit-group-dialog',
  templateUrl: 'edit-group-dialog.html',
  styleUrls: ['./area-group-admin.component.scss']
})
export class EditGroupDialog {
  editData: any;
  areaOptions: any[];
  newArea: any;
  areasToDelete: any[];
  groupAreas: any[];

  allAreas: any[];
  areaDropDownOptions: any[];
  areasInGroup: any[];
  displayedColumns: string[] = ["areaSelected", "areaOption"];

  constructor(
    public dialogRef: MatDialogRef<EditGroupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public deleteAreaGroupServ: DeleteAreaGroupService,
    public manageGroupServ: ManageGroupService,
    private changeDetect: ChangeDetectorRef) {
      this.editData = data
      let allAreasData = this.editData['allareas']
      this.allAreas = allAreasData
      this.areaDropDownOptions = Array.from(this.editData.allareas)
      this.areasInGroup = this.editData.areas
    }

  ngOnInit(){
    this.selectExistingAreas()
    this.areaDropDownOptions.sort((a,b) => (a["Area"] > b["Area"]) ? 1:-1);
  }

  selectExistingAreas() {
    for(let x = 0; x < this.areaDropDownOptions.length; x++){
      this.areaDropDownOptions[x]["selected"] = false;
    }

    for (let i = 0; i < this.areasInGroup.length; i++){
      for(let j = 0; j < this.areaDropDownOptions.length; j++){
        if (this.areasInGroup[i]["area"] == this.areaDropDownOptions[j]["Area"] + '-' + this.areaDropDownOptions[j]["Environment"]){
          this.areaDropDownOptions[j]["selected"] = true;
        }
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateSelection(event: any, areaOption: any){
    if(event["checked"]){
      this.manageGroupServ.addAreas(this.editData.group, areaOption['Area'], this.editData.site, areaOption['Environment']).subscribe((data) =>{
        console.log("Added area " + areaOption["ObjectDescription"] + " from group " + this.editData.group);
      })
    }else{
      let areaToDel = {
          groupName: this.editData.group,
          area: areaOption["Area"],
          site: areaOption["Site"],
          environment: areaOption["Environment"]
      }

      this.deleteAreaGroupServ.deleteGroups(areaToDel).subscribe((data) =>{
        console.log("Removed area " + areaOption["ObjectDescription"] + " from group " + this.editData.group);
      })
    }
  }
}


@Component({
  selector: 'add-group-dialog',
  templateUrl: 'add-group-dialog.html',
  styleUrls: ['./area-group-admin.component.scss']
})
export class AddGroupDialog {
  selectedSite: string
  groupName: string
  groupNameForm: UntypedFormGroup
  allAreas: any

  constructor(
    public dialogRef: MatDialogRef<AddGroupDialog>,
    public dialogRefEdit: MatDialogRef<EditGroupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public deleteAreaGroupServ: DeleteAreaGroupService,
    public manageGroupServ: ManageGroupService,
    private dialog: MatDialog,
    public fb: UntypedFormBuilder,
    public updateGroupsServ: UpdateGroupsService) {
      this.selectedSite = data.site
      this.allAreas = data.areas
      this.groupName = ''
    }

    ngOnInit(){
      this.groupNameForm = this.fb.group({
        groupName: ''
      })

      this.groupNameForm.valueChanges.subscribe((data) =>{
        this.groupName = data.groupName
      })
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    saveGroup(){
      this.dialogRef.close();
      const dialogRef = this.dialog.open(EditGroupDialog, {
        width: '250px',
        data: {areas: [], group: this.groupName, allareas: this.allAreas, site: this.selectedSite}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.updateGroupsServ.updateGroupAfterSubmit()
      });
    }

}