<button mat-raised-button (click)="onBack()" class="goBackBtn">Go Back</button>

<h1>Edit Prior Shift</h1>

<gea-mes-datepicker
class="dateRangeBut"
matTooltip="Select Date" 
(onDateSelect)="onDateSelection($event)" 
(onDateInit)="onDateSelection($event)"
singleDate="false" 
showLabel="true"
showCurrentShift="false"
defaultRange="Year To Date"
showRangeLabel="true"
timeRangeExclude="Today,this week,tHiS MoNth">
</gea-mes-datepicker>

<div class="content">
    <div *ngFor="let area of dataSource; let i = index" class="content-card">
        <mat-card class="shift-list-card">
            <mat-card-title>
                <h2>{{area.Area}} - {{area.AreaDesc}}</h2>
            </mat-card-title>
            <mat-card-content>
                <mat-table [dataSource]="area.Shifts"  style="overflow:auto; height:400px;"> 
                    <ng-container matColumnDef="ShiftDate">
                        <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Shift Date</mat-header-cell>
                        <mat-cell *matCellDef="let item">{{item.ShiftDate}}</mat-cell>
                    </ng-container>
                
                    <ng-container matColumnDef="Shift">
                        <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Shift</mat-header-cell>
                        <mat-cell *matCellDef="let item">{{item.Shift}}</mat-cell>
                    </ng-container>
                
                    <ng-container matColumnDef="StartTime">
                        <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Start</mat-header-cell>
                        <mat-cell *matCellDef="let item">{{item.ShiftStart | date:'HH:mm:ss'}}</mat-cell>
                    </ng-container>
                
                    <ng-container matColumnDef="EndTime">
                        <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>End</mat-header-cell>
                        <mat-cell *matCellDef="let item">{{item.ShiftEnd | date:'HH:mm:ss'}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="UnitsProduced">
                        <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Metrics</mat-header-cell>
                        <mat-cell *matCellDef="let item" (click)="openEOSMetrics(item)">
                            <span *ngIf="item.UnitCount > 0">Units Produced</span>
                            <span *ngIf="item.UnitCount == 0">No Units Produced</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Action">
                        <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Action</mat-header-cell>
                        <mat-cell *matCellDef="let item">
                            <button mat-button color="warn" (click)="deleteShift(item)"><mat-icon>delete_forever</mat-icon>Remove Shift</button>
                        </mat-cell>
                    </ng-container>
                
                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

            </mat-card-content>
            <mat-card-actions>

            </mat-card-actions>
        </mat-card>
    </div>
</div>