import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Calendar } from '@fullcalendar/core';
import {​​​​ GeaMesCognitoAuthService }​​​​ from '@gea-mes/cognito';
import { Observable, Subscription} from 'rxjs';
import { appinfo } from './appinfo';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { filter, pluck } from 'rxjs/operators';
import { CalendarService } from "./services/calendar.service";
import { environment } from 'src/environments/environment';
import { LocationSelectService } from './services/location-select.service';
import { GEA_MES_NAVBAR_APPLICATION_NAME } from '@gea-mes/navbar';

declare var gtag_setValue; // Declare function defined in index.html


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  title = 'workCalendar';
  isTablet$: boolean = this.breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.XSmall])

  logon$: Subscription;
  trackingid:string;

  helpURL:string;
  routeData: any;
  site: string;
  siteData: any;
  displayName:string = '';
  displayNameObservable: Observable<any>;
  displayNameSubsription: Subscription;

  constructor(private breakpointObserver: BreakpointObserver,
    private geaMesCognito: GeaMesCognitoAuthService,
    private router: Router,
    private location: Location,
    private locationSelectServ: LocationSelectService,
    private calendarService:CalendarService,
    private route:ActivatedRoute
    ) {
    const name = Calendar.name

    const navEndEvent$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      this.calendarService.isLoading.next(false);
    });

    this.helpURL = environment.helpURL;
    
  }

  ngOnInit(): void {
    gtag_setValue({"appversion": appinfo.version});
    
    this.logon$ = this.geaMesCognito.sessionEstablished$.subscribe( out => {
      this.trackingid = this.geaMesCognito.getUserAttribute("trackingid");
      if (this.trackingid != undefined) {
        gtag_setValue({"sub": this.trackingid});
        if (this.logon$) this.logon$.unsubscribe(); // We've done the work we need to, can unsubscribe
      }       
    });

    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        if(data.state.root.firstChild){
          this.routeData = data.state.root.firstChild.params;
            if(data.state.root.firstChild.firstChild){
              this.routeData = data.state.root.firstChild.firstChild.params;
            }
        }
      }
    });

    this.location.onUrlChange(url => {
      this.updateSite();
    }); 

  }

  updateSite(){
    if("site" in this.routeData){
      if(this.site != this.routeData.site){
        this.site = this.routeData.site;
        if(this.displayNameSubsription) this.displayNameSubsription.unsubscribe()
        this.displayNameObservable = this.locationSelectServ.getSites().pipe(
          pluck("Body")
        )
        this.displayNameSubsription = this.displayNameObservable.subscribe((data) => {
          this.siteData = data;
          for(let i=0; i<this.siteData.length; i++){
            if(this.siteData[i].Site == this.site){
              this.displayName = this.siteData[i].display_name;
            }
          }
        })
      }
    }else {
      this.site = "Home";
      this.displayName = "GE Appliances";
    }
  }

}
