import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TemplatesService } from '../../../services/templates.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-delete-confirm',
  templateUrl: './delete-confirm.component.html',
  styleUrls: ['./delete-confirm.component.scss']
})
export class DeleteConfirmComponent implements OnInit {

  template: string;
  site: string;
  environments: string[];

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<DeleteConfirmComponent>,
      private router: Router,
      private templatesService: TemplatesService
    ) { 
      this.template = this.data.template
      this.site = this.data.site
      this.environments = this.data.environments
    }
    
  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteTemplate() {

    let environment = ""
    for (let i = 0; i < this.environments.length; i++){
      if (i == 0){
        environment += this.environments[i]
      }else{
        environment += ","
        environment += this.environments[i]
      }
    }

    let template = {
      "name": this.template,
      "environment": environment
    }

    this.templatesService.deleteTemplate(template).subscribe((data)=>{

      this.router.navigate(['/template/Home', this.site]);
    })
    this.dialogRef.close();
  }
}
