<button mat-raised-button (click)="onBack()" class="goBackBtn">Go Back</button>

<h1>Pause Areas</h1>

<div class="box">
  <div>
    <button [disabled]="loadingStatus" mat-button mat-raised-button color="primary" (click)="toggleShiftCount()">
      <span *ngIf="shiftCount == 1">Show Future Shifts</span>
      <span *ngIf="shiftCount > 1">Hide Future Shifts</span>
    </button>
  </div>
  <div>
    Unpause all displayed areas currently paused.
  </div>
</div>

<div *ngIf="shiftCount == 1 && showAllPause" class="box">
  <div>
    <button [disabled]="loadingStatus" mat-button mat-raised-button color="warn" (click)="pauseAllAreas()">Pause All Areas</button>
  </div>
  <div>
    Pause all displayed areas that are in shift and not currently paused.
  </div>
</div>
<div *ngIf="shiftCount == 1 && showAllUnpause" class="box">
  <div>
    <button [disabled]="loadingStatus" mat-button mat-raised-button color="primary" style="background-color:green " (click)="unpauseAllAreas()">Unpause All Areas</button>
  </div>
  <div>
    Unpause all displayed areas currently paused.
  </div>
</div>

<div class="content" style="max-width: 800px; min-width:800px">
  <div *ngFor="let area of areaShiftList" class="content-card">
    <mat-card>
      <mat-card-title>
        <h2>{{area.Area}} - {{area.AreaDesc}}</h2>
      </mat-card-title>
      <mat-card-content>
        <mat-table [dataSource]="area.Shifts"  style="overflow:auto;"> 
          <ng-container matColumnDef="ShiftDate">
              <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Shift Date</mat-header-cell>
              <mat-cell *matCellDef="let item">{{item.ShiftDate}}</mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="Shift">
              <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Shift</mat-header-cell>
              <mat-cell *matCellDef="let item">{{item.Shift}}</mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="StartTime">
              <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Start</mat-header-cell>
              <mat-cell *matCellDef="let item">{{item.ShiftStart | date:'HH:mm:ss'}}</mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="EndTime">
              <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>End</mat-header-cell>
              <mat-cell *matCellDef="let item">{{item.ShiftEnd | date:'HH:mm:ss'}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Status">
            <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef>Pause Status</mat-header-cell>
            <mat-cell *matCellDef="let item">
              <span *ngIf="item.PauseStart == ''">Not paused.</span>
              <span *ngIf="item.PauseStart != ''">Pause started at {{item.PauseStart}}</span>
            </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="Action">
            <mat-header-cell mat-header-cell mat-sort-header *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let item; let i = index">
              <button [disabled]="loadingStatus" mat-raised-button color="warn" *ngIf="item.ShiftStart != null && item.PauseStart == ''" (click)="pauseArea(item.Area, item.ShiftDate, item.Shift)">Pause {{item.Shift}}</button>
              <button [disabled]="loadingStatus" mat-raised-button color="primary" style="background-color:green " *ngIf="item.ShiftStart != null && item.PauseStart != ''" (click)="unpauseArea(item.Area, item.ShiftDate, item.Shift, item.ShiftStart, i)">Unpause {{item.Shift}}</button>                
            </mat-cell>
          </ng-container>
    
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        </mat-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>