import { Component,  OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-activity-select',
  templateUrl: './activity-select.component.html',
  styleUrls: ['./activity-select.component.scss']
})
export class ActivitySelectComponent implements OnInit {
  site: string;
  status: string;
  displayName: string;
  showPrevShiftEdit:boolean=false; // Temp flag to disable showing manage prior shift
  
  constructor(private route: ActivatedRoute, 
              private router: Router
              ) { }

  ngOnInit() {
    this.site = this.route.snapshot.paramMap.get('site');
    
  }

  goToActive(siteName){
    this.router.navigate(['/area/select', siteName,'active', 'ALL']);
  }

  goToTemplate(siteName){
    this.router.navigate(['/template/Home', siteName]);
  }

  goToPauseMenu(siteName){
    this.router.navigate(['/area/select', siteName,'pause','ALL']);
  }

  goToBreakEarly(siteName) {
    this.router.navigate(['/area/select', siteName,'breakearly','ALL']);
  }

  goToEasyShift(siteName) {
    this.router.navigate(['/area/select', siteName,'easyshift','ALL']);
  }

  goToPrevShift(siteName) {
    this.router.navigate(['/area/select', siteName,'prevshift','ALL']);
  }

  ngOnDestroy(){
    
  }

}
