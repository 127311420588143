<form class="Template-Name fx-column-space-around-center" [formGroup]="templateOptionsForm">
    <mat-form-field>
        <mat-label>New Template Name: </mat-label>
        <input matInput placeholder="Enter New Name" formControlName="title" [(ngModel)]="newTemplatename">
    </mat-form-field> 

    <div class="fx-row-space-around-center">
        <button mat-raised-button color="primary" (click)="saveNewTemplate()">Save</button>
        <button mat-raised-button color="primary" (click)="cancel()">Cancel</button>
    </div>    
</form>
