import { Injectable } from '@angular/core';
import { BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import { AbstractRestService } from '@gea-mes/cognito'; 
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationSelectService extends AbstractRestService {
  postInit(): void {
  }


  private location = new BehaviorSubject<string>('default');
  cast = this.location.asObservable();

  selectLocation(loc){
    this.location.next(loc);
  }

  getSites(){
    let params = new HttpParams({fromObject: {}});
    return this.get({
      ApiResource: "/getsites",
      data: params,
      repeatCall: false
    }); 
  }


}

