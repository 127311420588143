<form [formGroup] = "groupNameForm">
    <mat-form-field class="example-full-width">
        <mat-label>Enter Group Name</mat-label>
        <input matInput placeholder="Group Name" formControlName="groupName">
    </mat-form-field>
</form>
<div mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" (click)="saveGroup()">Create Group</button>
    <span class="example-spacer"></span>
    <button mat-button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
</div>