<div>
  <button mat-raised-button (click)="onBack()" class="goBackBtn">Go Back</button>
  <h1 class="center">Select Area(s)</h1>
</div>

<h2> Proficy Projects: </h2>
<div *ngIf="allEnvironments.length > 1" class="fx-row">
    <button mat-raised-button color="primary" *ngFor="let env of uniqueEnvironments" (click)="filterByEnvironment(env)" style="margin-right: 10px">
      {{env}}
    </button>
</div>

<br>
<div *ngIf="isLoaded && !allLines.length" class="togcenter" style="color:red">
  No Areas Avaiable
</div>

<h2 *ngIf="(allGroups | json) != ({} | json)">Groups: </h2>
<mat-button-toggle  *ngFor="let grp of allGroups | keyvalue" class="toggle togcenter"  [checked]="grp.value.Checked" (change)="grp.value.Checked=onSelectionG($event.source.checked,grp)">{{grp.key}}</mat-button-toggle>
<mat-divider *ngIf="(allGroups | json) != ({} | json)"></mat-divider>
<h2>Areas: </h2>
<mat-button-toggle  *ngFor="let line of displayLines " class="toggle togcenter"  [checked]="line.Checked" (change)="line.Checked=onSelection($event.source.checked)">{{line.ObjectDescription||line.description}}</mat-button-toggle>
<div style="height:75px;background-color:white">

</div>
<div class="center">
  <button color="primary" mat-raised-button [disabled]="!hasSelection" id="submit" class="btn center blue mobile-btn" type="button" label="Submit" (click)="handleClick()">Submit</button>
</div>