import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-eos-metrics-dialog',
  templateUrl: './eos-metrics-dialog.component.html',
  styleUrls: ['./eos-metrics-dialog.component.scss']
})
export class EosMetricsDialogComponent implements OnInit {

  shift:any;

  displayedColumns: string[] = ["ObjectID", "Target", "Actual"];

  constructor(
    public dialogRef: MatDialogRef<EosMetricsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) {
    this.shift = this.inData.Shift;


   }

  ngOnInit(): void {
    console.log("Shift info!!", this.shift);
  }

  onNoClick():void {
    console.log("Close");
    this.dialogRef.close();
  }

}
