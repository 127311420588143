<h1 mat-dialog-title>Group: {{editData.group}}</h1>
<div mat-dialog-content>
  <h3>Areas to Include in Group</h3>
  <mat-table [dataSource]="areaDropDownOptions" class="areaOptionTable">

    <ng-container matColumnDef="areaSelected" >
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let areaOption" class="areaCheckboxRow"><mat-checkbox [checked]="areaOption.selected" (change)="updateSelection($event, areaOption)" color="primary"></mat-checkbox></td>
    </ng-container>

    <ng-container matColumnDef="areaOption">
      <th mat-header-cell *matHeaderCellDef> Area </th>
      <td mat-cell *matCellDef="let areaOption" class="areaTextRow"> {{areaOption.ObjectDescription}} </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </mat-table>
</div>
<div mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
</div>