<button mat-raised-button class="goBackBtn" matTooltip="Previous Day" routerLink="/locations">Go Back</button>

<div style="height:10px;background-color:white">

</div>
<div class="center">
    <button color="primary" mat-raised-button label="Click" (click)="goToActive(this.site)">Active Calendar</button>
</div>
<div style="height:25px;background-color:white">

</div>
<div class="center">
    <button color="primary" mat-raised-button (click)="goToTemplate(this.site)">Maintain Calendar Templates</button>
</div>
<div style="height:25px;background-color:white"></div>
<div class="center">
    <button color="primary" mat-raised-button (click)="goToPauseMenu(this.site)">Pause Areas</button>
</div>

<div style="height:25px;background-color:white"></div>
<div class="center">
    <button color="primary" mat-raised-button (click)="goToBreakEarly(this.site)">Break Early</button>
</div>

<div style="height:25px;background-color:white"></div>
<div class="center">
    <button color="primary" mat-raised-button (click)="goToEasyShift(this.site)">Manage Current Shift</button>
</div>
<div style="height:25px;background-color:white"></div>
<div class="center">
    <button *ngIf="showPrevShiftEdit" color="primary" mat-raised-button (click)="goToPrevShift(this.site)">Manage Previous Shifts</button>
</div>