import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule, routingComponents} from './app-routing.module';
import { AppComponent } from './app.component';
import { LocationSelectService } from './services/location-select.service';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatNativeDateModule } from "@angular/material/core";
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { LocationSelectComponent } from './main/location-select/location-select.component';
import {AreasSelectComponent} from "./main/areas-select/areas-select.component";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";

import { ActivitySelectComponent } from './main/activity-select/activity-select.component';
import { PauseComponent } from './pause/pause.component';

import { ReasonDialogComponent } from './main/reason-dialog/reason-dialog.component';

import { SharedCommonModule} from "./common/shared-common.module";
import { AdminModule } from './admin/admin.module';
import { TemplateModule } from './template/template.module';
import { LockInfoBottomSheet } from './main/lock-info-bottom-sheet/lock-info-bottom-sheet';
import { LogOutButtonComponent } from './main/log-out-button/log-out-button.component';
import { LogOutPageComponent } from './main/log-out-page/log-out-page.component';

import { GeaMesVersionModule } from '@gea-mes/utility'
import { GeaMesDatepickerModule } from '@gea-mes/datepicker';

import { appinfo } from './appinfo';
import { environment } from '../environments/environment'

import { PipesModule } from './pipes/pipes.module';
import { BreakEarlyComponent } from './break-early/break-early.component';
import { AreaShiftListComponent } from './easy-shift/components/area-shift-list/area-shift-list.component';
import { ChangeEndtimeDialogComponent } from './easy-shift/components/change-endtime-dialog/change-endtime-dialog.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PriorShiftEditComponent } from './prior-shift-edit/prior-shift-edit.component';
import { EosMetricsDialogComponent } from './prior-shift-edit/eos-metrics-dialog/eos-metrics-dialog.component';
import { RemoveShiftDialogComponent } from './prior-shift-edit/remove-shift-dialog/remove-shift-dialog.component';
import { GeaMesNavbarModule }from '@gea-mes/navbar';

const configOptions = {
  version: appinfo.version,
  name: appinfo.name,
  graphqlUrl: environment.graphqlUrl,
  graphqlAuthType: environment.graphqlAuth.type
}

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    LocationSelectComponent,
    AreasSelectComponent,
    ActivitySelectComponent,
    PauseComponent,
    ReasonDialogComponent,
    LockInfoBottomSheet,
    LogOutButtonComponent,
    LogOutPageComponent,
    BreakEarlyComponent,
    AreaShiftListComponent,
    ChangeEndtimeDialogComponent,
    PriorShiftEditComponent,
    EosMetricsDialogComponent,
    RemoveShiftDialogComponent
  ],
  imports: [
    SharedCommonModule,
    GeaMesVersionModule,
    AdminModule,
    TemplateModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    PipesModule,
    NgxMaterialTimepickerModule,
    MatTableModule,
    MatSelectModule,
    GeaMesDatepickerModule,
    GeaMesNavbarModule
  ],
  exports: [],
  providers: [
    LocationSelectService,
    {provide: 'APP_VERSION_CONFIG', useValue: configOptions}  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
