<div mat-dialog-content>
    <h3>Manage Areas: {{templateName}} </h3>    
    <div>
        <h4>Only areas with no shifts can be unchecked.</h4>
        <mat-table [dataSource]="areas"  matSort  matSortActive="Name" matSortDirection="asc" matSortStart="asc" style="overflow:auto; height:300px;">
            <ng-container matColumnDef="Selection">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <mat-checkbox (click)="$event.stopPropagation()" 
                                (change)="$event ? selection.toggle(item.Area) : null" 
                                [checked]="selection.isSelected(item.Area)" [disabled]="areasWithEvents.indexOf(item.Area) > -1 ? true : false"></mat-checkbox>
                </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="Area">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Area </mat-header-cell>
                <mat-cell *matCellDef="let item"> {{item.ObjectDescription}} </mat-cell>
            </ng-container>
    
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>
<div mat-dialog-actions class="fx-row-space-between-end">
    <div>
      <button mat-raised-button color="primary" type="submit" (click)="onUpdateAreas()">Save</button>
    </div>
    <div>      
      <button mat-button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
    </div>
</div>