import {Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@Pipe({name: "amLocal"})
export class AmLocalPipe implements PipeTransform{
    transform(value: any): dayjs.Dayjs {
        return dayjs.utc(value).local()        
    }
}