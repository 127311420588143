<button mat-raised-button (click)="onBack()" class="goBackBtn">Go Back</button>

<h1>Start Break Early</h1>

<div class="content">
  <div *ngFor="let area of dataSource" class="content-card">
    <mat-card>
      <mat-card-title>
        <h2>{{area.Area}} - {{area.AreaDesc}}</h2>
      </mat-card-title>
      <mat-card-title>
      <h3>{{area.ShiftDate}} {{area.Shift}}</h3>
      </mat-card-title>
      <mat-card-content>
        <ng-container *ngIf="area.StatusMsg == 'Not in Shift'; then noShift; else inShift"></ng-container>

        <ng-template #noShift>
          Not in a Shift, cannot start a break early.
        </ng-template>

        <ng-template #inShift>
          <ng-container *ngIf="area.StatusMsg == 'No Break Available'; then noBreakExists; else breakExists"></ng-container>

          <ng-template #breakExists>
            <div>{{area.StatusMsg}}: {{area.BreakStartTime | date:'mediumTime'}} to {{area.BreakEndTime | date:'mediumTime'}}</div>
          </ng-template>

          <ng-template #noBreakExists>
            No break avialable to start early, go to Active calendar and manually add a break if one is needed.
          </ng-template>

        </ng-template>



      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" *ngIf="area.StatusMsg == 'Break Available'" (click)="startBreakEarly(area.Area, area.ShiftDate, area.Shift)">Start {{area.Area}} Break Early</button>
      </mat-card-actions>
    </mat-card>
</div>
</div>