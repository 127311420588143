import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EasyShiftServiceService extends AbstractRestService {

  postInit(){}


  getEasyShiftList(envs:string[], areas:string[]):Observable<GenericLambdaReturn<EasyEditArea[]>> {
    let parms: any = {};

    parms.environments = envs.join(",");
    parms.arealist = areas.join(",");
    
    return this.get({
      ApiResource: "/easyshift",
      data: parms,
      repeatCall: true,
      repeatRate: 60000
    })
  }

  updateShiftEndTime(envs:string[], area:string, endTime:string, reason:string):Observable<GenericLambdaReturn<any>> {
    let parms: any = {};

    parms.environments = envs.join(",");
    parms.Area = area;
    parms.EndTime = endTime;
    parms.Reason = reason;
    
    return this.delete({
      ApiResource: "/easyshift",
      data: parms,
      repeatCall: false
    })
  }

}

export type EasyEditArea = {
  ProjectID: string,
  Area: string,
  CurrentShiftStatus: string;
  CurrentShiftDate: Date,
  CurrentShift: string,
  CurrentShiftStart: Date,
  CurrentShiftEnd: Date,
  BreakStartTime: Date,
  BreakEndTime: Date,
  BreakStatusMsg: string,
  NextShiftStatus: string,
  NextShiftDate: Date,
  NextShift: string,
  NextShiftStart: Date,
  NextShiftEnd: Date
}