<div style="height:10px;background-color:white"></div>
<button mat-raised-button (click) = "onBack()" style = "margin-left: 5px;">Go Back</button>
<button *ngIf="templatesLoaded" mat-raised-button (click) = "goToCreateTemplate(this.site)" color="primary" style = "margin-left: 5px;">Create New Template</button>

<div style="height:5px;background-color:white"></div>

<table mat-table [dataSource]="dataSource"  multiTemplateDataRows  *ngIf="(isScreen$ | async)?.matches" matSort  matSortActive="Name" matSortDirection="asc" matSortStart="asc"> 
    
    <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Template Name </th>
        <td mat-cell *matCellDef="let item"> {{item.Name}} </td>
    </ng-container>
    
    <ng-container matColumnDef="Areas">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Area(s)</th>
        <ng-container *matCellDef="let item">
            <td mat-cell *ngIf="item.Areas.length >= 10">
                <mat-accordion>
                    <mat-expansion-panel class="mat-acc-panel">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{item.Areas | slice:0:3}} ...
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list>
                        <mat-list-item *ngFor="let area of item.Areas">
                            {{area}}
                        </mat-list-item>
                    </mat-list>
                    </mat-expansion-panel>
                </mat-accordion>
            </td>
            <td mat-cell *ngIf="item.Areas.length < 10">{{item.Areas}}</td>
        </ng-container>
    </ng-container>
  
    <ng-container matColumnDef="Days">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Default Day(s)</th>
        <td mat-cell *matCellDef="let item">{{item.Days}}<mat-icon *ngIf="item.DifferentDefaultDays.DiffFlag" style="color: red; font-size: 20px; margin-left: 5px">error</mat-icon></td>
    </ng-container>

  
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let item" [attr.colspan]="displayedColumns.length">
            <div class="template-button-details"
                [@showButtons]="item == expandedItem ? 'expanded' : 'collapsed'">
                <div class="item-button-space">
                    <button *ngIf="expandedItem" mat-raised-button (click) = "goToTemplateDetail(this.site, item)" color="primary">View/Edit</button>
                    <div style="height:5px;background-color:white">
                    </div>
                </div>
            </div>
        </td>
    </ng-container>
   
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  
    <tr mat-row *matRowDef = "let item; columns: displayedColumns;"
        class="item-row"
        [class.expanded-row]="expandedItem === item"
        (click)="expandedItem = expandedItem === item ? null : item">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr> 
</table>

<div *ngIf="(isTablet$ | async)?.matches">
    <mat-card *ngFor="let item of mDataSource" class="templateCard mat-elevation-z10" (click) = "goToTemplateDetail(this.site, item)">
        <mat-card-title>{{item.Name}}</mat-card-title>
        <mat-card-content>
            <h4>Areas: {{item.Areas.join(', ')}}</h4>
            <h4>Default Days: {{item.Days}} <mat-icon *ngIf="item.DifferentDefaultDays.DiffFlag" style="color: red; font-size: 20px; margin-left: 5px">error</mat-icon> </h4>
        </mat-card-content>
    </mat-card>
</div>
<div class="gea-spacer-bottom"></div>
