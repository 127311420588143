import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { GeaMesDatepickerModule } from '@gea-mes/datepicker';

import { FullCalendarModule } from '@fullcalendar/angular';

import { TemplateRoutingModule } from './template-routing.module';
import { TemplateHomeComponent } from './components/template-home/template-home.component';
import { TemplateDetailComponent } from './components/template-detail/template-detail.component';
import { TemplateNameChangeDialogComponent } from './components/template-detail/template-name-change-dialog/template-name-change-dialog.component';
import { TemplateCreateDialogComponent } from './components/template-home/template-create-dialog/template-create-dialog.component';
import { DeleteConfirmComponent } from './components/template-detail/delete-confirm/delete-confirm.component';
import { AreaEditDialogComponent } from './components/template-detail/area-edit-dialog/area-edit-dialog.component';

import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatDividerModule } from "@angular/material/divider";
import {MatBadgeModule} from '@angular/material/badge';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTreeModule } from "@angular/material/tree";
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [
    TemplateHomeComponent,
    TemplateDetailComponent,
    TemplateNameChangeDialogComponent,
    TemplateCreateDialogComponent,
    AreaEditDialogComponent,
    DeleteConfirmComponent
  ],
  imports: [
    CommonModule,
    TemplateRoutingModule,
    MatBottomSheetModule,
    FullCalendarModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatExpansionModule,
    MatDividerModule,
    MatListModule,
    FormsModule,
    NgxMaterialTimepickerModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatCardModule,
    MatIconModule,
    GeaMesDatepickerModule,
    MatDividerModule,
    MatTreeModule,
    MatBadgeModule,
    MatChipsModule
  ]
})
export class TemplateModule { }
