import { Injectable } from '@angular/core'
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Observable, throwError, timer} from 'rxjs';
import {retryWhen, mergeMap, concatMap} from 'rxjs/operators';

export const genericRetryStrategy = ({
  maxRetryAttempts = 5,
  scalingDuration = 200
}: {
  maxRetryAttempts?: number,
  scalingDuration?: number,
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      if (retryAttempt > maxRetryAttempts ) {
        return throwError(error);
      }
      return timer(retryAttempt * scalingDuration);
    })
  );
};

@Injectable()
export abstract class AbstractRestService {
  env:any = environment;
  protected baseUrl: string;
  refreshTime: number =30000; //refresh time 30000 = 30 sec

  constructor(private http:HttpClient) {
    this.baseUrl = this.env.apigwUrl;
    this.postInit();
  }

  abstract postInit(): void;

  private wrapErrorHandler(req: Observable<any>):Observable<any> {
    return req.pipe(
      retryWhen(genericRetryStrategy()),
    );
  }

  get<T>(relativeUrl: string, params: HttpParams, repeat: boolean=true): Observable<T>
  {
    let p:HttpParams = params.append('t', `${Date.now()}`);
    //return this.wrapErrorHandler(this.http.get<T>(this.baseUrl+relativeUrl, {params: p}));
    let req = this.http.get<T>(this.baseUrl+relativeUrl, {params: p});
    if (repeat)
    {
      return this.wrapErrorHandler(timer(0,this.refreshTime).pipe(concatMap(_=>req)));
    } else
    {
      return this.wrapErrorHandler(req);
    }
//    return timer(0,this.refreshTime).pipe(concatMap(_=>req));
  }

  post<T>(relativeUrl: string, body:any, repeat: boolean=false, params?: HttpParams): Observable<T>
  {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
    let options:any = {};
    options.headers = headers;

    params ? options.params = params:null;
    let req = this.http.post<T>(this.baseUrl+relativeUrl, body, options);
    if (repeat)
    {
      return this.wrapErrorHandler(timer(0,this.refreshTime).pipe(concatMap(_=>req)));
    } else
    {
      return this.wrapErrorHandler(req);
    }
  }

  delete<T>(relativeUrl: string, params: HttpParams): Observable<T>
  {
    return this.wrapErrorHandler(
      this.http.delete<T>(this.baseUrl+relativeUrl, {params: params})
    );
  }

  put<T>(relativeUrl: string, body: any, repeat: boolean=false, params?: HttpParams): Observable<T> 
  {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
    let options: any = {}
    options.headers = headers;

    params ? options.params = params:null
    let req = this.http.put<T>(this.baseUrl+relativeUrl, body, options);
    if (repeat) {
      return this.wrapErrorHandler(timer(0, this.refreshTime).pipe(concatMap(_=>req)))
    } else {
      return this.wrapErrorHandler(req)
    }
  } 
}
