<form #modelForm="ngForm" [formGroup]="pauseFormGroup">
  <mat-dialog-content>
      <div>
        <mat-form-field>
          <textarea  matInput cdkTextareaAutosize placeholder="Reason" formControlName="reason" maxlength="80"></textarea>

           <mat-error *ngIf="hasError('reason', 'required')">Reason is required</mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>
  <mat-dialog-actions>
      <button mat-button [disabled]="!pauseFormGroup.valid" [mat-dialog-close]="modelForm.value">OK</button>
  </mat-dialog-actions>
</form>