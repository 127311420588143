import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { 
  AreaSelection, 
  CalendarEventMetaData, 
  Options,
  eventOptions,
  eventOptionsNoShift,
  shiftOptions, 
  ShiftLookup, 
  AreaInformation, 
  ShiftInformation, 
  CalendarEventType, TemplateEvent, CalendarEvent
} from '../../models/event-dialog.model';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { CalendarService } from 'src/app/services/calendar.service';
import { TemplatesService } from 'src/app/template/services/templates.service';
import {ConfirmationBoxComponent} from "src/app/common/components/confirmationBox/confirmationBox.component";
import { EventValidationService } from '../../services/event-validation.service';
import { ShiftLookupService } from '../../services/shift-lookup.service';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

@Component({
  selector: 'app-event-dialog',
  templateUrl: './event-dialog.component.html',
  styleUrls: ['./event-dialog.component.scss']
})

export class EventDialogComponent implements OnInit {
  currentEvent: CalendarEventType;
  currentShift: CalendarEventType;
  newEvent: CalendarEventType;
  Areas: AreaSelection;
  EventType: string;
  isAdmin: boolean;
  saveDebounce: boolean;
  haveAllLocks: boolean;
  isShift: boolean;
  isAreaEvent:boolean;
  eventOptions: Options[];
  eventOptionsNoShift: Options[];
  breakCategoryOptions: string[] = [];
  shiftOptions: string[];
  convertedEventType: string;
  templateEvent: boolean;
  inShiftCreate: boolean;
  eventDuration: string;
  timeErrorMsg: string;
  site: string;
  shiftLookupReference: ShiftLookup;
  shiftDateOffsetValue: string;
  datepickerStart: string;
  datepickerEnd: string;
  selectedDate: string;

  startTimeError:string='';
  endTimeError:string='';
  validationError:boolean=false;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private EventMetaData: CalendarEventMetaData,
    private geaMesCognitoAuthService: GeaMesCognitoAuthService,
    private templateService: TemplatesService,
    private calendarService: CalendarService, 
    private eventValidationService: EventValidationService,
    private shiftLookupService: ShiftLookupService
  ) 
  {
    this.saveDebounce = true;
    this.haveAllLocks = false;
    this.isAdmin = false;
    this.isShift = false;
    this.inShiftCreate = false;
  }

  ngOnInit(): void {
    this.geaMesCognitoAuthService.isUserInRole("Admin").subscribe( adminCheckResult =>{
      this.isAdmin = adminCheckResult
    })
    this.Areas = {
      'AvailableAreas': [],
      'SelectedAreas': []
    }
    this.shiftLookupService.shiftLookupReference.subscribe((shiftLookupData: ShiftLookup) =>{
      this.shiftLookupReference = shiftLookupData;
    })
    console.log(this.EventMetaData)
    this.site = this.EventMetaData.Site;
    this.currentEvent = JSON.parse(JSON.stringify(this.EventMetaData.Event));
    if (this.EventMetaData.Shift) {
      this.currentShift = JSON.parse(JSON.stringify(this.EventMetaData.Shift));
    }
    this.newEvent = this.EventMetaData.Event;
    let myTarget:number = +this.newEvent.Target * 100;
    this.newEvent.Target = myTarget.toString();
    this.haveAllLocks = this.EventMetaData.HaveAllLocks;
    this.Areas.AvailableAreas = this.EventMetaData.Areas.AvailableAreas;
    this.Areas.SelectedAreas = this.EventMetaData.Areas.SelectedAreas;
    this.templateEvent = this.EventMetaData.TemplateEvent;
    this.convertedEventType = this.convertEventType(this.newEvent.Event);
    this.isShift = this.setIsShift(this.convertedEventType);
    this.eventOptions = eventOptions;
    this.eventOptionsNoShift = eventOptionsNoShift;
    this.shiftOptions = shiftOptions;
    this.dateFormatCheck();
    this.eventDuration = this.adjustDuration(this.newEvent.StartTime, this.newEvent.EndTime);
    this.accountForStartDate(this.newEvent.ShiftDayOffset);
    this.resetDatePickerStartAndEnd()
    this.calendarService.getBreakCategories().subscribe( data => { this.breakCategoryOptions = data; });
    this.selectedDate = this.EventMetaData.SelectedDate;

    if (this.currentEvent.Area == null) {
      this.isAreaEvent = false;
    } else {
      this.isAreaEvent = true;
    }

  }

  timeChange(newTime: string, type: string): void {
    newTime = this.convertTo24Hour(newTime);
    let datePart:string;

    let startdate:string = dayjs(this.newEvent.StartTime).format('HH:mm');
    let enddate:string = dayjs(this.newEvent.EndTime).format('HH:mm');

    if (this.isAreaEvent) {
      datePart = dayjs(this.currentEvent.StartTime).format('YYYY-MM-DD')
    } else {
      datePart = this.selectedDate;  
    } 

    if (type == 'StartTime') {
      startdate = newTime;
      this.newEvent.StartTime = dayjs(datePart + 'T' + startdate).format('YYYY-MM-DD HH:mm:ss.sssssss');
    } else {
      enddate = newTime;
      this.newEvent.EndTime = dayjs(datePart + 'T' + enddate).format('YYYY-MM-DD HH:mm:ss.sssssss');
    }

    let dayjsStart:dayjs.Dayjs = dayjs(this.newEvent.StartTime);
    let dayjsEnd:dayjs.Dayjs = dayjs(this.newEvent.EndTime);

    // If adding to specific shift, set start date to be within shift
    if (this.isAreaEvent && dayjsStart.isBefore(this.currentEvent.StartTime) && dayjsStart.add(1, 'day').isBefore(this.currentEvent.EndTime)) {
      this.newEvent.StartTime = dayjsStart.add(1, 'day').format('YYYY-MM-DD HH:mm:ss.sssssss');;
      dayjsStart = dayjs(this.newEvent.StartTime);
    }

    // Move date of EndTime to one day in future if needed
    if (dayjsEnd.isBefore(dayjsStart)) {
      console.log("Set end time one day in future");
      this.newEvent.EndTime = dayjsEnd.add(1, 'day').format('YYYY-MM-DD HH:mm:ss.sssssss');

      dayjsEnd = dayjs(this.newEvent.EndTime);
    }

    // Validation Checks
    this.startTimeError = "";
    this.endTimeError = "";

    if (this.isAreaEvent) {
      this.currentShift = this.normalizeShiftDate(this.currentShift, this.currentEvent)
      let dayjsShiftStart:dayjs.Dayjs = dayjs(this.currentShift.StartTime);
      let dayjsShiftEnd:dayjs.Dayjs = dayjs(this.currentShift.EndTime);

      if (dayjsShiftStart.isAfter(dayjsShiftEnd)) {
        // Handle templates, set end date one day forward if crossing midnight for validation
        dayjsShiftEnd = dayjsShiftEnd.add(1, 'day');
      }

      if (dayjsStart.isBefore(dayjsShiftStart)) {
        this.startTimeError = "Must be after current shift start " + dayjsShiftStart.format("hh:mm A");
      }

      if (dayjsEnd.isBefore(dayjsShiftStart)) {
        this.endTimeError = "Must be after current shift start " + dayjsShiftStart.format("hh:mm A");
      }

      if (dayjsStart.isAfter(dayjsShiftEnd)) {
        this.startTimeError = "Must be before current shift end " + dayjsShiftEnd.format("hh:mm A");
      }

      if (dayjsEnd.isAfter(dayjsShiftEnd)) {
        this.endTimeError = "Must be before current shift end " + dayjsShiftEnd.format("hh:mm A");
      }
    }

    if (dayjsStart.isAfter(dayjsEnd)) {
      this.startTimeError = "Must be before end time";
      this.endTimeError = "Must be after start time";
    }

    if (this.startTimeError != '' || this.endTimeError != '') {
      this.validationError = true;
    } else {
      this.validationError = false;
    }

    if (this.templateEvent) {
      // Template dates should be the same day without shifting after validation
      this.newEvent.StartTime = dayjs(datePart + 'T' + startdate).format('YYYY-MM-DD HH:mm:ss.sssssss');
      this.newEvent.EndTime = dayjs(datePart + 'T' + enddate).format('YYYY-MM-DD HH:mm:ss.sssssss');
    }

    console.debug("Updated StartTime: " + this.newEvent.StartTime);
    console.debug("Updated EndTime: " + this.newEvent.EndTime);

    this.eventDuration = this.adjustDuration(this.newEvent.StartTime, this.newEvent.EndTime);
  }

  convertTo24Hour(timeString: string): string{
    console.debug("Initial Time: " + timeString);

    var PM: boolean = false;
    let convertedTime: string;
    var timePieces: string[];

    if(timeString.toUpperCase().includes("PM")){
      PM = true;
    }

    timeString = timeString.slice(0, -2).trim();
    timePieces = timeString.split(":");

    if(PM){
      timePieces[0] = (Number(timePieces[0]) + 12).toString();
    } else if (timePieces[0] == "12") {
      timePieces[0] = "00"
    }

    convertedTime = timePieces[0] + ":" + timePieces[1]
    console.debug("Converted Time: " + convertedTime);
    return timePieces[0] + ":" + timePieces[1];
  }

  resetDatePickerStartAndEnd(){
    this.datepickerStart = '12:00 am'
    this.datepickerEnd = '11:59 pm'
  }

  //If Duration is < 0 then add 24 hrs or 1440 mins to get the difference when start and end are in a different day
  adjustDuration(start: string, end: string): string{
    let newDuration: string = ''
    
    let hoursInt: number = dayjs(end).diff(dayjs(start), 'hours') < 0 ? dayjs(end).diff(dayjs(start), 'hours') + 24 : dayjs(end).diff(dayjs(start), 'hours')
    let minsInt: number = dayjs(end).diff(dayjs(start), 'minutes') < 0 ? dayjs(end).diff(dayjs(start), 'minutes') + 1440 : dayjs(end).diff(dayjs(start), 'minutes')

    if (minsInt >= 60){
      minsInt = minsInt - (Math.floor(minsInt / 60) * 60)
    }

    if (hoursInt == 0){
      newDuration = minsInt.toString() + 'm'
    } else {
      newDuration = hoursInt.toString() + 'h' + ' ' + minsInt.toString() + 'm'
    }

    return newDuration
  }

  validateDuration(): boolean {
    //ensure there's a space between the hours and mins
    if (this.eventDuration.includes('h') && this.eventDuration.includes('m')){
      if (this.eventDuration.charAt(this.eventDuration.indexOf('h')+1) != " "){
        this.eventDuration = this.eventDuration.slice(0,this.eventDuration.indexOf('h')+1) + " " + this.eventDuration.slice(this.eventDuration.indexOf('h')+1)
      }
    }

    let regex = RegExp('(^[0-9]*[h]$)|(^[0-9]*[m]$)|(^[0-9]*[H]$)|(^[0-9]*[M]$)');
    this.eventDuration = this.eventDuration.trim();
    let durationArray = this.eventDuration.split(' '); // array splits by space
    this.timeErrorMsg = '';

    //if there is no entry, push 0m to duration array
    if (this.eventDuration == "" || this.eventDuration == null) {
    }

    durationArray.forEach((el)=>{
      if(!regex.test(el)) {
        this.timeErrorMsg = 'Incorrect Format'
      }
    });

    if (this.timeErrorMsg != ''){
      return false
    } else {
      this.setNewEndTime(durationArray)
      return true
    }
  }

  setNewEndTime(durationArray: string[]): void{
    let timeToAdd: number = 0
    durationArray.forEach(timeElement => {
      if (timeElement.includes("h")){
        let hourTimeInt: number = parseInt(timeElement.substring(0, timeElement.length - 1))
        timeToAdd += hourTimeInt * 60
      } else {
        let minTimeInt: number = parseInt(timeElement.substring(0, timeElement.length - 1))
        timeToAdd += minTimeInt
      }
    });
    this.newEvent.EndTime = dayjs(this.newEvent.StartTime).add(timeToAdd, 'minutes').format('YYYY-MM-DD HH:mm:ss.sssssss');

    if (this.templateEvent){
      this.newEvent.EndTime = dayjs(dayjs().format('YYYY-MM-DD') + ' ' + dayjs(this.newEvent.EndTime).format('HH:mm:ss.sssssss')).format('YYYY-MM-DD HH:mm:ss.sssssss')
    }
  }

  //Checks date format and converts to YYYY-MM-DD HH:mm:ss.sssssss for validation and submission
  dateFormatCheck(): void{
    if (dayjs(this.newEvent.StartTime, 'hh:mm:ss', true).isValid() || dayjs(this.newEvent.EndTime, 'hh:mm:ss', true).isValid()){
      console.log("If Statement 1");
      this.newEvent.StartTime = dayjs(dayjs().format('YYYY-MM-DD') + ' ' + this.newEvent.StartTime).format('YYYY-MM-DD HH:mm:ss.sssssss');
      this.newEvent.EndTime = dayjs(dayjs().format('YYYY-MM-DD') + ' ' + this.newEvent.EndTime).format('YYYY-MM-DD HH:mm:ss.sssssss');
    } 
    
    if (dayjs(this.newEvent.StartTime, 'MM/DD/YYYY hh:mm A', true).isValid()){
      console.log("If Statement 2");
      this.newEvent.StartTime = dayjs(this.newEvent.StartTime).format('YYYY-MM-DD HH:mm:ss.sssssss')
    } 
    
    if (dayjs(this.newEvent.EndTime, 'MM/DD/YYYY hh:mm A', true).isValid()) {
      console.log("If Statement 3");
      this.newEvent.StartTime = dayjs(this.newEvent.EndTime).format('YYYY-MM-DD HH:mm:ss.sssssss')
    }

    if (dayjs(this.newEvent.StartTime, 'HH:mm:ss', true).isValid()){
      console.log("If Statement 4");
      this.newEvent.StartTime = dayjs(dayjs().format('YYYY-MM-DD') + ' ' + this.newEvent.StartTime).format('YYYY-MM-DD HH:mm:ss.sssssss');
    }

    if (dayjs(this.newEvent.EndTime, 'HH:mm:ss', true).isValid()){
      console.log("If Statement 5");
      this.newEvent.EndTime = dayjs(dayjs().format('YYYY-MM-DD') + ' ' + this.newEvent.EndTime).format('YYYY-MM-DD HH:mm:ss.sssssss');
    }
    
  }

  convertEventType(eventType: string): string{
    let convertedEventType: string = null;

    if (eventType == null){
      return convertedEventType;
    }
    if((this.newEvent.BreakCatagory.search('Pause') == 0 ) || eventType.substr(0,5) == "Pause"){
      convertedEventType = "Pause";
    }
    else if(eventType.substr(0,4) == "PAID" ){
      convertedEventType = "PAID EVENT";
    }else if(eventType.substr(0,6) == "UNPAID"){
      convertedEventType = "UNPAID EVENT";
    }else if(eventType.substr(0,5) == "SHIFT"){
      convertedEventType = "SHIFT";
    }else if(eventType.substr(0, 8) == "NON WORK"){
      convertedEventType = "NONWORK";
    }else{
      convertedEventType = null;
    }
    return convertedEventType
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  onDelete(): void {
    this.newEvent.Action = 'Delete'
    this.newEvent.AuroraEnv = this.Areas.SelectedAreas[0].Environment
    this.modifyDeleteConfirmation(this.newEvent, [true])
  }


  onCreate(): void {
    let newEventStart = ''
    let newEventEnd = ''


    this.inShiftCreate = true;
    this.eventOptions = this.eventOptions.filter(option => option.value != 'SHIFT');

    if (this.templateEvent){
      this.newEvent = new TemplateEvent(this.currentEvent.Template, this.currentEvent.Default)
      newEventStart = dayjs(this.currentEvent.StartTime).format('YYYY-MM-DD HH:mm:ss.sssssss');
      newEventEnd = dayjs(this.currentEvent.EndTime).format('YYYY-MM-DD HH:mm:ss.sssssss');
    } else {
      this.newEvent = new CalendarEvent(this.currentEvent.ShiftDate)
      newEventStart = this.currentEvent.StartTime
      newEventEnd = this.currentEvent.EndTime
    }
    
    this.newEvent.StartTime = newEventStart
    this.newEvent.EndTime = newEventEnd
  }

  shiftTimeFormatCheck(start: string, end: string): {'start': string, 'end': string} {
    let newTimes = {
      'start': start,
      'end': end
    }

    if (dayjs(start, 'HH:mm:ss', true).isValid()){
      newTimes.start = dayjs(dayjs().format('YYYY-MM-DD') + ' ' + start).format('YYYY-MM-DD HH:mm:ss.sssssss');
    }

    if (dayjs(end, 'HH:mm:ss', true).isValid()){
      newTimes.end = dayjs(dayjs().format('YYYY-MM-DD') + ' ' + end).format('YYYY-MM-DD HH:mm:ss.sssssss');
    }

    return newTimes
  }

  accountForStartDate(shiftDateOffset){
    if(shiftDateOffset == "Before" || shiftDateOffset == -1){
      this.newEvent.ShiftDayOffset = -1
      this.shiftDateOffsetValue = 'Before'
    } else if (shiftDateOffset == "During" || shiftDateOffset == 0){
      this.newEvent.ShiftDayOffset = 0
      this.shiftDateOffsetValue = 'During'
    } else if (shiftDateOffset == "After" || shiftDateOffset == 1) {
      this.newEvent.ShiftDayOffset = 1
      this.shiftDateOffsetValue = 'After'
    }
  }


  setIsShift(eventType: string): boolean{
    let isShiftBool: boolean = false

    if (eventType == 'SHIFT'){
      isShiftBool = true  
    } 
    return isShiftBool
  }

  setDemand(event: CalendarEventType): string{
    let newTarget: string = ''

    if (event.Action == 'Create'){
      if (this.isShift) {
        newTarget = '95'
      } else {
        newTarget = '0'
      }

    } else if (event.Action == 'Modify' && !this.isShift){
      newTarget = '0'
    } else {
      newTarget = this.newEvent.Target
    }

    return newTarget
  }

  eventChange(): void{
    this.newEvent.Event = this.convertEventType(this.convertedEventType)
    this.isShift = this.setIsShift(this.newEvent.Event)
    this.newEvent.Target = this.setDemand(this.newEvent)
    this.breakCategoryOptionsAdjustment()
  }

  breakCategoryOptionsAdjustment(): void{
    if (this.convertedEventType == 'Pause') {
      if (!this.breakCategoryOptions.includes('Pause')) this.breakCategoryOptions.push('Pause')
      this.newEvent.BreakCatagory = 'Pause'
    } else {
      if (this.breakCategoryOptions.includes('Pause')) {
        let i = this.breakCategoryOptions.indexOf('Pause')
        this.breakCategoryOptions.splice(i, 1)
      }
    }
  }

  onSubmit(){    
    let newEventArr = this.processEventsForSubmit()    
    let envsArr: any[] = [];
    newEventArr.forEach(function(el){
      envsArr.push(el.AuroraEnv)
    })
    this.templateService.setEnvironmentList(envsArr);
    
    let validationResults: boolean [] = this.eventValidationService.validate(newEventArr, this.isShift, this.templateEvent, this.newEvent.Action)

    if ((this.newEvent.Action == 'Modify' || this.newEvent.Action == 'Delete') && !validationResults.includes(false)) {
      this.modifyDeleteConfirmation(newEventArr, validationResults)
    } else if (!validationResults.includes(false)) {
      this.calendarService.isLoading.next(true)
      this.calendarService.buildEventAPI(newEventArr, this.templateEvent).subscribe(
        res => {
          this.calendarService.isLoading.next(false)
          this.dialogRef.close()
        },
        err => {
          this.calendarService.isLoading.next(false)
          console.log(this.newEvent)
        },
        () => this.calendarService.isLoading.next(false)
      )
    }
  }

  processEventsForSubmit(){
    let newEventsArr: any[] = [];
    this.dateFormatCheck();
    this.Areas.SelectedAreas.forEach((area: AreaInformation)=> {
      this.newEvent.Area = area.Area; 
      this.newEvent.ProjectID = area.ProjectID;
      this.newEvent.AuroraEnv = area.Environment;
      this.newEvent.ObjectDescription = area.ObjectDescription;
      if (!this.isShift){
        if (this.templateEvent) {
          let currentEventShiftInfo = this.setTemplateEventShift(area)      
          this.newEvent.Shift = currentEventShiftInfo ? currentEventShiftInfo.Shift : "Event Outside Shift"
          this.newEvent.ShiftDayOffset = 0
        } else {
          let currentEventShiftInfo = this.setEventShift(area)
          this.newEvent.Shift = currentEventShiftInfo ? currentEventShiftInfo.Shift : "Event Outside Shift"
          this.newEvent.EndTime = this.shiftDateCheck(this.newEvent)
        }
        newEventsArr.push(JSON.parse(JSON.stringify(this.newEvent)))
      } else {
        newEventsArr.push(JSON.parse(JSON.stringify(this.newEvent)));
      }
    })
    return newEventsArr
  }

  // Applies the shift to EVENTS ONLY based on the event start and end time
  setEventShift(calendarEvent: AreaInformation): ShiftInformation{
    console.log('The value of calendarEvent ',calendarEvent.Area,this.site,calendarEvent )
    let areaShifts = this.shiftLookupReference[calendarEvent.Area + '_' + this.site + '_' + calendarEvent.Environment]
    for (let shift in areaShifts) {
      let eventStart = dayjs(this.newEvent.StartTime)
      let eventEnd = dayjs(this.newEvent.EndTime)  
      let shiftStart = dayjs(areaShifts[shift].StartTime)
      let shiftEnd = dayjs(areaShifts[shift].EndTime)
      if (
          (eventStart.isSameOrAfter(shiftStart)) && (eventEnd.isSameOrBefore(shiftEnd))
        ) {
        return areaShifts[shift]
      }
    }
    return null
  }

  setTemplateEventShift(calendarEvent: AreaInformation): ShiftInformation {
    let shift = ''
    let shiftTitleCheck = ['1ST SHIFT', '2ND SHIFT', '3RD SHIFT']
    let areaShifts = this.shiftLookupReference[calendarEvent.Area + '_' + this.site + '_' + calendarEvent.Environment]
    if (dayjs(this.newEvent.StartTime).isAfter(dayjs(this.newEvent.EndTime))) {
      let shiftInfo = this.determineShiftEventPassesMidnight(areaShifts)
      if (shiftInfo != null) {
        return shiftInfo
      }
    } else {
      for (let shiftIdx in areaShifts) {
        let shiftEvent = areaShifts[shiftIdx]
        if (shiftTitleCheck.includes(shiftEvent.Shift)) {        
          shift = this.determineTemplateShift(shiftEvent)
          let shiftIdx = shiftTitleCheck.indexOf(shiftEvent.Shift)
          shiftTitleCheck.splice(shiftIdx, 1)
          if (shift != 'None'){
            return areaShifts[shiftIdx]
          }
        }
      }
    }
    return null
  }

  determineTemplateShift (shiftEvent: any): string{
    let shiftEvents: any[] = []
    let shift = 'None'
    let today = dayjs().format('YYYY-MM-DD')
    let eventStart = dayjs(this.newEvent.StartTime).isValid() ? dayjs(this.newEvent.StartTime) : dayjs(today + ' ' + dayjs(this.newEvent.StartTime).format('HH:mm:ss'))
    let eventEnd = dayjs(this.newEvent.EndTime).isValid() ? dayjs(this.newEvent.EndTime) : dayjs(today + ' ' + dayjs(this.newEvent.EndTime).format('HH:mm:ss'))
    shiftEvents = this.eventValidationService.normalizeTemplateEvents(shiftEvent)
    
    for (let shiftIdx in shiftEvents) {
      let shiftStart = dayjs(shiftEvents[shiftIdx].StartTime)
      let shiftEnd = dayjs(shiftEvents[shiftIdx].EndTime)
      if (
        (eventStart.isSameOrAfter(shiftStart)) && (eventEnd.isSameOrBefore(shiftEnd))
      ) {
        shift = shiftEvents[shiftIdx].Shift
        return shift
      }
    }

    return shift
  }

  determineShiftEventPassesMidnight(shiftEvent:any): ShiftInformation {
    let splitShiftEvents: any[] = []
    let shiftTitleCheck = ['1ST SHIFT', '2ND SHIFT', '3RD SHIFT']
    let today = dayjs().format('YYYY-MM-DD')
    let eventStart = dayjs(this.newEvent.StartTime).isValid() ? dayjs(this.newEvent.StartTime) : dayjs(today + ' ' + dayjs(this.newEvent.StartTime).format('HH:mm:ss'))
    let eventEnd = dayjs(this.newEvent.EndTime).isValid() ? dayjs(this.newEvent.EndTime) : dayjs(today + ' ' + dayjs(this.newEvent.EndTime).format('HH:mm:ss'))
   
    let shiftTrackingObj = {
      '1ST SHIFT': {
        'count': 0,
        'index': 0
      },
      '2ND SHIFT': {
        'count': 0,
        'index': 0
      },
      '3RD SHIFT': {
        'count': 0,
        'index': 0
      }
    }


    for (let shiftIdx in shiftEvent) {
      if (shiftTitleCheck.includes(shiftEvent[shiftIdx].Shift)) {
        splitShiftEvents = this.eventValidationService.normalizeTemplateEvents(shiftEvent[shiftIdx])
        
        for (let shiftEventIdx in splitShiftEvents) { 
          let shiftStart = dayjs(splitShiftEvents[shiftEventIdx].StartTime)
          let shiftEnd = dayjs(splitShiftEvents[shiftEventIdx].EndTime)
          
          if (
            (eventStart.isSameOrAfter(shiftStart)) && (eventStart.isSameOrBefore(shiftEnd))
            || (eventEnd.isSameOrAfter(shiftStart) && eventEnd.isSameOrBefore(shiftEnd))
          ) {
            shiftTrackingObj[splitShiftEvents[shiftEventIdx].Shift].count += 1
            shiftTrackingObj[splitShiftEvents[shiftEventIdx].Shift].index = shiftIdx
          }
        }

        let shiftRefIdx = shiftTitleCheck.indexOf(shiftEvent[shiftIdx].Shift)
        shiftTitleCheck.splice(shiftRefIdx, 1)
      }  
    }

    for (let shiftTitle in shiftTrackingObj) {
      if (shiftTrackingObj[shiftTitle].count == 2){
        return shiftEvent[shiftTrackingObj[shiftTitle].index]
      }
    }

    return null
  }

  shiftDateCheck(calendarEvent: CalendarEventType): string {
    let start = dayjs(calendarEvent.StartTime)
    let end = dayjs(calendarEvent.EndTime)

    if (end.isBefore(start)) {
      return end.add(1, 'day').format('YYYY-MM-DD HH:mm:ss.sssssss')
    } else {
      return end.format('YYYY-MM-DD HH:mm:ss.sssssss')
    }
  }

  // Adjust the shift offset based on the start time and the current shift offset
  shiftOffsetAdjustment(shiftStart: string, shiftOffset: number): number{   
    let adjustedShiftOffset = 0
    let shiftStartDayJS = dayjs(shiftStart)

    if (this.newEvent.ShiftDayOffset == -1) {
      if (shiftStartDayJS.isAfter(dayjs(this.newEvent.StartTime)) && shiftStartDayJS.isBefore(dayjs().endOf('day'))){
        adjustedShiftOffset = -1
      } else {
        adjustedShiftOffset = 0
      }
    } else if (this.newEvent.ShiftDayOffset == 0) {
      if (shiftStartDayJS.isAfter(dayjs().startOf('day')) && shiftStartDayJS.isBefore(dayjs(this.newEvent.StartTime))){
        adjustedShiftOffset = 1
      } else {
        adjustedShiftOffset = 0
      }
    } else if (this.newEvent.ShiftDayOffset == 1) {
      if (shiftStartDayJS.isAfter(dayjs().startOf('day')) && shiftStartDayJS.isBefore(dayjs(this.newEvent.StartTime))){
        adjustedShiftOffset = 2
      } else {
        adjustedShiftOffset = 1
      }
    } else {
      throw new Error('Shift Offset is invalid')
    }
    return adjustedShiftOffset
  }


  modifyDeleteConfirmation(newEventArr, validationResults){
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      width: '250px',
      data: {
        'oldEvent': this.currentEvent,
        'newEvent': this.newEvent,
        'action': this.newEvent.Action,
        'isTemplate': this.templateEvent
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      let isValid: boolean = result.submitFlag && !validationResults.includes(false)
      this.calendarService.isLoading.next(true)
      if (this.newEvent.Action == 'Delete' && isValid){
        console.log( 'before call to the deletecalendarevent ')
        this.calendarService.deleteCalendarEvent([this.newEvent], this.templateEvent).subscribe(
          res => {
            this.calendarService.isLoading.next(false)
            this.dialogRef.close()
          },
          err => this.calendarService.isLoading.next(false),
          () => this.calendarService.isLoading.next(false)
        )
      } else if (isValid){
        newEventArr.forEach((el) => {
          el.Reason = result.newReason;
        });

        this.calendarService.buildEventAPI(newEventArr, this.templateEvent).subscribe(
          res => {
            this.calendarService.isLoading.next(false)
            this.dialogRef.close()
          },
          err => this.calendarService.isLoading.next(false),
          () => this.calendarService.isLoading.next(false)
        )
      }
    });
  }

  normalizeShiftDate(shift: CalendarEventType, event: CalendarEventType): CalendarEvent {
    let shiftStart:dayjs.Dayjs
    let shiftEnd:dayjs.Dayjs
    if (shift.StartTime.length == 8) {
      shift.StartTime = event.StartTime.slice(0, 11)+shift.StartTime
      shiftStart = dayjs(shift.StartTime);
    }
    if (shift.EndTime.length == 8) {
      shift.EndTime = event.EndTime.slice(0, 11)+shift.EndTime
      shiftEnd = dayjs(shift.EndTime);
      if (shiftEnd.isBefore(shiftStart)) {
        shift.EndTime = shiftEnd.add(1, 'day').format();
      }
    }
    return shift
  }
}
