<div style="height:10px;background-color:white">

</div>
<button mat-raised-button (click)="onBack()" style="margin-left: 5px;">Go Back</button>

<div class="fx-column" style="border-color:gray; border-width: 1px;border-radius: 5px; border-style: solid; margin: 5px;">  
  <div class="fx-row-space-between-center">
    <div class="fx-row-start-center fx-wrap"><h2 style="padding-left: 10px;">{{templateName}}</h2></div>   
    <div class="fx-row-end-center fx-wrap">
      <button mat-raised-button [disabled]="!isAdmin||!haveAllLocks" color="primary" (click)="renameTemplate()">Rename Template</button>
      <button mat-raised-button [disabled]="!isAdmin||!haveAllLocks" color="warn" (click)="confirmDelete()">Delete Template</button>
      <form class="Template-Name" [formGroup]="templateOptionsForm" > 
        <mat-form-field >
          <mat-label>Default Days: </mat-label>
          <mat-select formControlName= "daysForm" [disabled]="!isAdmin||!haveAllLocks" multiple [(ngModel)]="selectedDays" (openedChange)="updateDays($event)">
            <mat-option *ngFor="let days of daysOfWeek" [value] = "days">{{days}}</mat-option>
          </mat-select>
        </mat-form-field>        
      </form>
    </div>   
  </div>
  <div *ngIf="differentDefaultDaysFlag" class="fx-column-center-start">
    <h2 style="max-width: 350px; margin-top: 15px"><span style="color: red">WARNING!</span> This template has different Default Days. Changing the Default Days will change the Default Days for all areas in the template.</h2>
    <div class="dupDefaultDaysDetails" *ngFor="let defaultDays of differentDefaultDaysObj | keyvalue">
      <div>Default Days: {{defaultDays.key}}</div>
      <div>Areas: {{defaultDays.value.join(', ')}}</div>
      <mat-divider></mat-divider>
    </div>
  </div>
</div>

<div class='app-calendar'>
  <div class="fx-row-start-center fx-wrap">
    <span class="example-spacer">
        <h2 class="center" *ngIf="isAdmin && haveAllLocks">
          <button mat-button (click)="openBottomSheet()"><mat-icon style="padding-right: 3px;">lock</mat-icon></button>
          Edit Mode: No one else can edit this template.
        </h2>
        <h2 class="center" *ngIf="isAdmin && !canLock && !haveAllLocks">
          <button mat-button (click)="openBottomSheet()"><mat-icon style="padding-right: 3px;">lock</mat-icon></button>
          Locked: Someone else is editing this template.
        </h2>
    </span>
    <div *ngIf="isAdmin" class="calendarEditButtons fx-row-end-center">
      <button mat-raised-button *ngIf="isAdmin && !haveAllLocks && !otherUserHasLocks" [disabled]="!canLock" (click)="changeLockStatus('locked')">Enter Edit Mode</button>
      <button mat-raised-button *ngIf="haveAllLocks" (click)="changeLockStatus('unlocked')">Enter View Mode</button>
      <button mat-raised-button *ngIf="isAdmin && otherUserHasLocks" (click)="changeLockStatus('locked')">Break Locks</button>
      <button mat-raised-button *ngIf="isAdmin && haveAllLocks" (click)="createNewEvent()">Create Events</button>
    </div>
  </div>
</div>
    <div class="fx-column-space-between-start">
      <div style="margin-bottom:-25px; margin-left: -5px;">
        <button mat-raised-button [disabled]="!isAdmin||!haveAllLocks" color="primary" (click)="editAreas()">Add Areas to Template</button>  
      </div>
      <div> 
        <ng-template [ngIf]="(isScreen$ | async)?.matches" [ngIfElse]="mobileCalendar">
          <full-calendar #calendar [options] = "calendarOptions"></full-calendar>
        </ng-template>
        <ng-template #mobileCalendar>
          <full-calendar #calendar [options] = "mobileCalendarOptions"></full-calendar>
        </ng-template>
      </div>
    </div>
<div class="fx-row-end-end fx-wrap">   
  <button style="background-color: transparent;color:black; border-style: none; padding:0.4em; margin-right: 3px;" >Legend:</button>
  <button style="background-color:#e0f4dc; color:black; border-style: none; padding:0.4em; margin-right: 3px;" >Shift</button>
  <button style="background-color:#cf3d36; color:white; border-style: none; padding:0.4em; margin-right: 3px;" >Paid Event</button>
  <button style="background-color:#faf12e; color:black; border-style: none; padding:0.4em; margin-right: 3px;" >Unpaid Event</button>
  <button style="background-color:#ff8c2c; color:white; border-style: none; padding:0.4em; margin-right: 3px;" >Pause</button>
  <button style="background-color:#a365d2; color:white; border-style: none; padding:0.4em; margin-right: 3px;" >Non-work Day</button>
</div>