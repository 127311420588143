import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreakEarlyService extends AbstractRestService {
  postInit(){}

  getBreakearlyList(envs:string[], areas:string[]):Observable<GenericLambdaReturn<BreakEarlyArea[]>> {
    let parms: any = {};

    parms.environments = envs.join(",");
    parms.arealist = areas.join(",");
    
    console.log(parms);
    return this.get({
      ApiResource: "/breakearly",
      data: parms,
      repeatCall: true,
      repeatRate: 60000
    })
  }

  breakEarly(envs:string[], Area:string, ShiftDate:Date, Shift:string, Reason:string):Observable<GenericLambdaReturn<string>> {
    let parms: any = { "data": {}};

    parms.data.environments = envs.join(",");
    parms.data.Area = Area;
    parms.data.ShiftDate = ShiftDate;
    parms.data.Shift = Shift
    parms.data.Reason = Reason

    return this.put({
      ApiResource: "/breakearly",
      data: parms,
      repeatCall: false
    })
  }
}

export type BreakEarlyArea = {
  ProjectID: string,
  Area: string,
  ShiftDate: Date,
  Shift: string,
  ShiftStart: Date,
  ShiftEnd: Date,
  BreakStartTime: Date,
  BreakEndTime: Date,
  StatusMsg: string
}