<form (ngSubmit)="onSubmit()" #eventDetails="ngForm">
    <h1  *ngIf="newEvent.ObjectDescription">{{newEvent.ObjectDescription}}</h1>
    <h1 style="display:inline">{{newEvent.Shift}}</h1>
    <h1 style="display:inline" class="break-category" *ngIf="newEvent.BreakCatagory != '<NONE>' && newEvent.BreakCatagory != '<None>'">- {{newEvent.BreakCatagory}}</h1>
    <span class="spacer"></span>
    <button color="primary" *ngIf="isAdmin && haveAllLocks && newEvent.Action == 'Modify' && convertedEventType == 'SHIFT'" mat-raised-button style="float:right;line-height:32px" type="button" id="create" [disabled]="!haveAllLocks" (click)="onCreate()">Create Event</button>

    <div mat-dialog-content>
        <mat-accordion>
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Areas and Event Type
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list>
                    <mat-list-item>
                        <mat-form-field id="area">
                            <mat-label>Area</mat-label>
                            <mat-select [(ngModel)]="Areas.SelectedAreas" [disabled]="newEvent.Action == 'Modify' || inShiftCreate && (isAdmin || haveAllLocks)" name="selectAreas" multiple>
                                <mat-option *ngFor="let area of Areas.AvailableAreas" [value]="area">{{area.ObjectDescription}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-list-item>
                    <mat-list-item class="EventTypeDropDown">
                        <mat-form-field>
                            <mat-label>Event Type</mat-label>
                            <mat-select [(ngModel)]="convertedEventType" [disabled]="newEvent.Action == 'Modify' && (isAdmin || haveAllLocks)" (selectionChange)="eventChange()" name="EventType">
                              <mat-option *ngFor="let type of eventOptions" [value]="type.value">
                                {{type.viewValue}}
                             </mat-option>                 
                            </mat-select>
                          </mat-form-field>
                    </mat-list-item>
                </mat-list>
            </mat-expansion-panel>
        
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Details
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list>
                    <mat-list-item *ngIf="!inShiftCreate && isShift">
                        <div style="width:100%">
                            <div class="left">Shift</div>
                            <div class="timeright">
                                <mat-select [(ngModel)]="newEvent.Shift" name="Shift" class="formFields" [disabled]="!isAdmin || !haveAllLocks">
                                    <mat-option *ngFor="let cat of shiftOptions" [value]="cat" value="cat">{{cat}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <!-- Category Select -->
                    <mat-list-item *ngIf=!isShift>
                        <div style="width:100%"> 
                            <div class="left">Category</div>
                            <div class="timeright">
                                <mat-select [(ngModel)]="newEvent.BreakCatagory" name="breakCategory" class="formFields" [disabled]="!isAdmin || !haveAllLocks || convertedEventType == 'Pause' || newEvent.BreakCatagory =='Pause'">
                                    <mat-option *ngFor="let cat of breakCategoryOptions" [value]="cat" value="cat">{{cat}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>
                        <div style="width:100%">
                            <div class="left">Start</div>
                            <div class="timeright">
                                <!--See here for examples https://agranom.github.io/ngx-material-timepicker/-->
                                <input style="cursor:pointer" 
                                    [ngxTimepicker]="StartTime"
                                    [disabled]="!isAdmin || !haveAllLocks" 
                                    class="time formFields form-control"
                                    [value]="newEvent.StartTime | date:'h:mm a'"
                                    readonly>
                                <ngx-material-timepicker 
                                    #StartTime
                                    (timeSet)="timeChange($event, 'StartTime')">
                                </ngx-material-timepicker>
                                <div *ngIf="startTimeError != ''" class="validationError">{{startTimeError}}</div>
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <div *ngIf="!isShift">
                        <mat-list-item>
                            <div style="width:100%">
                                <div class="left">Duration</div>
                                <div class="timeright">
                                    <input matInput [(ngModel)]="eventDuration"  [disabled]="!isAdmin || !haveAllLocks" class="time formFields form-control" (ngModelChange)='validateDuration()'  placeholder="ex: 1h 30m"  name="duration" style="cursor:pointer">
                                </div>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </div>
                    <div *ngIf="templateEvent && isShift">
                        <mat-list-item>
                            <div style="width:100%" class="fx-row-center-center">
                                <div class="left">Start Time Is: </div>
                                <span class="spacer"></span>
                                <mat-button-toggle-group [disabled]="!isShift && (!isAdmin || !haveAllLocks)" 
                                    name="relativeStart" 
                                    aria-label="relative Start" 
                                    (change)=accountForStartDate($event.value) 
                                    [(ngModel)]="shiftDateOffsetValue">
                                        <mat-button-toggle value = "Before">Before</mat-button-toggle>
                                        <mat-button-toggle value = "During">During</mat-button-toggle>
                                        <mat-button-toggle value = "After">After</mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </div>
                    <mat-list-item>
                        <div style="width:100%">
                            <div class="left">End</div>
                            <div style="cursor:pointer" class="timeright">
                                <!--See here for examples https://agranom.github.io/ngx-material-timepicker/-->
                                <input style="cursor:pointer" 
                                    [ngxTimepicker]="EndTime"
                                    [disabled]="!isAdmin || !haveAllLocks" 
                                    class="time formFields form-control"
                                    [value]="newEvent.EndTime | date:'h:mm a'"
                                    readonly>
                                <ngx-material-timepicker 
                                #EndTime 
                                (timeSet)="timeChange($event, 'EndTime')">
                                </ngx-material-timepicker>
                                <div *ngIf="endTimeError != ''" class="validationError">{{endTimeError}}</div>
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item *ngIf="!inShiftCreate && isShift">
                        <div style="width:100%">
                            <div class="left">Demand %</div>
                            <div style="cursor:pointer" class="timeright">
                                <input type="text" [disabled]="(!isAdmin || !haveAllLocks)" class="time formFields form-control" id="Target"
                                    name="Target" [(ngModel)]="newEvent.Target">
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item *ngIf="newEvent.Action == 'Modify'">
                        <div style="width:100%">
                            <div class="left">Reason</div>
                            <div style="cursor:pointer" class="timeright">
                                <input type="text" [disabled]="true" class="time formFields form-control" id="Reason"
                                    name="Reason" [(ngModel)]="newEvent.Reason">
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Horn Details
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list>
                    <mat-list-item>
                        <div style="width:100%">
                            <div class="left">Event Start (seconds before)</div>
                            <input type="text" [disabled]="!isAdmin || !haveAllLocks" class="right form-control"
                                id="HornStartDelta" name="HornStartDelta" [(ngModel)]="newEvent.HornStartDelta">
                        </div>
                    </mat-list-item>

                    <mat-list-item>
                        <div style="width:100%">
                            <div class="left">Duration (seconds)</div>
                            <input type="text" [disabled]="!isAdmin || !haveAllLocks" class="right form-control"
                                id="HornStartDuration" name="HornStartDuration"
                                [(ngModel)]="newEvent.HornStartDuration">
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>

                    <mat-list-item>
                        <div style="width:100%">
                            <div class="left">Event End (seconds before)</div>
                            <input type="text" [disabled]="!isAdmin || !haveAllLocks" class="right form-control"
                                id="HornEndDelta" name="HornEndDelta" [(ngModel)]="newEvent.HornEndDelta">
                        </div>
                    </mat-list-item>

                    <mat-list-item>
                        <div style="width:100%">
                            <div class="left">Duration (seconds)</div>
                            <input type="text" [disabled]="!isAdmin || !haveAllLocks" class="right form-control"
                                id="HornEndDuration" name="HornEndDuration" [(ngModel)]="newEvent.HornEndDuration">
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>

                    <mat-list-item>
                        <div style="width:100%">
                            <div class="left">Warning (seconds before)</div>
                            <input type="text" [disabled]="!isAdmin || !haveAllLocks" class="right form-control"
                                id="HornAdvDelta" name="HornAdvDelta" [(ngModel)]="newEvent.HornAdvDelta">
                        </div>
                    </mat-list-item>

                    <mat-list-item>
                        <div style="width:100%">
                            <div class="left">Duration (seconds)</div>
                            <input type="text" [disabled]="!isAdmin || !haveAllLocks" class="right form-control"
                                id="HornAdvDuration" name="HornAdvDuration" [(ngModel)]="newEvent.HornAdvDuration">
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>

                </mat-list>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div mat-dialog-actions>
        <button *ngIf="isAdmin && saveDebounce && haveAllLocks && convertedEventType!='NONWORK'" mat-raised-button color="primary" type="submit" [disabled]="validationError" class="submit" >Save</button>
        <button *ngIf="isAdmin && newEvent.Action == 'Modify' && haveAllLocks && convertedEventType!='NONWORK'" mat-raised-button  [disabled]="convertedEventType=='NONWORK' || !haveAllLocks" color="warn" type="button" class="button" id="delete" (click)="onDelete()">Delete {{convertedEventType}}</button>
        <span class="spacer"></span>
        <button color="primary" type="button" mat-raised-button (click)="onNoClick()" class="warn">Close</button>
    </div>
</form>