import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EosMetricsDialogComponent } from './eos-metrics-dialog/eos-metrics-dialog.component';
import { RemoveShiftDialogComponent } from './remove-shift-dialog/remove-shift-dialog.component';
import { PriorShift, PriorShiftServiceService } from './services/prior-shift-service.service';

@Component({
  selector: 'app-prior-shift-edit',
  templateUrl: './prior-shift-edit.component.html',
  styleUrls: ['./prior-shift-edit.component.scss']
})
export class PriorShiftEditComponent implements OnInit {

  
  rawData:PriorShift[];

  dataSource3:PriorShiftArea[] = [];
  areas:string[];
  envs:string[];
  site:string;

  dataSource:PriorShiftArea[];
  subscription:Subscription;

  displayedColumns: string[] = ["ShiftDate", "Shift", "StartTime", "EndTime", "UnitsProduced", "Action"];

  selection = new SelectionModel<PriorShift>(false, []);

  constructor(
    private route: ActivatedRoute,private router: Router,
    public dialog: MatDialog,
    private svcShiftService: PriorShiftServiceService
  ) { }

  ngOnInit(): void {
    let lines:string[] = this.route.snapshot.paramMap.get('lines').split('&'); 

    this.site = this.route.snapshot.paramMap.get('site')
    let myAreas:string[] = [];
    let myEnvs:string[] = [];

    // Extract Area and Env from each line
    lines.forEach(function(line) {
      let parts = line.split('-');
      
      myAreas.push(parts[0]);
      myEnvs.push(parts[1]);

    });

    this.envs = Array.from(new Set(myEnvs)); // Deduplicate envs
    this.areas = myAreas;
    this.getShiftList();
  }

  getShiftList() {
    if (this.subscription) this.subscription.unsubscribe();

    this.subscription = this.svcShiftService.getPriorShiftList(this.envs, this.areas).subscribe(
      (out) => {
        console.log("Data", out);
        this.rawData = out['Body'];
        this.dataSource = [];

        let currentArea:PriorShiftArea; 
        
        this.rawData.forEach( shift => {
          if (currentArea && currentArea['Area'] != shift['Area']) {
            this.dataSource.push(currentArea);
            currentArea = null;
          }

          if (currentArea == null) {
            currentArea = {
              ProjectID: shift['ProjectID'],
              Area: shift['Area'],
              AreaDesc: shift['AreaDesc'],
              Shifts:[]
            }
          }

          if (currentArea['Area'] == shift['Area']) {
            currentArea.Shifts.push(shift);
          }

        });

        if (currentArea) {
          this.dataSource.push(currentArea);
        }

        console.log("this.dataSource", this.dataSource);
    });

  }

  onDateSelection(e) {
    console.log("Date selected", e);
  }

  deleteShift(shift:PriorShift) {
    console.log("Remove shift", shift);

    const dialogRef = this.dialog.open(RemoveShiftDialogComponent,{
      width:'250px',
      data: {
        Shift: shift
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog closed");
    });
  }

  onBack():void {
    this.router.navigate(['/area', 'select', this.site, 'prevshift', 'ALL']);
  }

  openEOSMetrics(shift:PriorShift) {
    const dialogRef = this.dialog.open(EosMetricsDialogComponent,{
      width:'250px',
      data: {
        Shift: shift
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog closed");
    });
  }

}

export type PriorShiftArea = {
  ProjectID:string,
  Area:string,
  AreaDesc:string
  Shifts:PriorShift[]
}

/*export type Shift = {
  ShiftDate:string, //Date,
  Shift:string,
  ShiftStart:Date,
  ShiftEnd:Date,
}*/

export type EOSMetric = {
  ObjectID:string,
  Target:number,
  Actual:number
}