import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LocationStrategy, PathLocationStrategy} from "@angular/common";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GEA_MES_NAVBAR_APPLICATION_NAME,GEA_MES_NAVBAR_MENU_SERVICE,GEA_MES_NAVBAR_ENV_PROD_STATUS, GEA_ENVIROMENT, NavItem} from '@gea-mes/navbar';
import { GeaMesAuthInterceptor,GeaMesLoginComponent, GeaMesCognitoAuthGuard, COGNITO_CONFIG, GeaMesCognitoModule, APPLICATION_NAME, APIGATEWAY_CONFIG} from "@gea-mes/cognito"
import {environment} from '../environments/environment';

import {LocationSelectComponent} from "./main/location-select/location-select.component";
import {AreasSelectComponent} from "./main/areas-select/areas-select.component";
import { LogOutPageComponent } from './main/log-out-page/log-out-page.component';
import { ActivitySelectComponent } from './main/activity-select/activity-select.component';
import { PauseComponent } from './pause/pause.component';
import { BreakEarlyComponent } from './break-early/break-early.component';
import { AreaShiftListComponent } from './easy-shift/components/area-shift-list/area-shift-list.component';
import { PriorShiftEditComponent } from './prior-shift-edit/prior-shift-edit.component';
import { appinfo } from './appinfo';
import { BehaviorSubject } from 'rxjs';

const appRoutes: Routes = [
  {path: 'login', component: GeaMesLoginComponent},
  {path: 'logout', component: LogOutPageComponent},
  {
    path: '', canActivate: [GeaMesCognitoAuthGuard],
    children: [ 
      {path: '', redirectTo:'/locations', pathMatch: 'full'},
      {path: 'locations', component: LocationSelectComponent},
      {path: 'area/select/:site/:state/:environment', component: AreasSelectComponent},
      {path: 'area/select/:site', component: AreasSelectComponent},
      {path: 'activity/select/:site', component: ActivitySelectComponent },
      {path: 'pause/:site/:state/:groups/:environment/:lines', component: PauseComponent},
      {path: 'breakearly/:site/:state/:groups/:environment/:lines', component: BreakEarlyComponent},
      {path: 'easyshift/:site/:state/:groups/:environment/:lines', component: AreaShiftListComponent},
      {path: 'manageprevshift/:site/:state/:groups/:environment/:lines', component: PriorShiftEditComponent},
      {path: 'template', loadChildren: () => import('./template/template.module').then(m => m.TemplateModule)}, 
      {path: 'active', loadChildren: () => import('./active-calendar/active-calendar.module').then(m => m.ActiveCalendarModule)},
      {path: 'admin', loadChildren: () => import('./admin/admin.module').then(m=>m.AdminModule)}
    ]
  },
  {path: '**', redirectTo: '/locations'}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes), GeaMesCognitoModule],
  providers: [
    { provide: COGNITO_CONFIG, useValue: environment.cognitoOptions },
    { provide: LocationStrategy, useClass: PathLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: GeaMesAuthInterceptor, multi: true },
    { provide: APPLICATION_NAME, useValue: 'WORKCALENDAR' },
    { provide: APIGATEWAY_CONFIG, useValue: environment.apiGateway },
    { provide: GEA_MES_NAVBAR_APPLICATION_NAME,useValue:appinfo.name.toUpperCase()},
    { provide: GEA_MES_NAVBAR_MENU_SERVICE, useValue: new BehaviorSubject<NavItem[]>([]) },
    { provide: GEA_MES_NAVBAR_ENV_PROD_STATUS, useValue: environment.production },
    { provide: GEA_ENVIROMENT, useValue: environment }

  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }

export const routingComponents = [LocationSelectComponent];
