import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarService } from 'src/app/services/calendar.service';
import {ActivatedRoute, Router} from "@angular/router";
import { state, trigger, style, transition, animate } from '@angular/animations';
import {pluck} from "rxjs/operators";
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import {MatSort} from '@angular/material/sort';
import { TemplatesService } from '../../services/templates.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TemplateCreateDialogComponent } from './template-create-dialog/template-create-dialog.component';

export interface wcTemplate {
  Site: string;
  Name: string;
  Areas: string [];
  Days: string [];
}



@Component({
  selector: 'app-template-home',
  templateUrl: './template-home.component.html',
  styleUrls: ['./template-home.component.scss'],
  animations:[
    trigger('showButtons', [
      state('collapsed', style({height: '0px', minHeight:'0'})),
      state('expanded', style({height:'*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TemplateHomeComponent implements OnInit {

  @ViewChild(MatSort) sort:MatSort;

  site: string;
  displayName: string;
  dataSource: MatTableDataSource<wcTemplate>;
  mDataSource: any[];
  templatesArr: any;
  displayedColumns: string[] = ["Name", "Areas", "Days"];
  expandedItem: wcTemplate | null;
  envsSet: Set<string>;
  templatesLoaded: Boolean;
  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])

  constructor(private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private calendarService: CalendarService,
    private templateService: TemplatesService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.envsSet = new Set();
    this.site = this.route.snapshot.paramMap.get('site');
    this.dataSource = new MatTableDataSource<wcTemplate>(); 
    this.templatesLoaded = false;
    this.calendarService.getAreasAPI(this.site, false).pipe(
      pluck('Body'),
      pluck('areas')
      ).subscribe((areas) =>{
      let areasArr: any = areas
      console.log(areasArr)
      areasArr.forEach(area => {
        if (!this.envsSet.has(area['Environment'])){
          this.envsSet.add(area['Environment'])
        } 
      });

      this.getTemplates()
    })
  }

  onBack(){
    this.router.navigate(['activity', 'select', this.site]);
  }

  goToTemplateDetail(siteName, template){
    console.log(template) 
    let envs = template.Environment 
    let templateName = template.Name
    this.templateService.setEnvironmentList(envs)
    this.router.navigate(['/template/Detail', siteName, templateName]);
  }

  goToCreateTemplate(siteName){
    const dialogRef = this.dialog.open(TemplateCreateDialogComponent, {
      width: '250px', //consider changing based on window size...
      data: {'site': siteName, 'templateList': this.templatesArr}
    });    
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result.createType == 'Existing' || result.createType =='Blank'){
        result['Environment'] = result.envs
        result['Name'] = result.template
        this.goToTemplateDetail(result.site, result)
      }
    });
  }

  getTemplates(){
    let envString: string = ''
    let setLen: number = this.envsSet.size
    let i = 0
    for (let env of this.envsSet){
      if(i == setLen - 1){
        envString += env
      } else {
        envString += env + ','
      }
      i++
    }

    this.templateService.getTemplates(envString).subscribe((templates) =>{
      this.templatesArr = templates['Body']
      this.templatesLoaded = true;
      console.log(templates)
      this.mDataSource = this.templatesArr
      this.mDataSource.sort(function(a,b){
        var textA = a.Name.toUpperCase();
        var textB = b.Name.toUpperCase();
        return(textA < textB)? -1: (textA > textB)? 1: 0; 
      })
      this.dataSource.data = this.templatesArr
      this.dataSource.sort = this.sort
      console.log(this.dataSource)
    })

  }

}
