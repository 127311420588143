<div *ngIf="changesMade; else noChangesMade">
  <div mat-dialog-content>
    <span *ngIf="data.action == 'Modify'" style="font-size:16px">Summary of Modification(s):</span>
    <span *ngIf="data.action == 'Delete'" style="font-size:16px">Schedule Item to Delete: {{data.newEvent.Event}}</span>
    <br>
    <div *ngIf="data.action == 'Modify'">
      <div style="margin-bottom: 10px" *ngFor="let key of keys();">

        <div style="font-size:16px" *ngIf="newData[key] != oldData[key] && key != 'Reason' && key != 'AuroraEnv'">{{key}}</div>
        <div *ngIf="(key == 'StartTime' || key == 'EndTime') && isTemplate">
          <div  style="color:black" *ngIf="newData[key] != oldData[key]">{{oldData[key] | amDateFormat:'hh:mm A'}}</div>
          <div style="color:red" *ngIf="newData[key] != oldData[key]">{{newData[key] | amDateFormat:'hh:mm A'}}</div>
        </div>
        <div *ngIf="(key == 'StartTime' || key == 'EndTime') && !isTemplate">
          <div  style="color:black" *ngIf="newData[key] != oldData[key]">{{oldData[key]}}</div>
          <div style="color:red" *ngIf="newData[key] != oldData[key]">{{newData[key]}}</div>
        </div>
        <div *ngIf="key != 'StartTime' && key != 'EndTime' && key != 'Reason'">
              <div style="color:black" *ngIf="newData[key] != oldData[key] && key != 'AuroraEnv'">{{oldData[key]}}</div>
            <div style="color:red" *ngIf="newData[key] != oldData[key] && key != 'AuroraEnv'">{{newData[key]}}</div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider style="margin-bottom: 10px"></mat-divider>
  <mat-form-field style="width: 50%;">
    <mat-label>Reason for Modification:</mat-label>
    <input matInput maxlength="80" [(ngModel)]="newReason" placeholder="ex. Unforeseen Event" name="Reason" style="cursor:pointer">
    <mat-hint>{{newReason.length}} / 80</mat-hint>
  </mat-form-field>
  <br>
  <mat-divider style="margin-top: 10px"></mat-divider>
  <h3>Are you sure you want to make this modification?</h3>
  <div mat-dialog-actions>
    <button mat-button color="primary" (click)="onSubmit()">Yes</button>
    <button mat-button color="primary" (click)="onNoClick()">No</button>
  </div>
</div>

<ng-template #noChangesMade>
  <div mat-dialog-content>
    <h1>No Changes Made</h1>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="primary" (click)="onNoClick()">Go Back</button>
  </div>
</ng-template>