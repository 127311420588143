import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TemplateDetailComponent } from './components/template-detail/template-detail.component';
import { TemplateHomeComponent } from './components/template-home/template-home.component';


const routes: Routes = [
  {path: 'Home/:site', component: TemplateHomeComponent },
  {path: 'Detail/:site/:template', component: TemplateDetailComponent},  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplateRoutingModule { }
