import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators} from "@angular/forms";


@Component({
  selector: 'app-reason-dialog',
  templateUrl: './reason-dialog.component.html',
  styleUrls: ['./reason-dialog.component.scss']
})
export class ReasonDialogComponent implements OnInit {
  ngForm: NgForm;
  pauseFormGroup: UntypedFormGroup; 
  reason: string;

  constructor() {}

  ngOnInit(): void {
    this.pauseFormGroup = new UntypedFormGroup({
      reason: new UntypedFormControl('', [Validators.required])
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.pauseFormGroup.controls[controlName].hasError(errorName)
  }

}
