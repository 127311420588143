import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TemplatesService } from 'src/app/template/services/templates.service';
import { CalendarService } from 'src/app/services/calendar.service';


export interface templateNameChange {
  'template': string, 
  'areas': area[], 
  'environments': string[]
}

export interface area {
  area: string,
  env: string,
  AuroraEnv: string
}

export type renameTemplate = {
  environment: string,
  inOldTemplate: string,
  inNewTemplate: string,
  inOldArea: string,
  inNewArea: string
}

@Component({
  selector: 'app-template-name-change-dialog',
  templateUrl: './template-name-change-dialog.component.html',
  styleUrls: ['./template-name-change-dialog.component.scss']
})
export class TemplateNameChangeDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: templateNameChange,
  public dialogRef: MatDialogRef<TemplateNameChangeDialogComponent>,
  private templateService: TemplatesService,
  private calendarService: CalendarService) { }
  templateOptionsForm: UntypedFormGroup;
  environment: string[];
  oldTemplatename: string;
  newTemplatename: string;
  oldArea: area[];
  newArea: area[];
  templateChangeArr: renameTemplate[];

  
  ngOnInit() {
    this.templateOptionsForm = new UntypedFormGroup({
      title: new UntypedFormControl(this.oldTemplatename)
    });
    
    this.oldTemplatename = this.data.template;
    this.newTemplatename = this.oldTemplatename
    this.oldArea = this.data.areas
    this.newArea = this.data.areas
    this.templateChangeArr = []
  }

  

  saveNewTemplate(){
    for (let area in this.oldArea){
      console.log(this.oldArea)
      let templateChangeObj ={
        'inOldTemplate': this.oldTemplatename,
        'inNewTemplate': this.newTemplatename,
        'environment': this.oldArea[area].AuroraEnv,
        'inOldArea': this.oldArea[area].area,
        'inNewArea': this.oldArea[area].area
      }

      this.templateChangeArr.push(templateChangeObj)
    };
    this.calendarService.isLoading.next(true);

    this.templateService.updateTemplateName(this.templateChangeArr).subscribe((data) =>{
      this.calendarService.isLoading.next(false);
      this.dialogRef.close({'template': this.newTemplatename});
    })
  }

  cancel(){
    this.dialogRef.close({'template': this.oldTemplatename});
  }

}
