import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { renameTemplate } from '../components/template-detail/template-name-change-dialog/template-name-change-dialog.component';
import { Observable, BehaviorSubject } from 'rxjs';
import * as dayjs from 'dayjs';

export type TemplateEventType = {
  Area: string,
  ShiftDayOffset: number,
  ShiftDate: string,
  StartTime: string,
  EndTime: string,
  Event: string,
  EventType: string,
  ObjectDescription: string,
  Shift: string,
  ProjectID: string,
  BreakCatagory: string,
  Target: string,
  HornAdvDelta: string,
  HornAdvDuration: string,
  HornStartDelta: string,
  HornEndDelta: string,
  HornStartDuration: string,
  HornEndDuration: string,
  Template: string,
  Default: string,
  Modify_by: string,
  Reason: string,
  Action: string,
  DisplayStartTime: string,
  DisplayEndTime: string,
  DisplayShiftDayOffset: number,
  AuroraEnv: string
}

export type TemplateEventConfirmType = {
  events?: TemplateEventType[];
  oldEvent?: TemplateEventType;
  newEvent?: TemplateEventType;
  action?: string;
}





@Injectable({
  providedIn: 'root'
})
export class TemplatesService extends AbstractRestService {
  postInit(){}

  private _envList: BehaviorSubject<any[]> = new BehaviorSubject([])
  public envList: Observable<any[]> = this._envList.asObservable()


  setEnvironmentList(envs: any[]){    
    console.log(envs)
    this._envList.next(envs)
  }

  getTemplates(environments:string){
    let params: any = {};
    if (environments) params.environments = environments;
    return this.get({
      ApiResource: "/templates",
      data: params,
      repeatCall: false
    });    
  }

  getTemplateDetails(environments: string[], templateName: string){
    let params: any = {};
    templateName = encodeURIComponent(templateName);
    let environmentsStr = environments.join(',')
    console.log( "inside template_serice.getTemplatesDetails ")
    if (environmentsStr) params.environments = environmentsStr;
    if (templateName) params.template = templateName;
    return this.get({
      ApiResource: "/templatedetails",
      data: params,
      repeatCall: false
    });
  }

  updateDefaultDays(templateName: string, projects: string[], newDays: string[]){
    let params: any = {};

    params.environment = projects.toString();
    params.templatename = templateName;
    params.daylist = newDays.toString();
    params.reason = "Template Change";
    console.log( "inside template_serice.updateDefaultDays ")
    let paramsArr = {'data': params}
    return this.post({
      ApiResource: "/templates",
      data: paramsArr,
      repeatCall: false
    });
  }

  templateCopy(site: string, proficyEnvs: string[], oldTemplateName: string, newTemplateName: string){
    let params: any = {}

    params.Site = site
    params.ProficyEnvironments = proficyEnvs
    params.OldTemplate = oldTemplateName
    params.NewTemplate = newTemplateName
    let paramsArr = {'data': params}
    console.log( "inside template_serice.templateCopy ")
    return this.post({
      ApiResource: "/templatecopy",
      data: paramsArr,
      repeatCall: false
    });
  
  }

  updateTemplateName(updateTemplateNameObj: renameTemplate[]){
    let params: any = {};
    params.templateRenameObj = updateTemplateNameObj;
    let paramsArr = {'data': params}
    return this.put({
      ApiResource: "/templates",
      data: paramsArr,
      repeatCall: false
    });

  }


  createTemplate(){

  }

  deleteTemplate(template: any){
    let params: any = {};

    params.templatename = template.name,
    params.environment = template.environment
    return this.delete({
      ApiResource: "/templates",
      data: params,
      repeatCall: false
    });
  }

  
}
