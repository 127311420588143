import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {AreaType, CalendarService} from "../../services/calendar.service";
import {Observable} from "rxjs/internal/Observable";
import {switchMap, pluck} from "rxjs/operators";
import {Subscription} from "rxjs/internal/Subscription";


@Component({
  selector: 'app-areas-select',
  templateUrl: './areas-select.component.html',
  styleUrls: ['./areas-select.component.scss']
})


export class AreasSelectComponent implements OnInit {
  site: string;
  state: string;
  selgroup:any[];
  pfcyEnvironment: string;
  allLines: AreaType[] = [];
  displayLines: AreaType[] = [];
  allGroups: any[] = [];
  uniqueEnvironments: string[];
  allEnvironments: string[];
  subscription: Subscription;
  hasSelection: number = 0;
  isLoaded: boolean= false;


  constructor(private route: ActivatedRoute, private calendarService:CalendarService, private router: Router) {}

  ngOnInit(): void {
    this.allEnvironments = [];
    this.calendarService.isLoading.next(true);
    this.site = this.route.snapshot.paramMap.get('site');
    this.state = this.route.snapshot.paramMap.get('state');
    this.pfcyEnvironment = this.route.snapshot.paramMap.get('environment')
    this.callAreas();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onBack(){
    this.router.navigate(['/activity', 'select', this.site]);
  }

  callAreas(){
    let d: Observable<AreaType[]> =
      this.route.paramMap.pipe(
        switchMap((params:ParamMap) =>
        this.calendarService.getAreasAPI(this.site, false)),
        pluck('Body')
      );
    if (this.subscription) this.subscription.unsubscribe();

    this.subscription = d.subscribe((next) => {
      console.log(next)
      this.isLoaded = true;
      this.allLines = this.convertAreas(next['areas']);
      this.displayEnvironment(this.pfcyEnvironment)
      if (this.uniqueEnvironments.length > 1){
        this.allEnvironments = this.uniqueEnvironments
        this.allEnvironments.push('ALL')
      }
      this.allGroups = next['groups'];
      this.calendarService.isLoading.next(false);
    });
    
  }

  convertGroups(groupArr: any[]){
    let arr: any[] = []
    groupArr.forEach((el) =>{
      arr.push(Object.keys(el)[0])
    })
    console.log(arr)
    return arr
  }


  convertAreas(arr: AreaType[]){
    let newArr: any[] = [];
    this.uniqueEnvironments = [];
    arr.forEach((el) => {
      if (!this.uniqueEnvironments.includes(el.Environment)){
        this.uniqueEnvironments.push(el.Environment);
      }
      newArr.push(el)
    });

    return newArr;
  }

  handleClick() {
    this.selgroup =[]
    let groups = this.filterGroups(this.allGroups)
    console.log('before lines definition')
    let lines = this.displayLines
            .filter(opt => opt.Checked)
            .map(opt => opt.Area + "-" + opt.Environment);

    if (this.state.indexOf('pause') != -1) {
      if(groups.length == 0 ){
        groups = ['NO'];
      } else {
        groups = this.pauseMenuGroupConversion();
      }
      this.router.navigate(['/pause',this.site,this.state,groups.join("&"), this.pfcyEnvironment, lines.join("&")]); 
    }
    else if (this.state.indexOf('breakearly') != -1) {
      if(groups.length == 0 ){
        groups = ['NO'];
      } else {
        groups = this.pauseMenuGroupConversion();
      }
      this.router.navigate(['/breakearly',this.site,this.state,groups.join("&"), this.pfcyEnvironment, lines.join("&")]);
    }
    else if (this.state.indexOf('easyshift') != -1) {
      if(groups.length == 0 ){
        groups = ['NO'];
      } else {
        groups = this.pauseMenuGroupConversion();
      }
      this.router.navigate(['/easyshift',this.site,this.state,groups.join("&"), this.pfcyEnvironment, lines.join("&")]);
    }
    else if (this.state.indexOf('prevshift') != -1) {
      if(groups.length == 0 ){
        groups = ['NO'];
      } else {
        groups = this.pauseMenuGroupConversion();
      }
      this.router.navigate(['/manageprevshift',this.site,this.state,groups.join("&"), this.pfcyEnvironment, lines.join("&")]);
    }
    else {
      let combinedLines = this.combineDeDup(lines, groups)
      this.router.navigate(['/active/calendar', this.site, 'active', this.pfcyEnvironment, combinedLines.join('&')])
    }
  }

  pauseMenuGroupConversion(): any[] {
    let fvar = ''
    let selGroup = []
    for (const key in this.allGroups) {
      if("Checked" in this.allGroups[key] && this.allGroups[key]["Checked"] == true) {
        fvar = key.replace(/\s/g,'')
        selGroup.push(fvar)
      }
    }

    return selGroup
  }

  filterGroups(groupObj: any){
    let groupArr = []
    for (const key in groupObj){
      if("Checked" in groupObj[key] && groupObj[key]["Checked"] == true){
        if (groupArr.length > 0){
          groupObj[key]["areas"].forEach(element => {
            groupArr.push(element["area"])
          });
        } else{
          groupObj[key]["areas"].forEach(element => {
            groupArr.push(element['area'])
          });
        }
      }
    }
    return groupArr
  }

  combineDeDup(linesArr: any[], groupsArr: any[]){
    let comArr = linesArr.concat(groupsArr)
    if(linesArr.length == 0 || groupsArr.length == 0){
      return comArr
    } else{
      console.log(' the value of length ', comArr.length)
      for (let i = 0; i < comArr.length; i++){
        for(let j = i + 1; j < comArr.length; j++){
          if (comArr[i] == comArr[j]){
            comArr.splice(j--, 1)
          }
        }
      }
      return comArr
    }
  }

  onSelection(e){
    if (e){this.hasSelection++; }
    else {this.hasSelection--;}
    return e;
  }

  onSelectionG(e,grp){
    if (e){
      this.hasSelection++; 
      for (let [key, value ] of Object.entries(this.allGroups)) {
        if( key == grp.key) {
          for (let i = 0; i < this.allGroups[key].areas.length; i++){
            for(let j =0 ; j < this.displayLines.length; j++ ){
               if (this.displayLines[j].ObjectDescription == this.allGroups[key].areas[i].description){
                this.displayLines[j].Checked = true
               }
            }
          }
        }
        }
    }
    else {this.hasSelection--;
      console.log (' No selection ')
      for (let [key, value ] of Object.entries(this.allGroups)) {
        if( key == grp.key) {
          for (let i = 0; i < this.allGroups[key].areas.length; i++){
            for(let j =0 ; j < this.displayLines.length; j++ ){
              if (this.displayLines[j].ObjectDescription == this.allGroups[key].areas[i].description){
                this.displayLines[j].Checked = false
               }
            }
          }
        }
      }

    }
    return e;
  }
  filterByEnvironment(sEnv){
    this.pfcyEnvironment = sEnv
    this.displayEnvironment(sEnv)
    this.router.navigate(['/area/select', this.site, this.state, sEnv]);
  }

  displayEnvironment(sEnv){
    this.displayLines =  []
    if (sEnv == 'ALL'){
      this.displayLines = this.allLines
    }
    else{
      this.allLines.forEach(line =>{
        if (line.Environment == sEnv){
          this.displayLines.push(line)
        }
      })
    }
    this.displayLines.sort((a,b) => (a.ObjectDescription > b.ObjectDescription) ? 1: -1)
  }
}
