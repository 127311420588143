import { Injectable } from '@angular/core';
import { Subject, Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { CalendarService } from '../../services/calendar.service';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { CalendarEvent, CalendarEventType } from '../models/event-dialog.model';

export interface shiftDetail {
  shifts: string[]
  shiftTimes: shiftTimes[]
}

export interface shiftTimes {
  start: string
  end: string
}


export interface EventDetails {
  [key: string]: eventTimes
}

export interface eventTimes {
  eventTimes: EventDetail[]
}



export interface EventDetail {
  start: string,
  end: string,
  eventID: string,
  BreakCategory: string
}

export interface ErrorMessageDetails{
  type: string
  errorMessage: string
}

@Injectable({
  providedIn: 'root'
})
export class EventValidationService {
  private _shiftList: Subject<shiftDetail[]> = new ReplaySubject()
  public shiftList: Observable<shiftDetail[]> = this._shiftList.asObservable()
  private shiftValidationObj: shiftDetail[];

  private _eventList: Subject<EventDetails[]> = new ReplaySubject()
  public eventList: Observable<EventDetails[]> = this._eventList.asObservable()
  private eventValidationObj: EventDetails;
  
  private errorMessageList: ErrorMessageDetails[];
  private calendarServiceSubscription: Subscription;
  public existingEventsFlag: boolean
  public currentEvent: any;

  constructor(private calendarService: CalendarService,
    private toastr: ToastrService
  ) {
    this.errorMessageList = [];
   }

  updateShiftList(calendarEvents: CalendarEventType, templateEvent: boolean, uniqueEnvs: string[] = [], uniqueAreas: string[] = [], start: dayjs.Dayjs = dayjs(), end: dayjs.Dayjs = dayjs().add(1, 'day'),Allevent:any = []) {
    let shiftCheckStart = start.add(-1, 'day').format('YYYY-MM-DDTHH:mm:ss');
    let shiftCheckEnd = end.add(1, "day").format('YYYY-MM-DDTHH:mm:ss');
    let inshiftDetails = Allevent['Body']; 
    if (this.calendarServiceSubscription) this.calendarServiceSubscription.unsubscribe()

    if (templateEvent) {
      if (calendarEvents.Action == 'New'){
        this._shiftList.next([])
        this.shiftValidationObj = []
      } else {
        let calendarEventValues: any = Object.values(calendarEvents)
        let calendarEventsFlat: string[] = calendarEventValues.flat()
        this.createShiftList(calendarEventsFlat, templateEvent)
      }
    } else {
        this.createShiftList(inshiftDetails[0]['Data'], templateEvent)

    }
  }

  createShiftList(calendarEvents, templateEvent){
    let shiftTestObj:any = {};
    let eventTestObj:any = {};
    let eventTrackerSet: any = new Set();

    if (calendarEvents.length > 0) {
      calendarEvents.forEach(element => {

        let shiftDate = element.ShiftDate ? element.ShiftDate : dayjs().format('YYYY-MM-DD')
        let eventTime = {
          'start': element.StartTime,
          'end': element.EndTime
        }
        
        if (element['Event'].substring(0,5) == "SHIFT") {
          let shiftEventQueue = [];

          if (templateEvent){
            let newTempTemplateEvents = this.normalizeTemplateEvents(element)
            shiftEventQueue = newTempTemplateEvents
          } else {
            shiftEventQueue.push(element)
          }

          for (let shift in shiftEventQueue) {
            if (!(shiftEventQueue[shift].ProjectID + '_' + shiftEventQueue[shift].Area in shiftTestObj)){
              shiftTestObj[shiftEventQueue[shift].ProjectID + '_' + shiftEventQueue[shift].Area] = {}
            }

            if (shiftEventQueue[shift].ProjectID + '_' + shiftEventQueue[shift].Area + '_' + shiftDate in shiftTestObj) {
              shiftTestObj[shiftEventQueue[shift].ProjectID + '_' + shiftEventQueue[shift].Area + '_' + shiftDate]['shifts'].push({
                'shiftTitle': shiftEventQueue[shift].Shift, 
                'eventID': shiftDate + '_' + shiftEventQueue[shift].Event,
                'shiftDayOffset': shiftEventQueue[shift].ShiftDayOffset
              })
            } else {
              shiftTestObj[shiftEventQueue[shift].ProjectID + '_' + shiftEventQueue[shift].Area + '_' + shiftDate] = {}
              shiftTestObj[shiftEventQueue[shift].ProjectID + '_' + shiftEventQueue[shift].Area + '_' + shiftDate]['shifts'] = {}
              shiftTestObj[shiftEventQueue[shift].ProjectID + '_' + shiftEventQueue[shift].Area + '_' + shiftDate]['shifts'] = [{
                'shiftTitle': shiftEventQueue[shift].Shift, 
                'eventID': shiftDate + '_' + shiftEventQueue[shift].Event,
                'shiftDayOffset': shiftEventQueue[shift].ShiftDayOffset
              }]
            }

            if ('shiftTimes' in shiftTestObj[shiftEventQueue[shift].ProjectID + '_' + shiftEventQueue[shift].Area]) {
              shiftTestObj[shiftEventQueue[shift].ProjectID + '_' + shiftEventQueue[shift].Area]['shiftTimes'].push({
                'start': shiftEventQueue[shift].StartTime, 
                'end': shiftEventQueue[shift].EndTime,
                'eventID': shiftDate + '_' + shiftEventQueue[shift].Event
              })
            } else {       
              shiftTestObj[shiftEventQueue[shift].ProjectID + '_' + shiftEventQueue[shift].Area]['shiftTimes'] = {}
              shiftTestObj[shiftEventQueue[shift].ProjectID + '_' + shiftEventQueue[shift].Area]['shiftTimes'] = [ {
                'start': shiftEventQueue[shift].StartTime, 
                'end': shiftEventQueue[shift].EndTime,
                'eventID': shiftDate + '_' + shiftEventQueue[shift].Event
              } ]
            }
          }
        } else {
          let eventQueue = []
          if (!eventTrackerSet.has(element.ProjectID + '_' + element.Area + '_' + element.Event)) {
            
            if (templateEvent){
              let newTempTemplateEvents = this.normalizeTemplateEvents(element)
              eventQueue = newTempTemplateEvents
            } else {
              eventQueue.push(element)
            } 
            
            for (let events in eventQueue) {
              
              if (!(eventQueue[events].ProjectID + '_' + eventQueue[events].Area in eventTestObj)){
                eventTestObj[eventQueue[events].ProjectID + '_' + eventQueue[events].Area] = {}
              }
              
              if ('eventTimes' in eventTestObj[eventQueue[events].ProjectID + '_' + eventQueue[events].Area]) {
                eventTestObj[eventQueue[events].ProjectID + '_' + eventQueue[events].Area]['eventTimes'].push({
                  'start': eventQueue[events].StartTime, 
                  'end': eventQueue[events].EndTime,
                  'eventID': shiftDate + '_' + eventQueue[events].Event,
                  'BreakCategory': eventQueue[events].BreakCatagory
                })
              } else {       
                eventTestObj[eventQueue[events].ProjectID + '_' + eventQueue[events].Area]['eventTimes'] = {}
                eventTestObj[eventQueue[events].ProjectID + '_' + eventQueue[events].Area]['eventTimes'] = [ {
                  'start': eventQueue[events].StartTime, 
                  'end': eventQueue[events].EndTime,
                  'eventID': shiftDate + '_' + eventQueue[events].Event,
                  'BreakCategory': eventQueue[events].BreakCatagory
                } ]
              }
            }

            eventTrackerSet.add(element.ProjectID + '_' + element.Area + '_' + element.Event)
          }
        }
      });
      }
    this._shiftList.next(shiftTestObj)
    this.shiftValidationObj = shiftTestObj
    this._eventList.next(eventTestObj)
    this.eventValidationObj = eventTestObj
    console.log(this.eventValidationObj)
  }

  validate(events: CalendarEventType[], isShift: boolean, templateEvent: boolean, eventAction: string): boolean[]{
    let validations: boolean[] = []
    this.errorMessageList = []
    this.hideErrorMessage();
    if (this.shiftValidationObj === undefined) this.shiftValidationObj = [] 

    events.forEach(event => {
      this.errorMessageList = []
      let shiftDate = event.ShiftDate ? event.ShiftDate : this.createShiftDate(event)
      let eventID = shiftDate + '_' + event.Event
      validations.push(this.validateShift(event, isShift))
      validations.push(this.validateTarget(event, isShift))
      if (templateEvent) {
        validations.push(this.validateShiftOffsetCompatability(event, eventID))
      }
      
      if (isShift) {
        validations.push(this.validateShiftTitle(event, eventID))
        validations.push(this.validateShiftTimeFrame(event, eventID, templateEvent))
      } else {
        validations.push(this.validateEventIsInShift(event, eventID))
        event.BreakCatagory != 'Pause' ? validations.push(this.validateEventTimeFrame(event, eventID, templateEvent)) : validations.push(this.validatePauseConflict(event, eventID, templateEvent))
      }

      if (validations.includes(false)) {
        this.showErrorMessage(event)
      }
    });
    return validations
  }

  createShiftDate(calendarEvent: CalendarEventType): string {
    let shiftDate = dayjs()
    let shiftDateStr = ''

    if (calendarEvent.ShiftDayOffset == -1){
      shiftDate.add(-1, 'day')
    } else if (calendarEvent.ShiftDayOffset == 1){
      shiftDate.add(1, 'day')
    }

    shiftDateStr = shiftDate.format('YYYY-MM-DD')
    return shiftDateStr
  }

  adjustShiftTime(calendarEvent: CalendarEventType): {'start': dayjs.Dayjs, 'end': dayjs.Dayjs} {
    let adjustedShiftTime = {
      'start': dayjs(dayjs().format('YYYY-MM-DD') + ' ' + calendarEvent.StartTime),
      'end': dayjs(dayjs().format('YYYY-MM-DD') + ' ' + calendarEvent.EndTime)
    }
    if (calendarEvent.ShiftDayOffset == -1) {
      adjustedShiftTime.start.add(-1, 'day')
    } else if (calendarEvent.ShiftDayOffset == 1) {
      adjustedShiftTime.start.add(1, 'day')
      adjustedShiftTime.end.add(1, 'day')
    }

    return adjustedShiftTime
  }

  normalizeTemplateEvents(calendarEvent: any): {'start': dayjs.Dayjs, 'end': dayjs.Dayjs}[] {
    try {
      let templateEvents = []
      let today = dayjs().format('YYYY-MM-DD')
      let start: dayjs.Dayjs
      let end: dayjs.Dayjs

      if (!dayjs(calendarEvent.StartTime).isValid()){
        start = 'StartTime' in calendarEvent ? dayjs(today + ' ' + calendarEvent.StartTime) : dayjs(today + ' ' + calendarEvent.Start)
        end = 'EndTime' in calendarEvent ? dayjs(today + ' ' + calendarEvent.EndTime) : dayjs(today + ' ' + calendarEvent.End)
      } else {  
        start = dayjs(calendarEvent.StartTime)
        end = dayjs(calendarEvent.EndTime)
      }

      let normalizedTimes = {
        'start': start,
        'end': end
      }

      if (normalizedTimes.start.isAfter(normalizedTimes.end)) {
        let calendarEventBeforeMid = JSON.parse(JSON.stringify(calendarEvent))
        let calendarEventAfterMid = JSON.parse(JSON.stringify(calendarEvent))

        calendarEventBeforeMid.StartTime = normalizedTimes.start.format('YYYY-MM-DD HH:mm:ss.sssssss')
        calendarEventBeforeMid.EndTime = dayjs(today + ' ' + '23:59:59').format('YYYY-MM-DD HH:mm:ss.sssssss')
        
        calendarEventAfterMid.StartTime = dayjs(today + ' ' + '00:00:00').format('YYYY-MM-DD HH:mm:ss.sssssss')
        calendarEventAfterMid.EndTime = normalizedTimes.end.format('YYYY-MM-DD HH:mm:ss.sssssss')
        
        templateEvents.push(calendarEventBeforeMid)
        templateEvents.push(calendarEventAfterMid)
      } else {
        calendarEvent.StartTime = normalizedTimes.start.format('YYYY-MM-DD HH:mm:ss.sssssss')
        calendarEvent.EndTime = normalizedTimes.end.format('YYYY-MM-DD HH:mm:ss.sssssss')      
        templateEvents.push(calendarEvent)      
      }

      return templateEvents
    } catch(error) {
      console.error("Error normalizing events")
    }
  }

  shiftOffSetEventAdjustment(calendarEvent: CalendarEventType): {'start': dayjs.Dayjs, 'end': dayjs.Dayjs} {
    let todayDate = dayjs().format('YYYY-MM-DD')
    let startTime = dayjs(calendarEvent.StartTime, 'YYYY-MM-DD HH:mm:ss.sssssss', true).isValid() ? dayjs(calendarEvent.StartTime).format('HH:mm:ss') : calendarEvent.StartTime
  
    let endTime = dayjs(calendarEvent.EndTime, 'YYYY-MM-DD HH:mm:ss.sssssss', true).isValid() ? dayjs(calendarEvent.EndTime).format('HH:mm:ss') : calendarEvent.EndTime


    let adjustedTimes = {
      'start': dayjs(todayDate + ' ' + startTime),
      'end': dayjs(todayDate + ' ' + endTime)
    }
   
    console.log(this.shiftValidationObj)
    return adjustedTimes
  }


  validateTarget(event: CalendarEvent, isShift: boolean): boolean{
    let errorMessage = ''

    if (isShift && (event.Target == '0' || event.Target == '' || event.Target == null)){
      errorMessage = '<h3>No Demand</h3>' +
                  'Shift: ' + event.Shift + '<br>' +
                  'Start Time: ' + dayjs(event.StartTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
                  'End Time: ' + dayjs(event.EndTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
                  'Every Shift Must Have a Demand'
      this.errorMessageList.push({'errorMessage': errorMessage, 'type': 'GENERAL'})
      return false
    } else {
      return true
    }
  }

  validateShift(event: CalendarEvent, isShift: boolean): boolean{
    let errorMessage = ''
    
    if (isShift && (event.Shift == null)){
      errorMessage = '<h3>No Shift Selected</h3>' +
                  'Shift: ' + event.Shift + '<br>' +
                  'Start Time: ' + dayjs(event.StartTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
                  'End Time: ' + dayjs(event.EndTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
                  'Shift must be selected'
      this.errorMessageList.push({'errorMessage': errorMessage, 'type': 'GENERAL'})
      return false
    } else {
      return true
    }
  }  

  validateReason(event: CalendarEvent): boolean {
    let errorMessage = ''
    if (event.Reason == "" || event.Reason == null){
      errorMessage = '<h3>No Reason</h3>' +
                  'Event: ' + event.Event + '<br>' +
                  'Start Time: ' + dayjs(event.StartTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
                  'End Time: ' + dayjs(event.EndTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
                  'All Modifications Must Have A Reason'
      this.errorMessageList.push({'errorMessage': errorMessage, 'type': 'GENERAL'})
      return false
    } else {
      return true
    }
  }

  setExistingEventFlag(existingFlag, event){
    this.existingEventsFlag = existingFlag
    this.currentEvent = event
  }

  setNewEventFlag(existingFlag){
    this.existingEventsFlag = existingFlag
    this.currentEvent = null
  }

  removeCurrentTimeFrame(event: CalendarEvent): Observable<boolean> {
    let startTime = dayjs(event.StartTime).format('YYYY-MM-DD HH:mm:ss.sssssss')
    let endTime = dayjs(event.EndTime).format('YYYY-MM-DD HH:mm:ss.sssssss')
    const idx = this.shiftValidationObj[event.ProjectID + '_' + event.Area]['shiftTimes'].findIndex(event => event.start == startTime && event.end == endTime)
    this.shiftValidationObj[event.ProjectID + '_' + event.Area]['shiftTimes'].splice(idx, 1)
     
    return of(true)
  }

  validateEventIsInShift(event: CalendarEvent, eventID: string): boolean {
    let errorMessage = ''

    if (event.Shift == 'Event Outside Shift'){
        
      errorMessage = errorMessage = '<h3>Event Not In Shift</h3>' +
      'Event: ' + event.Event + '<br>' +
      'Start Time: ' + dayjs(event.StartTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
      'End Time: ' + dayjs(event.EndTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
      'Events Must Be In A Shift'
      this.errorMessageList.push({'errorMessage': errorMessage, 'type': 'AREA'})
      return false
    } else {
      return true
    }
     
  }

  validateEventTimeFrame(event: CalendarEvent, eventID: string, templateEvent: boolean): boolean {
    let errorMessage = ''
    
    if(this.eventValidationObj[event.ProjectID + '_' + event.Area]) {
      let element;
      let eventValObj = this.eventValidationObj[event.ProjectID + '_' + event.Area].eventTimes.filter(event => event.BreakCategory != 'Pause')
      
      for (element in eventValObj) {  
        let eventValObjEventID = eventValObj[element].eventID
        let eventStartTime = dayjs(event.StartTime)
        let eventEndTime = dayjs(event.EndTime)
        let checkEventStart = dayjs(eventValObj[element].start)
        let checkEventEnd = dayjs(eventValObj[element].end)

        if( (eventValObjEventID != eventID) 
            && (
              ( (eventStartTime.isSameOrAfter(checkEventStart) && eventStartTime.isBefore(checkEventEnd))
                || (eventEndTime.isAfter(checkEventStart) && eventEndTime.isSameOrBefore(checkEventEnd))
                || (eventStartTime.isSameOrBefore(checkEventStart) && eventEndTime.isSameOrAfter(checkEventEnd)) 
              ) || 
              (
                (checkEventStart.isAfter(eventStartTime) && checkEventEnd.isBefore(eventEndTime))
              )
            )
          ) {
            errorMessage = '<h3>Overlapping Event Times</h3>' +
            'Shift: ' + event.Shift + '<br>' +
            'Start Time: ' + dayjs(event.StartTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
            'End Time: ' + dayjs(event.EndTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
            'Overlaps An Existing Event'
            this.errorMessageList.push({'errorMessage': errorMessage, 'type': 'AREA'})
            return false
        }
      };
    }
  }

  validatePauseConflict(event: CalendarEvent, eventID: string, templateEvent: boolean): boolean {
    let errorMessage = ''
    let timeCheckResult: boolean = true
    let eventTimeArr: any[] = []

    if (templateEvent) {
      eventTimeArr = this.normalizeTemplateEvents(event)
    } else {
      eventTimeArr.push(event)
    }

    if(this.eventValidationObj[event.ProjectID + '_' + event.Area]) {
      let element;
      let pauseEventValObj = this.eventValidationObj[event.ProjectID + '_' + event.Area].eventTimes.filter(event => event.BreakCategory == 'Pause')
      for (let shiftEvent in eventTimeArr) {
        for (element in pauseEventValObj) {
          let eventValObjEventID = pauseEventValObj[element].eventID
          let eventStartTime = dayjs(eventTimeArr[shiftEvent].StartTime)
          let eventEndTime = dayjs(eventTimeArr[shiftEvent].EndTime)
          let checkEventStart = dayjs(pauseEventValObj[element].start)
          let checkEventEnd = dayjs(pauseEventValObj[element].end)

        
          if( (eventValObjEventID != eventID) 
              && (
                ( (eventStartTime.isAfter(checkEventStart) && eventEndTime.isBefore(checkEventEnd)) 
                  || (eventStartTime.isBefore(checkEventStart) && eventEndTime.isAfter(checkEventStart) && eventEndTime.isBefore(checkEventEnd)) 
                  || (eventStartTime.isAfter(checkEventStart) && eventStartTime.isBefore(checkEventEnd) && eventEndTime.isAfter(checkEventEnd))
                  || (eventStartTime.isSame(checkEventStart) && eventEndTime.isSame(checkEventEnd))
                ) 
              )
            ) {
              errorMessage = '<h3>Overlapping Pause Events</h3>' +
              'Shift: ' + event.Shift + '<br>' +
              'Start Time: ' + dayjs(event.StartTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
              'End Time: ' + dayjs(event.EndTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
              'Overlaps An Existing Pause'
              this.errorMessageList.push({'errorMessage': errorMessage, 'type': 'AREA'})
              return false
          }
        };
      }
    }
    return true
  }

  validateShiftTimeFrame(event: CalendarEvent, eventID: string, templateEvent: boolean): boolean {
    let errorMessage = ''
    let timeCheckResult: boolean = true
    let eventTimeArr: any[] = []

    if (templateEvent) {
      eventTimeArr = this.normalizeTemplateEvents(event)
    } else {
      eventTimeArr.push(event)
    }

    for (let shift in eventTimeArr) {
      if(this.shiftValidationObj[eventTimeArr[shift].ProjectID + '_' + eventTimeArr[shift].Area]) {
        let element;
        for (element in this.shiftValidationObj[eventTimeArr[shift].ProjectID + '_' + eventTimeArr[shift].Area]['shiftTimes']) {
          let shiftValObjEventID = this.shiftValidationObj[eventTimeArr[shift].ProjectID + '_' + eventTimeArr[shift].Area]['shiftTimes'][element].eventID
          let eventStartTime = dayjs(eventTimeArr[shift].StartTime)
          let eventEndTime = dayjs(eventTimeArr[shift].EndTime)
          let checkShiftStart = dayjs(this.shiftValidationObj[eventTimeArr[shift].ProjectID + '_' + eventTimeArr[shift].Area]['shiftTimes'][element].start)
          let checkShiftEnd = dayjs(this.shiftValidationObj[eventTimeArr[shift].ProjectID + '_' + eventTimeArr[shift].Area]['shiftTimes'][element].end)

          if( (shiftValObjEventID != eventID) 
              && ((eventStartTime.isAfter(checkShiftStart) && eventEndTime.isBefore(checkShiftEnd)) 
              || (eventStartTime.isAfter(checkShiftStart) && eventStartTime.isBefore(checkShiftEnd)) 
              || (eventEndTime.isAfter(checkShiftStart) && eventEndTime.isBefore(checkShiftEnd))) 
              ||
                (checkShiftStart.isAfter(eventStartTime) && checkShiftEnd.isBefore(eventEndTime))  
            ) {
            timeCheckResult = false
          }
        };
      }
    }
    if (timeCheckResult){
      return true
    } else {
      errorMessage = '<h3>Overlapping Shift Times</h3>' +
                    'Shift: ' + event.Shift + '<br>' +
                    'Start Time: ' + dayjs(event.StartTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
                    'End Time: ' + dayjs(event.EndTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
                    'Overlaps An Existing Shift'
      this.errorMessageList.push({'errorMessage': errorMessage, 'type': 'AREA'})
      return false
    }
  }

  validateDuration(event: CalendarEvent): boolean {
    if(event.StartTime == event.EndTime){
      let errorMessage = "<h3>No Duration</h3>" + "<br>" + 
                        "Enter a Duration For This Event"
      this.errorMessageList.push({'errorMessage': errorMessage, 'type': 'GENERAL'})
      return false
    } else {
      return true
    }
  }

  validateShiftTitle(event:CalendarEvent, eventID: string): boolean {
    let errorMessage = ''
    
    if (event.Shift == "") {
      errorMessage = '<h3>No Shift Selected</h3>' +
                    'Shift: ' + event.Shift + '<br>' +
                    'Start Time: ' + dayjs(event.StartTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
                    'End Time: ' + dayjs(event.EndTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
                    'All Shifts Must Have A Shift Title'
      this.errorMessageList.push({'errorMessage': errorMessage, 'type': 'GENERAL'})
      return false
    }
    
    if (this.shiftValidationObj[event.ProjectID + '_' + event.Area + '_' + event.ShiftDate]) {
      let shiftCheck = this.shiftValidationObj[event.ProjectID + '_' + event.Area + '_' + event.ShiftDate]['shifts'].filter(shift => shift.shiftTitle === event.Shift)
      if (shiftCheck.length > 0){
        for (let shift in shiftCheck) {
          if(shiftCheck[shift].eventID != eventID) {
            errorMessage = '<h3>Shift Already Exists</h3>' +
                        'Shift: ' + event.Shift + '<br>' +
                        'Start Time: ' + dayjs(event.StartTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
                        'End Time: ' + dayjs(event.EndTime).format('YYYY-MM-DD HH:mm A') + '<br>' +
                        'This Shift Already Exists For This Area'
            this.errorMessageList.push({'errorMessage': errorMessage, 'type': 'AREA'})
            return false
          } 
        };
      }
    }  
  
    return true
  }

  validateShiftOffsetCompatability(event: CalendarEvent, eventID: string) {
    let errorMessage = ''

    if (this.shiftValidationObj[event.ProjectID + '_' + event.Area + '_' + event.ShiftDate]) {
      let shiftCheck = this.shiftValidationObj[event.ProjectID + '_' + event.Area + '_' + event.ShiftDate]['shifts']
      if (shiftCheck.length > 0){
        for (let shift in shiftCheck) {
          if(shiftCheck[shift].eventID != eventID && ((shiftCheck[shift].shiftDayOffset == 1 && event.ShiftDayOffset == -1) || (shiftCheck[shift].eventID != eventID && shiftCheck[shift].shiftDayOffset == -1 && event.ShiftDayOffset == 1))) {
            errorMessage = 'Cannot have a shift with an offset of Before and After'
            this.errorMessageList.push({'errorMessage': errorMessage, 'type': 'AREA'})
            return false
          } 
        };
      }
    }

    return true
  }

  showErrorMessage(event){
    let areasStr = ''
    this.errorMessageList.forEach(element => {
      areasStr += element.errorMessage + '<br>'
      + '--------------------------------------------------------------' + '<br>'
    });

    this.toastr.warning(
      '<br>' + areasStr + '<br>Click to dismiss this message.', 
       event.ObjectDescription + ' Failed to Create:', 
      {
        closeButton: true,
        positionClass: "toast-top-full-width",
        tapToDismiss: true,
        disableTimeOut: true,
        enableHtml: true
      });
  }

  hideErrorMessage() {
    this.toastr.clear()
  }
}
