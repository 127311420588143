<form [formGroup]="endtimeFormGroup">
    <h3 mat-dialog-title>Change Shift End Time</h3>
    <div mat-dialog-content>
        
        <mat-divider></mat-divider>        
        <div style="padding-top: 10px" class="fx-row-start-center">
            <div>
                <mat-form-field>
                    <mat-label>New Shift End Time:</mat-label>
                    <input  matInput 
                            placeholder="Shift End" 
                            [ngxTimepicker]="startTime" 
                            [min]="minTime"
                            [max]="maxTime"
                            [value]="shiftEnd"
                            formControlName="ShiftEnd" 
                            readonly>
                    <ngx-material-timepicker #startTime></ngx-material-timepicker>
                </mat-form-field>
            </div>
            <div style="padding: 15px">
                <mat-checkbox class="example-margin" (click)="toggleImmediate()" formControlName="EndImmediately">End Shift Immediately</mat-checkbox>
            </div>
        </div>
        <div>
            <small class="validationError" *ngIf="endtimeFormGroup.get('ShiftEnd').hasError('afterCurrentShiftEnd')">
                End Time cannot be later than current shift end time of {{areaShift.CurrentShiftEnd | date:'hh:mm a'}}
            </small>
            <small class="validationError" *ngIf="endtimeFormGroup.get('ShiftEnd').hasError('beforeCurrentShiftStart')">
                End Time cannot be earlier than current shift start time of {{areaShift.CurrentShiftStart | date:'hh:mm a'}}
            </small>
            <small class="validationError" *ngIf="endtimeFormGroup.get('ShiftEnd').hasError('beforeCurrentTime')">
                End Time cannot be earlier than current time of {{currentTime | date:'hh:mm a'}}
            </small>
        </div>

        <mat-divider></mat-divider>
        
        <mat-form-field style="width: 50%; padding-top: 10px">
            <mat-label>Reason for Modification:</mat-label>
            <input matInput maxlength="80" #reason placeholder="ex. Unforeseen Event" formControlName="Reason">
            <mat-hint>{{reason.value?.length || 0}}/80</mat-hint>
        </mat-form-field>
        <mat-divider></mat-divider>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="updateEndTime()" [disabled]="endtimeFormGroup.invalid">Save</button>
        <button mat-raised-button (click)="onNoClick()">Cancel</button>
    </div>
</form>