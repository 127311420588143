<button mat-raised-button (click)="onBack()" class="goBackBtn">Go Back</button>

<h1>Manage Current Shift</h1>

<div class="content">
  <div *ngFor="let area of dataSource" class="content-card">
    <mat-card>
      <mat-card-title>
        <h2>{{area.Area}} - {{area.AreaDesc}}</h2>
      </mat-card-title>
      <mat-card-title>
      <h3></h3>
      </mat-card-title>
      <mat-card-content>
        <div class="fx-column-space-around-start">
          <div style="margin-bottom: 5px">
            <strong>Current Shift: </strong> 
            <span *ngIf="area.CurrentShiftStatus == 'SHIFT EXISTS'">{{area.CurrentShiftDate}} {{area.CurrentShift}} ({{area.CurrentShiftStart | date:'hh:mm a'}} to {{area.CurrentShiftEnd | date:'hh:mm a'}})</span>
            <span *ngIf="area.CurrentShiftStatus == 'NO SHIFT'">Not in Shift</span>
          </div>

          <div style="margin-bottom: 5px">
            <strong>Next Break: </strong>
            <span *ngIf="area.CurrentShiftStatus == 'SHIFT EXISTS' && area.BreakStatusMsg == 'Break Available'">{{area.StatusMsg}}: {{area.BreakStartTime | date:'mediumTime'}} to {{area.BreakEndTime | date:'mediumTime'}}</span>
            <span *ngIf="area.CurrentShiftStatus == 'SHIFT EXISTS' &&               area.BreakStatusMsg != 'Break Available'">No break avialable to start early, go to Active calendar and manually add a break if one is needed.</span>
            <span *ngIf="area.CurrentShiftStatus == 'NO SHIFT'">Not in Shift</span>
          </div>

          <div>
            <strong>Next Shift: </strong> 
            <span *ngIf="area.NextShiftStatus == 'SHIFT EXISTS'">{{area.NextShiftDate}} {{area.NextShift}} ({{area.NextShiftStart | date:'hh:mm a'}} to {{area.NextShiftEnd | date:'hh:mm a'}})</span>
            <span *ngIf="area.NextShiftStatus == 'NO SHIFT'">No Upcoming Shift</span>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="warn" *ngIf="area.CurrentShiftStatus == 'SHIFT EXISTS'" (click)="endShiftEarly(area)">Change {{area.Area}} End Time</button>
        <!-- TODO: button will be implemented in future ticket <button mat-raised-button color="primary" style="background-color: green" *ngIf="area.CurrentShiftStatus == 'NO SHIFT' && area.NextShiftStatus == 'SHIFT EXISTS'" (click)="startShiftEarly(area)">Start {{area.Area}} Shift Early</button>-->
        <button mat-raised-button color="primary" *ngIf="area.BreakStatusMsg == 'Break Available'" (click)="startBreakEarly(area)">Start {{area.Area}} Break Early</button>
      </mat-card-actions>
    </mat-card>
</div>
</div>
