import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
/*import { AbstractRestService } from 'src/app/services/abstract-rest.service'; */
import { AbstractRestService } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class DeleteAreaGroupService extends AbstractRestService {
  postInit(): void {
  }

  deleteGroups(delAreaObj: any){
    let params: any = {}

    delAreaObj.groupName ? params.groupName : null
    delAreaObj.site ? params.site : null 
    delAreaObj.environement ? params.environement : null
    delAreaObj.area ? params.area : null

  
    if (delAreaObj.area && delAreaObj.environment) {
      params.groupName = delAreaObj.groupName
      params.site = delAreaObj.site
      params.environment = delAreaObj.environment
      params.area = delAreaObj.area
    } else {
      params.groupName = delAreaObj.groupName
      params.site = delAreaObj.site
    }  

    console.log(' Inside delete group ',params )
    return this.delete({
      ApiResource: "/managegroups",
      data:  params
    });
 /*   return this.delete('/managegroups', httpParams); */
  }
}