<div>
  <button mat-raised-button class="goBackBtn" routerLink="/locations">Go Back</button>
</div>
<ng-container *ngFor="let site of siteData | async">
  <button color="primary" mat-raised-button class="loc" type="button" label="Click" id={{site.Site}} (click)="handleSiteClick(site.Site)">{{site.display_name}}</button>
</ng-container>

<mat-card>
    <mat-card-header>
        <mat-card-title>Area Group Configuration - Site: {{selectedSite}}</mat-card-title>
        <span class="example-spacer"></span>
        <button *ngIf="selectedSite && isMobile" class="addGroupBut" color="primary" mat-raised-button (click)="addGroup()">Add Group</button>
        <button *ngIf="selectedSite && !isMobile" class="addGroupBut" mat-mini-fab color="primary" aria-label="Add Group" (click)="addGroup()">
          <mat-icon>add</mat-icon>
        </button>
    </mat-card-header>
    <mat-card-content>
      <mat-accordion *ngFor="let group of allGroups | keyvalue">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="fx-row-start-center">
            <mat-panel-title>
              <h2>Group: {{group.key}}</h2>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <h4>Areas:</h4>
          <mat-list #areas (selectionChange)="onSelection(areas)">
            <mat-list-item *ngFor="let area of group.value.areas">
              {{area['description']}}
            </mat-list-item>
          </mat-list>

          <div>
            <button class="loc" mat-raised-button color="primary" (click)="editAreas(group.value.areas, group.key)">Edit Areas</button>
            <button class="loc" mat-raised-button color="warn" (click)="deleteGroup(group.key)">Delete Group</button>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
</mat-card>
