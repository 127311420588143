// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  graphqlUrl: 'https://jetp7b2phnef5j6rbbcl2k3vpu.appsync-api.us-east-1.amazonaws.com/graphql',
  graphqlAuth: {
    type: 'cognito'
  },
  apigwUrl: "https://api-dev.sc.geappl.io/WorkCalendar",
  apiGateway: {
    url: 'https://api-dev.sc.geappl.io/',
    stage: 'WorkCalendar'
  },
  cognitoOptions: {
    ClientId: '68h4jq5b3oi4qq02690p6ve3k2', // Your client id here
    AppWebDomain: 'mesauth.auth.us-east-1.amazoncognito.com', // Exclude the "https://" part.
    TokenScopesArray: ['openid', 'profile', 'email'],
    UserPoolId: 'us-east-1_Dj6sStrjh',
  },
  adminGroups: [ 'UNV_APL_AdminERP_AL', 'UNV_APL_Materials_AdvancedERP_AL', 'UNV_APL_PowerUser_AdvancedERP_AL', 'UNV_APL_ShopFloor_AdvancedERP_AL'],
  helpURL: 'https://geappliances.sharepoint.com/:f:/s/ManufacturingIT/EhsyPUdwGsVIic9NrxC0muAB-rPakFOXoA8rJdAOL5rBzg?e=xRCx4X'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
