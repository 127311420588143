import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { BreakEarlyService } from 'src/app/break-early/break-early.service';
import { ReasonDialogComponent } from 'src/app/main/reason-dialog/reason-dialog.component';
import { CalendarService } from 'src/app/services/calendar.service';
import { EasyEditArea, EasyShiftServiceService } from '../../services/easy-shift-service.service';
import { ChangeEndtimeDialogComponent } from '../change-endtime-dialog/change-endtime-dialog.component';

@Component({
  selector: 'app-area-shift-list',
  templateUrl: './area-shift-list.component.html',
  styleUrls: ['./area-shift-list.component.scss']
})
export class AreaShiftListComponent implements OnInit, OnDestroy {

  areas:string[];
  envs:string[];
  dataSource:EasyEditArea[];
  site:string;

  subscription:Subscription;

  constructor(
    private route: ActivatedRoute,private router: Router,
    private svcEasyShift: EasyShiftServiceService,
    private svcBreakEarly: BreakEarlyService,
    private svcCalendar: CalendarService, 
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    let lines:string[] = this.route.snapshot.paramMap.get('lines').split('&'); 

    this.site = this.route.snapshot.paramMap.get('site')
    let myAreas:string[] = [];
    let myEnvs:string[] = [];

    // Extract Area and Env from each line
    lines.forEach(function(line) {
      let parts = line.split('-');
      
      myAreas.push(parts[0]);
      myEnvs.push(parts[1]);

    });

    this.envs = Array.from(new Set(myEnvs)); // Deduplicate envs
    this.areas = myAreas;
    this.getEasyShiftList();
  }

  getEasyShiftList() {
    if (this.subscription) this.subscription.unsubscribe();

    this.subscription = this.svcEasyShift.getEasyShiftList(this.envs, this.areas).subscribe(
      (out) => {
        console.log("Data", out);
        this.dataSource = out['Body'];
      }
    ) 
  }

  endShiftEarly(area:EasyEditArea) {
    console.log("End shift early");

    const dialogRef = this.dialog.open(ChangeEndtimeDialogComponent, {
      width: '500px',
      maxHeight: '800px',
      data: {
        Envs: this.envs,
        Area: area
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Add/Edit Closed");
      this.getEasyShiftList();
    });
  }


  startBreakEarly(area:EasyEditArea) {
    const dialogRef = this.dialog.open(ReasonDialogComponent,{
      width:'250px',
      data: {reason: ""}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.svcCalendar.isLoading.next(true); // Set progress spinner
        this.svcBreakEarly.breakEarly(this.envs, area.Area, area.CurrentShiftDate, area.CurrentShift, result.reason).pipe(first()).subscribe(
          out => {
            console.debug("Started Break Early", out);
            this.getEasyShiftList(); // Force immediate refresh since we know data just updated
            this.svcCalendar.isLoading.next(false); // Turn off progress spinner
          }
        );
      }
    });
  }


  startShiftEarly(area:EasyEditArea) {
    console.log("Start Shift Early");
  }
  

  onBack() {
    this.router.navigate(['/area', 'select', this.site, 'easyshift', 'ALL']);
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

}

