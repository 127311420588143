/*
* Description: Static Application Information
*
* History:
*   05/07/2023  A Kuprion   ITMFG-34262 Initial version, needed for angular upgrade as package.json can no longer be read
*
**/
export const appinfo = {
    'name': 'workcalendar',
    'version': '1.9.0'
}