<div class="fx-row-wrap">
      <div style="height: 70px;  z-index: 10;">
            <gea-mes-header
            showLogOut="desktop"
            showMenu="both"
            [subTitle]="displayName"

            showLeftIcon1="both"
            routeLeftIcon1="locations"
            leftIcon1Src="home"
            leftIcon1ToolTip="Home Page"

            showRightIcon1="both"
            routeRightIcon1="admin/settings"
            rightIcon1Src="settings_applications"
            rightIcon1ToolTip="Admin Configuration"

            showRightIcon2="both"
            [linkRightIcon2]="helpURL"
            rightIcon2Src="help_outline"
            rightIcon2ToolTip="Help"

            >
            </gea-mes-header>
      </div>
</div> 
<div>
      <app-progress-spinner></app-progress-spinner>
      <router-outlet></router-outlet>
</div>