<form [formGroup]="reasonFormGroup">
    <h3 mat-dialog-title>Confirm Remove Shift</h3>
    <div mat-dialog-content>
        
        <div class="fx-column-space-around-start"> 
            <div class="fx-row"> 
                <div class="fx-flex-100px">Shift Date:</div>
                <div>{{shift.ShiftDate}}</div>
            </div>
            
            <div class="fx-row"> 
                <div class="fx-flex-100px">Shift:</div>
                <div>{{shift.Shift}}</div>
            </div>

            <div class="fx-row"> 
                <div class="fx-flex-100px">Start:</div>
                <div>{{shift.ShiftStart | date:'HH:mm:ss'}}</div>
            </div>

            <div class="fx-row"> 
                <div class="fx-flex-100px">End:</div>
                <div>{{shift.ShiftEnd | date:'HH:mm:ss'}}</div>
            </div>
        </div>
       

        <mat-divider></mat-divider>
        
        <mat-form-field style="width: 50%; padding-top: 10px">
            <mat-label>Reason for Removing Shift:</mat-label>
            <input matInput maxlength="80" #reason placeholder="ex. Unforeseen Event" formControlName="Reason">
            <mat-hint>{{reason.value?.length || 0}}/80</mat-hint>
        </mat-form-field>
        <mat-divider></mat-divider>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button color="warn" (click)="removeShift()" [disabled]="reasonFormGroup.invalid">Remove Shift</button>
        <button mat-raised-button (click)="onNoClick()">Cancel</button>
    </div>
</form>