import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { CalendarEventType, ShiftLookup, ShiftTimes } from '../models/event-dialog.model';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class ShiftLookupService {
  private _shiftLookupReference: Subject<ShiftLookup> = new ReplaySubject();
  public shiftLookupReference: Observable<ShiftLookup> = this._shiftLookupReference.asObservable();
  calendarServiceSubscription: Subscription;

  constructor() { }

  setShiftLookUp(calendarEvents: CalendarEventType[], site: string, templateEvent: boolean){
    let calendarEventsLookup = {}
    let shiftsOnly = calendarEvents.filter(event => event['Event'].substring(0,5) == "SHIFT")
    shiftsOnly.forEach(shift => {
      let projectid:string;
      if (templateEvent) {
        projectid = shift.AuroraEnv;
      } else {
        projectid = shift.ProjectID;
      }
      let shiftObj = {}
      let areaKey = shift.Area + '_' + site + '_' + projectid
      let start: string;
      let end: string;

      if (!(areaKey in calendarEventsLookup)) {
        calendarEventsLookup[areaKey] = []
      }

      if (dayjs(shift.StartTime).isValid()) {
        start = shift.StartTime
        end = shift.EndTime
      } else {
        start = dayjs(dayjs().format('YYYY-MM-DD') + ' ' + shift.StartTime).format('YYYY-MM-DD HH:mm:ss.sssssss')
        end = dayjs(dayjs().format('YYYY-MM-DD') + ' ' + shift.EndTime).format('YYYY-MM-DD HH:mm:ss.sssssss')
      }

      shiftObj['Shift'] = shift.Shift
      shiftObj['StartTime'] = start
      shiftObj['EndTime'] = end 
      shiftObj['ShiftDayOffset'] = shift.ShiftDayOffset ? shift.ShiftDayOffset : null
      calendarEventsLookup[areaKey].push(shiftObj)
    });
    this._shiftLookupReference.next(calendarEventsLookup)

  }
}
