import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AreaGroupAdminComponent } from './area-group-admin/area-group-admin.component';

const adminRoutes: Routes = [
  {path: 'settings', component: AreaGroupAdminComponent}
];


@NgModule({
    imports: [RouterModule.forChild(adminRoutes)],
    exports: [RouterModule]
  })
  export class AdminRoutingModule { }