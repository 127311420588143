import {Component, Inject, OnInit} from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { EventConfirmType} from "src/app/services/calendar.service";
import { EventValidationService } from 'src/app/common/services/event-validation.service';
import { CalendarEvent } from 'src/app/common/models/event-dialog.model';

@Component({
  selector: 'app-confirmationBox',
  styleUrls: ['./confirmationBox.component.scss'],
  templateUrl: './confirmationBox.component.html'
})


export class ConfirmationBoxComponent implements OnInit{

  oldData: CalendarEvent;
  newData: CalendarEvent;
  newReason: string;
  changesMade: boolean;
  isTemplate: boolean

  constructor(
    public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
    private eventValidationService: EventValidationService,
    @Inject(MAT_DIALOG_DATA) public data: EventConfirmType) {}

    ngOnInit(): void {
      this.isTemplate = this.data.isTemplate
      this.newReason = '';
      if (this.data.oldEvent) this.oldData = this.data.oldEvent;

      let myTarget:number = +this.oldData.Target * 100;
      this.oldData.Target = myTarget.toString();

      if (this.data.newEvent) this.newData = this.data.newEvent;
      if (this.newData.Action == 'Delete'){
        this.changesMade = true
      } else {
        this.changesMade = this.checkForChange(this.oldData, this.newData)
      }
    }

    keys() : Array<string> {
    return Object.keys(this.newData);
  }

  checkForChange(oldData: CalendarEvent, newData: CalendarEvent){
    for (const [key, value] of Object.entries(oldData)) {
      if (newData[key] != value){
        return true
      }
    }
    return false
  }

  onNoClick(): void {
    this.dialogRef.close({'submitFlag': false});
  }
  onSubmit(): void {
    this.newData.Reason = this.newReason
    
    if (this.eventValidationService.validateReason(this.newData)) {
      this.dialogRef.close({'submitFlag': true, 'newReason': this.newReason});
    } else {
      this.eventValidationService.showErrorMessage(this.newData)
    }
  }
}
