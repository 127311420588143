import { HttpParams } from '@angular/common/http'
import * as dayjs from 'dayjs';
import { CalendarEventType } from '../common/models/event-dialog.model';

export class CalendarServiceUtil {

  static buildAreaParams(site: string, lines?: string[]) {
    let params: any = {};

    params.site = site.toString();

    if (lines) params.areas = lines.join(",");

    return new HttpParams({fromObject: params}); 
  }

  static buildParams(environments: string[], startTime: string, endTime:string, lines?:string[]) {
    let params: any = {};
    if (startTime) params.startTime = startTime;
    if (endTime) params.endTime = endTime;
    if (lines) params.areas = lines.join(",");
    if (environments) params.environments = environments.join(",");
    
    return new HttpParams({fromObject: params});
  }

  static buildEventParams(events: CalendarEventType[]) {
    let params: any [] = [];
    let param: any = {};
    console.log(events)

    events.forEach((el) => {
      param.Template = '';
      param.Area = el.Area;
      param.AuroraEnv = el.AuroraEnv;
      param.ProjectID = el.ProjectID;
      param.Shift = el.Shift;
      param.ShiftDate = el.ShiftDate;
      param.ObjectDescription = el.ObjectDescription;
      param.Event = el.Event;
      param.BreakCatagory = el.BreakCatagory;
      param.StartTime = dayjs(el.StartTime).format('YYYY-MM-DDTHH:mm:ss'); //convert to required format
      param.EndTime = dayjs(el.EndTime).format('YYYY-MM-DDTHH:mm:ss'); //convert to required format

      console.debug("buildEventParams: " + param.StartTime + " - " + param.EndTime);

      if (el.Target != '') {
        param.Target = +el.Target; //convert to required format
        if (param.Target > 0) {
          param.Target = param.Target / 100;
        }
      } else {
        param.Target = 0;
      }
      param.Action = el.Action;
      param.Reason = el.Reason;
      param.HornAdvDelta = parseInt(el.HornAdvDelta, 10); //convert to required format
      param.HornAdvDuration = parseInt(el.HornAdvDuration, 10);
      param.HornStartDelta = parseInt(el.HornStartDelta, 10); //convert to required format
      param.HornStartDuration = parseInt(el.HornStartDuration, 10); //convert to required format
      param.HornEndDelta = parseInt(el.HornEndDelta, 10); //convert to required format
      param.HornEndDuration = parseInt(el.HornEndDuration, 10); //convert to required format
      param.Modify_by = el.Modify_by;
      params.push(param);
      param = {};
    });
    return {"data": params, "isAsync": "0"}

  }
}
